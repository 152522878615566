import axios from 'axios';

import {IUploadBlobProps} from 'modules/file/models/IFileCreate';
import {IFile} from 'modules/file/models/IFile';
import {IFileListQuery} from 'modules/file/models/IFileListQuery';
import {IFileListResult} from 'modules/file/models/IFileListResult';
import {getAccessTokenSilently} from 'shared/utils/token';
import {Config} from 'config';
import {IFileToken} from '../models/IFileToken';
import {getAuthHeaders} from 'shared/utils/auth';

const getURLForFileAPI = (path: string): string => {
    return `${Config.fileServiceURL}/files${path}`;
};

const getURLForFileTokenAPI = (path: string): string => {
    return `${Config.fileServiceURL}/file_token${path}`;
};

export const uploadBlob = ({uploadUrl, blob}: IUploadBlobProps): Promise<void> => {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('PUT', uploadUrl, true);
        xhr.onload = () => {
            const status = xhr.status;
            if (status === 200) {
                resolve();
            } else {
                reject('Could not upload file');
            }
        };
        xhr.onerror = () => {
            reject('Could not upload file');
        };
        xhr.setRequestHeader('Content-Type', blob.type);
        xhr.send(blob);
    });
};

export const readFileList = async ({
    userId,
    cursor,
    limit,
    folder,
}: IFileListQuery): Promise<IFileListResult> => {
    const accessToken = await getAccessTokenSilently();
    const params = {
        cursor,
        limit,
        user_id: userId,
        folder,
    };
    const response = await axios.get(getURLForFileAPI('/'), {
        withCredentials: true,
        headers: getAuthHeaders(accessToken),
        params: params,
    });
    return {
        files: response.data?.files ?? [],
        nextCursor: response.data?.next_cursor,
    };
};

export const readFile = async (fileId: string): Promise<IFile> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getURLForFileAPI(`/${fileId}`), {
        withCredentials: true,
        headers: getAuthHeaders(accessToken),
    });
    return response.data as IFile;
};

export const deleteFile = async (fileId: string): Promise<string> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.delete(getURLForFileAPI(`/${fileId}`), {
        withCredentials: true,
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const batchDeleteFiles = async (fileIds: string[]) => {
    const accessToken = await getAccessTokenSilently();
    const payload = {
        file_ids: fileIds,
    };
    const response = await axios.post(getURLForFileAPI('/batch_delete'), payload, {
        withCredentials: true,
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const createFileToken = async (): Promise<IFileToken> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(getURLForFileTokenAPI('/'), null, {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const createFileFromUpload = async (fileUploadId: string): Promise<IFile> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.put(
        getURLForFileAPI('/'),
        {
            file_upload_id: fileUploadId,
        },
        {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        },
    );
    return response.data as IFile;
};
