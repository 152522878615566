import React from 'react';

import {Button, Form, Modal} from 'react-bootstrap';
import {useSetRecoilState} from 'recoil';

import {IArticle} from 'modules/help/models';
import {deleteArticle} from 'modules/help/api';
import {toastAxiosError} from 'shared/utils/error';
import {articleListRemoveSelector} from 'modules/help/state/article-list';
import {toast} from 'shared/utils/toast';

interface IDeleteArticleModalProps {
    show: boolean;
    onHide: () => void;
    article?: IArticle;
}

export const DeleteArticleModal = ({show, onHide, article}: IDeleteArticleModalProps) => {
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
    const removeCategory = useSetRecoilState(articleListRemoveSelector);

    const onSubmit = ((e: React.FormEvent) => {
        e.preventDefault();

        if (!article) {
            return;
        }

        setIsSubmitting(true);
        (async () => {
            try {
                await deleteArticle(article.id);
                removeCategory(article.id);
                onHide();
                toast.success('Delete Article', 'Article deleted successfully.');
            } catch (e) {
                toastAxiosError(e, 'Error deleting article');
            } finally {
                setIsSubmitting(false);
            }
        })();
    });

    return (
        <Modal
            show={show && !!article}
            size="sm"
            title="Delete Article"
            isSubmitting={isSubmitting}
            onHide={onHide}
        >
            <Modal.Header closeButton={!isSubmitting}>
                <Modal.Title>
                    Delete Article
                </Modal.Title>
            </Modal.Header>
            <Form onSubmit={onSubmit}>
                <Modal.Body>
                    <p>Are you sure you wish to delete this article?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" variant="danger" disabled={isSubmitting}>
                        Delete Article
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};
