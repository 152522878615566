export enum IssueStatus {
    OPEN = 'OPEN',
    CLOSED = 'CLOSED',
}

export enum IssueOrder {
    NONE = 'NONE',
    CREATED_AT = 'CREATED_AT',
}

export interface IIssue {
    user_id: string | undefined;
    id: string;
    status: IssueStatus;
    title: string;
    description: string;
    created_at: string;
    updated_at?: string;
    image_id?: string;
}

export interface ICreateIssue {
    status: IssueStatus;
    title: string;
    description: string;
    image_id?: string;
}

export interface IIssueUpdate {
    status?: IssueStatus;
    title?: string;
    description?: string;
    image_id?: string;
}

export interface IReadIssuesQuery {
    order_by?: IssueOrder;
    status?: IssueStatus;
    limit?: number;
    cursor?: string;
}

export interface IReadIssuesResult {
    issues: IIssue[];
    next_cursor?: string;
}
