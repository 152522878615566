import React from 'react';

import {Col, Form, Row, Button} from 'react-bootstrap';
import {Control, FieldValues, Path} from 'react-hook-form';

import {Delete} from 'shared/icons/dashboard';
import {InputController} from 'shared/components/form/InputController/InputController';

interface IWorkExperienceItemProps<FormType> {
    name: Path<FormType>;
    index: number;
    disabled?: boolean;
    onDelete?: (index: number) => void;
    control: Control<FormType>;
}

export const WorkExperienceItem = <FormType extends FieldValues>({
    disabled,
    name,
    index,
    onDelete,
    control,
}: IWorkExperienceItemProps<FormType>) => {
    return (
        <Row>
            <Col xs={6}>
                <Form.Group as={Col} controlId={`${name}.name`}>
                    <label className="Inputlable">Name</label>
                    <InputController
                        control={control}
                        name={`${name}.name` as Path<FormType>}
                        disabled={disabled}
                        className="Input__box-shodow--none Inputfield"
                    />
                </Form.Group>
            </Col>
            <Col xs={4} xl={5}>
                <Form.Group as={Col} controlId={`${name}.description`}>
                    <label className="Inputlable">Description</label>
                    <InputController
                        asType="textarea"
                        control={control}
                        rows={2}
                        className="Input__box-shodow--none Inputfield"
                        name={`${name}.description` as Path<FormType>}
                        disabled={disabled}
                    />
                </Form.Group>
            </Col>
            <Col xs={2} xl={1}>
                <Form.Label className="d-block">&nbsp;</Form.Label>
                <Button variant="link" onClick={() => onDelete?.(index)}>
                    <Delete className="ClodeBtn"/>
                </Button>
            </Col>
        </Row>
    );
};
