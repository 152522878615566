import React from 'react';

import {IJobWorkType} from 'modules/job/models';

import {IconInPerson, IconVirtual} from 'shared/icons/jobs';
import {jobWorkTypeToFriendlyName} from 'modules/job/utils';

interface IJobWorkTypeIconProps {
    workType: IJobWorkType;
    className?: string;
    iconClassName?: string;
    bold?: boolean;
}

export const JobWorkTypeIcon = ({workType, className, iconClassName, bold}: IJobWorkTypeIconProps) => {
    const friendlyWorkType = React.useMemo<string | null>(() => {
        return jobWorkTypeToFriendlyName(workType);
    }, [workType]);

    const icon = React.useMemo<React.ReactNode>(() => {
        if (workType.is_virtual && workType.is_in_person) {
            return (
                <>
                    <IconInPerson className="mr-3"/>
                    <IconVirtual className="mr-3"/>
                </>
            );
        } else if (workType.is_in_person) {
            return (
                <IconInPerson className={iconClassName}/>
            );
        } else if (workType.is_virtual) {
            return (
                <IconVirtual className={iconClassName}/>
            );
        } else {
            return null;
        }
    }, [workType, iconClassName]);

    const DescriptionComponent = bold ? 'strong' : 'span';

    return (
        <div className={className}>
            {icon}
            <small className={workType.is_virtual ? 'text-primary' : undefined}>
                <DescriptionComponent>{friendlyWorkType}</DescriptionComponent>
            </small>
        </div>
    );
};
