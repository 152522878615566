import React from 'react';

import {IProfile} from 'modules/profile/models/IProfile';
import {UserRole} from 'modules/user/models';
import {BillingInfo} from 'components/profile/ViewProfile/ViewProfileBilling/BillingInfo';
import {BillingSystemUserWarning} from 'components/profile/ViewProfile/ViewProfileBilling/BillingSystemUserWarning';

interface IViewProfileBillingProps {
    profile: IProfile;
}

export const ViewProfileBilling = ({profile}: IViewProfileBillingProps) => {

    return (
        <>
            {profile.role === UserRole.System ? (
                <BillingSystemUserWarning/>
            ) : (
                <BillingInfo profile={profile}/>
            )}
        </>
    );
};
