import {tagListSelector, tagListReadSelector} from 'modules/tag/state/tag-list';
import {useRecoilLoadable} from 'shared/recoil/hooks/useRecoilLoadable';

export const useTagListLoadable = () => {
    const {data, loading, error} = useRecoilLoadable({
        state: tagListSelector,
        loadable: tagListReadSelector,
    });
    return {
        tags: data,
        loading,
        error,
    };
};
