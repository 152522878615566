import axios from 'axios';

import {Config} from 'config';
import {ICreatePost, ICreatePostLawyer} from 'modules/post/models/ICreatePost';
import {IPost} from 'modules/post/models/IPost';
import {IPostListResult} from 'modules/post/models/IPostListResult';
import {IPostListQuery, IPostQuery} from 'modules/post/models/IPostListQuery';
import {IReadFeaturedPostsRequest} from 'modules/post/models/IReadFeaturedPostsRequest';
import {PostOrder} from 'modules/post/models/postOrder';
import {IPostCountResult} from 'modules/post/models/IPostCountResult';
import {getAccessTokenSilently} from 'shared/utils/token';
import {getAuthHeaders} from 'shared/utils/auth';

const getURLForPostAPI = (path: string) => {
    return `${Config.mainServiceURL}/posts${path}`;
};

export const createPost = async (postData: ICreatePost): Promise<IPost> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(getURLForPostAPI('/'), postData, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
    return response.data;
};

export const updatePost = async (postId: string, postData: ICreatePost | ICreatePostLawyer): Promise<IPost> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.patch(getURLForPostAPI(`/${postId}`), postData, {
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
    });
    return response.data;
};

export const readPost = async (postId: string): Promise<IPost> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getURLForPostAPI(`/${postId}`), {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const readPostList = async (params: IPostListQuery): Promise<IPostListResult> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getURLForPostAPI('/'), {
        params: {
            limit: params.limit,
            cursor: params.cursor,
            user_id: params.userId,
            kind: params.kind,
            is_featured: params.isFeatured,
            is_published: params.isPublished,
            order_by: params.orderBy,
            search_term: params.searchTerm,
        },
        headers: getAuthHeaders(accessToken),
    });
    return {
        posts: response.data?.posts,
        nextCursor: response.data?.next_cursor,
    };
};

export const readPostCount = async (params: IPostQuery): Promise<IPostCountResult> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getURLForPostAPI('/count'), {
        params: {
            user_id: params.userId,
            kind: params.kind,
            is_featured: params.isFeatured,
            is_published: params.isPublished,
            search_term: params.searchTerm,
        },
        headers: getAuthHeaders(accessToken),
    });
    return {
        postCount: response.data?.post_count,
    };
};

export const readFeaturedPostList = async (params: IReadFeaturedPostsRequest): Promise<IPostListResult> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getURLForPostAPI('/'), {
        params: {
            is_published: params?.is_published,
            is_featured: params?.is_featured,
            limit: params.limit,
            cursor: params.cursor,
            user_id: params.userId,
            kind: params.kind,
            order_by: PostOrder.PublishedAt,
        },
        headers: getAuthHeaders(accessToken),
    });
    return {
        posts: response.data?.posts,
        nextCursor: response.data?.next_cursor,
    };
};

export const deletePost = async (postId: string) => {
    const accessToken = await getAccessTokenSilently();
    await axios.delete(getURLForPostAPI(`/${postId}`), {
        headers: getAuthHeaders(accessToken),
    });
};
