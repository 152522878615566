import {cssTransition, toast as reactToast, TypeOptions} from 'react-toastify';

import {getToastMessage} from 'shared/components/ToastContainer/ToastMessage';

const defaultConfig = {
    autoClose: 1000,
    transition: cssTransition({
        enter: 'empty',
        exit: 'empty',
    }),
};

export const toast = {
    info: (title: string, message: string) => {
        reactToast.info(getToastMessage({title, message}), defaultConfig);
    },
    success: (title: string, message: string) => {
        reactToast.success(getToastMessage({title, message}), defaultConfig);
    },
    error: (title: string, message: string) => {
        reactToast.error(getToastMessage({title, message}), defaultConfig);
    },
    warning: (title: string, message: string) => {
        reactToast.warning(getToastMessage({title, message}), defaultConfig);
    },
    default: (title: string, message: string) => {
        reactToast(getToastMessage({title, message}), defaultConfig);
    },
    dark: (title: string, message: string) => {
        reactToast.dark(getToastMessage({title, message}), defaultConfig);
    },
    open: (title: string, message: string, type: TypeOptions) => {
        reactToast(getToastMessage({title, message}), {
            ...defaultConfig,
            type,
        });
    },
};
