import axios from 'axios';

import {IBillingSubscription} from '../models';
import {Config} from 'config';
import {getAuthHeaders} from 'shared/utils/auth';
import {getAccessTokenSilently} from 'shared/utils/token';

export const getURLForBillingService = (path: string): string => {
    return `${Config.billingServiceURL}${path}`;
};

export const readSubscription = async (userID: string): Promise<IBillingSubscription> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getURLForBillingService(`/users/${userID}/subscription`), {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};
