import React from 'react';

import {Col, Form, Row, Button} from 'react-bootstrap';
import {FieldValues, Path, UseControllerProps} from 'react-hook-form';

import {parseInputValueAsInt} from 'shared/utils/form';

import {Delete} from 'shared/icons/dashboard';
import {InputController} from 'shared/components/form/InputController/InputController';

interface ISkillsControllerItemProps<FormType> extends UseControllerProps<FormType> {
    index: number;
    disabled?: boolean;
    onDelete?: (index: number) => void;
}

export const SkillsControllerItem = <FormType extends FieldValues>({
    disabled,
    name,
    index,
    onDelete,
    control,
}: ISkillsControllerItemProps<FormType>) => {
    return (
        <Row>
            <Col xs={6}>
                <Form.Group as={Col} controlId={`${name}.name`}>
                    <label className="Inputlable">Name</label>
                    <InputController
                        control={control}
                        name={`${name}.name` as Path<FormType>}
                        placeholder="Name"
                        className="Input__box-shodow--none Inputfield"
                        disabled={disabled}
                    />
                </Form.Group>
            </Col>
            <Col xs={4} xl={5}>
                <Form.Group as={Col} controlId={`${name}.level`}>
                    <label className="Inputlable">Level</label>
                    <InputController
                        control={control}
                        name={`${name}.level` as Path<FormType>}
                        placeholder="level"
                        disabled={disabled}
                        className="Input__box-shodow--none Inputfield"
                        formatValue={parseInputValueAsInt}
                    />
                </Form.Group>
            </Col>
            <Col xs={2} xl={1}>
                <Form.Label className="d-block">&nbsp;</Form.Label>
                <Button variant="link" onClick={() => onDelete?.(index)}>
                    <Delete className="ClodeBtn"/>
                </Button>
            </Col>
        </Row>
    );
};
