import {selector} from 'recoil';

import {IIssue} from 'modules/issue/models';
import {throwWriteOnlySelectorError, guardRecoilDefaultValue} from 'shared/recoil/utils';
import {issueListAtom} from 'modules/issue/state/issue-list';

export const issueInsertSelector = selector<IIssue>({
    key: 'issueInsertSelector',
    get: throwWriteOnlySelectorError,
    set: ({set, get}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }

        const atomValue = get(issueListAtom);
        if (!atomValue) {
            return;
        }

        const newIssues = Array.from(atomValue.issues);
        const index = newIssues.findIndex(issue => issue.id === newValue.id);
        if (index !== -1) {
            newIssues.splice(index, 1, newValue);
        } else {
            newIssues.push(newValue);
        }
        set(issueListAtom, {
            ...atomValue,
            issues: newIssues,
        });
    },
});
