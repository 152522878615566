import React from 'react';

import {Control} from 'react-hook-form';
import {Form} from 'react-bootstrap';

import {IProfile} from 'modules/profile/models/IProfile';
import {IProfileUpdate} from 'modules/profile/models/IProfileUpdate';

import {FormCard} from 'components/profile/shared/FormCard/FormCard';
import {SkillsController} from 'components/profile/shared/SkillsController/SkillsController';
import {WorkExperienceController} from 'components/profile/shared/WorkExperienceController/WorkExperienceController';
import {SocialLinksController} from 'components/profile/shared/SocialLinksController/SocialLinksController';

interface IProfileFormSkillsProps {
    profile: IProfile;
    control: Control<IProfileUpdate>;
    isSubmitting: boolean;
}

export const ProfileFormSkills = ({control, isSubmitting}: IProfileFormSkillsProps) => {

    return (
        <FormCard title="Resume">
            <Form.Group>
                <Form.Label>Skills</Form.Label>
                <SkillsController
                    name="skills"
                    control={control}
                    disabled={isSubmitting}
                />
            </Form.Group>
            <hr className="my-5"/>
            <Form.Group>
                <Form.Label>Experience</Form.Label>
                <WorkExperienceController
                    name="work_experience"
                    control={control}
                    disabled={isSubmitting}
                />
            </Form.Group>
            <hr className="my-5"/>
            <Form.Group controlId="UpdateProfile__social_links">
                <Form.Label>Social Links</Form.Label>
                <SocialLinksController
                    name="social_links"
                    control={control}
                    disabled={isSubmitting}
                />
            </Form.Group>
        </FormCard>
    );
};
