import React, {useState} from 'react';

import {Button, Form, Modal} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {useSetRecoilState} from 'recoil';
import {zodResolver} from '@hookform/resolvers/zod';

import {IssueStatus} from 'modules/issue/models';
import {toastAxiosError} from 'shared/utils/error';
import {toast} from 'shared/utils/toast';
import {createIssue} from 'modules/issue/api';
import {createIssueForm, IIssueForm} from 'modules/issue/forms';

import {CreateIssueForm} from 'components/issues/shared/CreateIssueModal/CreateIssueForm';

import './style.scss';
import {issueInsertSelector} from 'modules/issue/state/issue-insert';

interface ICreateIssueModalProps {
    show: boolean;
    onHide: () => void;
}

export const CreateIssueModal = ({onHide, show}: ICreateIssueModalProps) => {
    const {control, handleSubmit, reset, setValue} = useForm<IIssueForm>({
        resolver: zodResolver(createIssueForm),
        defaultValues: {
            status: IssueStatus.OPEN,
            title: '',
            description: '',
            image_id: '',
        },
    });

    React.useEffect(() => {
        if (!show) {
            reset();
        }
    }, [show, reset]);

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const insertIssue = useSetRecoilState(issueInsertSelector);

    const onSubmit = handleSubmit((formData) => {
        setIsSubmitting(true);

        (async () => {
            try {
                const issue = await createIssue(formData);
                insertIssue(issue);
                toast.success('Issue created', 'The issue was created successfully.');
            } catch (e) {
                toastAxiosError(e, 'Error updating Issue');
            } finally {
                setIsSubmitting(false);
            }
        })();
    });

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header>
                <Modal.Title>
                    Create Issue
                </Modal.Title>
            </Modal.Header>
            <Form onSubmit={onSubmit} noValidate>
                <Modal.Body>
                    <CreateIssueForm control={control} disabled={isSubmitting} setValue={setValue}/>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" variant="primary" disabled={isSubmitting}>
                        Create
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};
