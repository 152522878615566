import React from 'react';
import {BackendService} from 'shared/models/systemTask';
import {Badge} from 'react-bootstrap';

interface IServiceBadgeProps {
    service: BackendService;
}

export const ServiceBadge = ({service}: IServiceBadgeProps) => {
    const variant = React.useMemo<string>(() => {
        switch (service) {
            case BackendService.Chat:
                return 'success';
            case BackendService.Main:
                return 'primary';
            case BackendService.Document:
                return 'warning';
            case BackendService.Notification:
                return 'info';
            case BackendService.File:
                return 'dark';
            case BackendService.User:
                return 'danger';
            case BackendService.Help:
                return 'indigo';
            default:
                return 'light';
        }
    }, [service]);

    return (
        <Badge variant={variant} className="badge--lg">{service}</Badge>
    );
};
