import React from 'react';

import {Button, Badge} from 'react-bootstrap';

import {ISystemTask} from 'shared/models/systemTask';
import {useBool} from 'shared/hooks/useBool';
import {toast} from 'shared/utils/toast';
import {toastAxiosError} from 'shared/utils/error';

import {Spinner} from 'shared/components/loading/Spinner/Spinner';

interface IProfileAdminActionsRowProps {
    userId: string;
    systemTask: ISystemTask<string>;
}

export const ProfileAdminActionsRow = ({userId, systemTask}: IProfileAdminActionsRowProps) => {
    const [isSubmitting, setIsSubmitting] = useBool();

    const onClick = (e: React.MouseEvent) => {
        e.preventDefault();

        setIsSubmitting(true);
        (async () => {
            try {
                await systemTask.action(userId);
                toast.success(systemTask.name, 'Task completed successfully');
            } catch (e) {
                toastAxiosError(e, systemTask.name);
            } finally {
                setIsSubmitting(false);
            }
        })();
    };

    return (
        <div className="d-flex justify-content-between py-2 border-bottom">
            <div>
                {systemTask.name}
                {systemTask.isSafe ? (
                    <Badge className="ml-2" variant="success">Safe to Run</Badge>
                ) : (
                    <Badge className="ml-2" variant="danger">Danger</Badge>
                )}
            </div>
            <div>
                <Button
                    variant="primary"
                    onClick={onClick}
                    className="d-flex align-items-center w-100 justify-content-center"
                    disabled={isSubmitting}
                    size="sm"
                >
                    {isSubmitting && (
                        <Spinner size="sm" className="mr-1"/>
                    )}
                    Run
                </Button>
            </div>
        </div>
    );
};
