import {atom, selectorFamily} from 'recoil';

import {IProfileQuery} from 'modules/profile/models/IProfileListQuery';
import {readProfileCount} from 'modules/profile/api/profile';
import {compareProfileFilters} from '../utils';
import {guardRecoilDefaultValue} from 'shared/recoil/utils';

interface IProfileCountState {
    filters: IProfileQuery;
    profileCount: number;
    resetVersion: number;

    [key: string]: number | IProfileQuery;
}

export const profileCountAtom = atom<IProfileCountState | undefined>({
    key: 'profileCountAtom',
    default: undefined,
});

export const profileCountResetAtom = atom<number>({
    key: 'profileCountResetAtom',
    default: 0,
});

export const profileCountSelector = selectorFamily<IProfileCountState | undefined, IProfileQuery>({
    key: 'profileCountSelector',
    get: (filters) => ({get}) => {
        const atomValue = get(profileCountAtom);
        const resetVersion = get(profileCountResetAtom);
        if (
            atomValue &&
            compareProfileFilters(filters, atomValue.filters) &&
            atomValue.resetVersion === resetVersion
        ) {
            return atomValue;
        }
        return undefined;
    },
    set: (_) => ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue) && newValue) {
            return null;
        }
        set(profileCountAtom, newValue);
    },
});

export const profileCountReadSelector = selectorFamily<IProfileCountState, IProfileQuery>({
    key: 'profileCountReadSelector',
    get: (filters) => async ({get}) => {
        const currentValue = get(profileCountSelector(filters));
        if (currentValue) {
            return currentValue;
        }

        const resetVersion = get(profileCountResetAtom);
        const profileCountResult = await readProfileCount({
            ...filters,
        });

        return {
            filters,
            profileCount: profileCountResult.profileCount,
            resetVersion,
        } as IProfileCountState;
    },
});
