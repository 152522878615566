import {useRecoilLoadable} from 'shared/recoil/hooks/useRecoilLoadable';
import {postCountSelector, postCountReadSelector} from 'modules/post/state/post-count';
import {IPostQuery} from 'modules/post/models/IPostListQuery';

export const usePostCountLoadable = (filters: IPostQuery) => {
    const {data, loading, error} = useRecoilLoadable({
        state: postCountSelector(filters),
        loadable: postCountReadSelector(filters),
    });
    return {
        postCount: data?.postCount,
        loading,
        error,
    };
};
