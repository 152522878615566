import React from 'react';

import {Link} from 'react-router-dom';

import {IProfile} from 'modules/profile/models/IProfile';
import {getFullName} from 'modules/profile/utils';

import {IconProfile, IconBin} from 'shared/icons/common';
import {TableDropdownItem} from 'shared/components/TableDropdown/TableDropdownItem';
import {UserIdProfilePicture} from 'shared/components/user/UserIdProfilePicture/UserIdProfilePicture';
import {FormatDate} from 'shared/components/date/FormatDate/FormatDate';
import {ProfileStatusBadge} from 'shared/components/ProfileStatusBadge/ProfileStatusBadge';
import {TableDropdown} from 'shared/components/TableDropdown/TableDropdown';

interface IProfileListRowProps {
    profile: IProfile;
    onDelete: () => void;
}

export const ProfileListRow = ({profile, onDelete}: IProfileListRowProps) => {
    const linkPath = `/profiles/${profile.id}`;
    const fullName = getFullName(profile);

    return (
        <tr>
            <td className="">
                <Link to={linkPath} className="table__link">
                    <UserIdProfilePicture id={profile.id} imgClassName="article" round size="xs" table/>
                </Link>
            </td>
            <td>
                <Link to={linkPath} className="table__link">{fullName}</Link>
            </td>
            <td className="d-none d-md-table-cell">
                {profile.id}
            </td>
            <td>
                <FormatDate>{profile.created_at}</FormatDate>
            </td>
            <td>
                {profile.profession}
            </td>
            <td>
                <ProfileStatusBadge isFeatured={profile.is_featured} isPublished={profile.is_published}/>
            </td>
            <td className="table__options-col">
                <TableDropdown>
                    <TableDropdownItem
                        to={`/profiles/${profile.id}`}
                        variant="success"
                        icon={IconProfile}
                    >
                        View Profile
                    </TableDropdownItem>
                    <TableDropdownItem
                        onClick={onDelete}
                        variant="danger"
                        icon={IconBin}
                    >
                        Delete Profile
                    </TableDropdownItem>
                </TableDropdown>
            </td>
        </tr>
    );
};
