import {
    endOfDay,
    format,
    formatDistanceStrict,
    formatDistanceToNowStrict,
    formatRelative,
    isSameDay,
    parseISO,
    startOfDay,
} from 'date-fns';
import {format as formatTZ, utcToZonedTime} from 'date-fns-tz';

export const shortDateFormat = 'dd/MM/yyyy';
export const readableShortDateFormat = 'do MMM yyyy';
export const readableLongDateFormat = 'do MMMM, yyyy';
export const longDateFormat = 'd MMMM yyyy';
export const timeFormat = 'h:mm aa';
export const filenameTimestampFormat = 'yyyy-MM-dd HH:mm';
export const isoDateTimeFormat = 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSSxxx';

interface IParseAndFormatDistanceProps {
    date: string | Date;
    addSuffix?: boolean;
}

/**
 * Parse a date that might also be an ISO UTC string.
 * @param date
 */
export const safelyParseDate = (date: Date | string): Date => {
    return (typeof date === 'string') ? parseISO(date) : date;
};

export const formatFromNow = (date: Date | string) => {
    return formatDistanceStrict(safelyParseDate(date), new Date(), {addSuffix: true});
};

export const formatDateShort = (date: Date | string) => {
    return format(safelyParseDate(date), 'dd/MM/yyyy');
};

export const formatDateOnlyReadableShort = (date: Date | string) => {
    return format(safelyParseDate(date), 'do MMM yyyy');
};

export const formatDateOnlyReadableLong = (date: Date | string) => {
    return format(safelyParseDate(date), 'do MMMM, yyyy');
};

export const formatTimeRelative = (date: Date | string) => {
    const dateAsDate = safelyParseDate(date);
    const now = new Date();
    if (isSameDay(dateAsDate, now)) {
        return format(dateAsDate, 'h:mm aa');
    } else {
        return formatRelative(dateAsDate, now);
    }
};

export const formatFilenameTimestamp = (date: Date | string) => {
    const dateAsDate = safelyParseDate(date);
    return format(dateAsDate, 'yyyy-MM-dd HH:mm');
};

export const formatHoursAndMinutesAsTime = (hours: number, minutes: number) => {
    const date = new Date(1999, 1, 1, hours, minutes);
    return format(date, 'h:mm aa');
};

export const formatStartOfTodayUTC = () => {
    return formatISOUTC(startOfDay(new Date()));
};

export const formatEndOfTodayUTC = () => {
    return formatISOUTC(endOfDay(new Date()));
};

export const getTimezone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const formatISOUTC = (date: Date): string => {
    return formatTZ(utcToZonedTime(date, 'UTC'), 'yyyy-MM-dd\'T\'HH:mm:ss.SSSSSSxxx', {timeZone: 'UTC'});
};

export const parseAndFormatDistance = ({date, addSuffix}: IParseAndFormatDistanceProps) => {
    const parsedDate = safelyParseDate(date);
    return formatDistanceToNowStrict(parsedDate, {
        addSuffix: addSuffix ?? false,
    });
};
