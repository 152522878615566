import {useRecoilLoadable} from 'shared/recoil/hooks/useRecoilLoadable';
import {IProfileQuery} from 'modules/profile/models/IProfileListQuery';
import {profileCountSelector, profileCountReadSelector} from 'modules/profile/state/profile-count';

export const useProfileCountLoadable = (filters: IProfileQuery) => {
    const {data, loading, error} = useRecoilLoadable({
        state: profileCountSelector(filters),
        loadable: profileCountReadSelector(filters),
    });
    return {
        profileCount: data?.profileCount,
        loading,
        error,
    };
};
