import React from 'react';

import {Badge} from 'react-bootstrap';
import {IPost} from 'modules/post/models/IPost';

interface IPostStatusBadgeProps {
    post: IPost;
}

interface IBadgeProps {
    variant: string;
    title: string;
}

export const PostStatusBadge = ({post}: IPostStatusBadgeProps) => {
    const badgeProps = React.useMemo<IBadgeProps>(() => {
        if (post.is_featured && post.is_published) {
            return {
                variant: 'success',
                title: 'Featured',
            };
        } else if (post.is_published) {
            return {
                variant: 'info',
                title: 'Published',
            };
        } else {
            return {
                variant: 'secondary',
                title: 'Hidden',
            };
        }
    }, [post]);

    return (
        <Badge variant={badgeProps.variant} className="badge--lg">
            {badgeProps.title}
        </Badge>
    );
};
