import React from 'react';

import {Card, Row, Col, Badge} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExternalLinkAlt} from '@fortawesome/free-solid-svg-icons';

import {IProfile} from 'modules/profile/models/IProfile';
import {IUser} from 'modules/user/models';
import {getFullName} from 'modules/profile/utils';
import {formatDateShort} from 'shared/utils/date';
import {Config} from 'config';

import {ProfilePicture} from 'shared/components/ProfilePicture/ProfilePicture';
import {ProfileStatusBadge} from 'shared/components/ProfileStatusBadge/ProfileStatusBadge';
import {UploadProfilePicture} from 'components/profile/shared/UploadProfilePicture/UploadProfilePicture';

import './style.scss';

interface IProfileDetailsCardProps {
    profile: IProfile;
    user: IUser;
}

export const ProfileDetailsCard = ({profile, user}: IProfileDetailsCardProps) => {
    const fullName = getFullName(profile);

    return (
        <Card>
            <Card.Body>
                <Row>
                    <Col xl={2} className="ProfileDetailsCard__image-col">
                        <div className="d-flex align-items-center flex-column">
                            <ProfilePicture
                                name={fullName}
                                id={profile.image_id}
                                alt={fullName}
                                url={profile.image_url}
                                round
                                size="profileimg"
                            />
                        </div>
                        {/* TODO: add upload picture button */}
                        <div className="d-flex align-items-center flex-column">
                            <UploadProfilePicture userId={profile.id}/>
                        </div>
                    </Col>
                    <Col xl={10} className="ProfileDetailsCard__details-col">
                        <Row>
                            <Col xl={3}>
                                <p className="ProfileDetailsCard__field-label">Profile ID</p>
                                <p className="ProfileDetailsCard__field-value">{profile.id}</p>
                            </Col>

                            <Col xl={3}>
                                <p className="ProfileDetailsCard__field-label">Created At</p>
                                <p className="ProfileDetailsCard__field-value">
                                    {profile.created_at ? formatDateShort(profile.created_at) : 'Never'}
                                </p>
                            </Col>
                            <Col xl={3}>
                                <p className="ProfileDetailsCard__field-label">Featured At</p>
                                <p className="ProfileDetailsCard__field-value">
                                    {profile.featured_at ? formatDateShort(profile.featured_at) : 'Never'}
                                </p>
                            </Col>
                            <Col xl={3}>
                                <p className="ProfileDetailsCard__field-label">Published At</p>
                                <p className="ProfileDetailsCard__field-value">
                                    {profile.published_at ? formatDateShort(profile.published_at) : 'Never'}
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={3}>
                                <p className="ProfileDetailsCard__field-label">Auth0 User ID</p>
                                <span className="ProfileDetailsCard__field-value">
                                    {user.id}
                                    <a
                                        href={`${Config.auth0DashboardURL}/users/${user.id}`}
                                        target="_blank"
                                        rel="noreferrer"
                                        className="ml-1"
                                    >
                                        <FontAwesomeIcon icon={faExternalLinkAlt}/>
                                    </a>
                                </span>
                            </Col>
                            <Col xl={3}>
                                <p className="ProfileDetailsCard__field-label">Email Address</p>
                                <span className="ProfileDetailsCard__field-value">
                                    {user.email}
                                    {user.is_verified ? (
                                        <Badge variant="success" className="ml-2">Verified</Badge>
                                    ) : (
                                        <Badge variant="danger" className="ml-2">Not Verified</Badge>
                                    )}
                                </span>
                            </Col>
                            <Col xl={3}>
                                <p className="ProfileDetailsCard__field-label">Role</p>
                                <Badge variant="primary" pill className="badge--lg">{profile.role}</Badge>
                            </Col>
                            <Col xl={3}>
                                <p className="ProfileDetailsCard__field-label">Status</p>
                                <p className="ProfileDetailsCard__field-value">
                                    <ProfileStatusBadge
                                        isFeatured={profile.is_featured}
                                        isPublished={profile.is_published}
                                    />
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
};
