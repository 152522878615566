import {selector} from 'recoil';

import {tagListAtom} from 'modules/tag/state/tag-list';
import {throwWriteOnlySelectorError, guardRecoilDefaultValue} from 'shared/recoil/utils';

export const tagRemoveSelector = selector<string>({
    key: 'tagRemoveSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }

        const tagList = get(tagListAtom);
        if (tagList) {
            set(tagListAtom, tagList.filter(tag => tag.id !== newValue));
        }
    },
});
