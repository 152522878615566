import {selectorFamily, atomFamily} from 'recoil';

import {IPost} from 'modules/post/models/IPost';
import {readPost} from 'modules/post/api';
import {postListAtom} from 'modules/post/state/post-list';
import {guardRecoilDefaultValue} from 'shared/recoil/utils';

export const postLookupAtom = atomFamily<IPost | undefined, string>({
    key: 'postLookupAtom',
    default: undefined,
});

export const postLookupSelector = selectorFamily<IPost | undefined, string>({
    key: 'postLookupSelector',
    get: (postId: string) => async ({get}) => {
        const atomValue = get(postLookupAtom(postId));
        if (atomValue) {
            return atomValue;
        }

        const postListState = get(postListAtom);
        const postInList = postListState?.posts?.find(post => post.id === postId);
        if (postInList) {
            return postInList;
        }

        return undefined;
    },
    set: () => ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue) || !newValue) {
            return;
        }
        set(postLookupAtom(newValue.id), newValue);
    },
});

export const postLookupReadSelector = selectorFamily<IPost, string>({
    key: 'postLookupReadSelector',
    get: (postId: string) => async ({get}): Promise<IPost> => {
        const currentValue = get(postLookupSelector(postId));
        if (currentValue) {
            return currentValue;
        }
        return await readPost(postId);
    },
});
