export enum PlanFrequency {
    Month = 'MONTH',
    Year = 'YEAR',
    Week = 'WEEK',
    Day = 'DAY',
}

export enum SubscriptionStatus {
    NotConfigured = 'NOT_CONFIGURED',
    Incomplete = 'INCOMPLETE',
    Active = 'ACTIVE',
    Failed = 'FAILED',
}

export interface IBillingAddress {
    line1?: string;
    line2?: string;
    city?: string;
    state?: string;
    country?: string;
    postal_code?: string;
}

export interface IBillingPlan {
    currency: string;
    description?: string;
    frequency: PlanFrequency;
    name: string;
    price: number;
    price_id: string;
    price_key: string;
    product_id: string;
}

export interface IBillingSubscription {
    user_id: string;
    customer_id: string;
    plan: IBillingPlan;
    status: SubscriptionStatus;
}
