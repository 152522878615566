import React from 'react';

import {Col, Row} from 'react-bootstrap';
import {Control} from 'react-hook-form';

import {IProfile} from 'modules/profile/models/IProfile';
import {IProfileUpdate} from 'modules/profile/models/IProfileUpdate';

import {FormCard} from 'components/profile/shared/FormCard/FormCard';
import {FormController} from 'shared/components/form/FormController/FormController';

interface IProfileFormAboutMeProps {
    profile: IProfile;
    control: Control<IProfileUpdate>;
    isSubmitting: boolean;
}

export const ProfileFormAboutMe = ({control, isSubmitting}: IProfileFormAboutMeProps) => {

    return (
        <FormCard title="About Me">
            <Row>
                <Col lg={12}>
                    <FormController
                        name="summary"
                        label="Professional profile summary description"
                        as="textarea"
                        rows={5}
                        control={control}
                        disabled={isSubmitting}
                    />
                </Col>
            </Row>
        </FormCard>
    );
};
