import React from 'react';

import {Table} from 'react-bootstrap';

import {IPost} from 'modules/post/models/IPost';

import {PaginationControls} from 'shared/components/PaginationControls/PaginationControls';
import {DeleteResourceModal} from 'components/resources/ResourceList/DeleteResourceModal/DeleteResourceModal';
import {ResourceListRow} from 'components/resources/ResourceList/ResourceListRow/ResourceListRow';

interface IResourceListTableProps {
    page: number;
    more: boolean;
    onPageChange: (page: number) => void;
    posts: IPost[];
}

export const ResourceListTable = ({page, onPageChange, posts, more}: IResourceListTableProps) => {
    const [showDeleteModal, setShowDeleteModal] = React.useState<boolean>(false);
    const [postToDelete, setPostToDelete] = React.useState<IPost | null>(null);

    const onDeletePost = (post: IPost) => {
        setPostToDelete(post);
        setShowDeleteModal(true);
    };

    const closeDeleteModal = () => {
        setShowDeleteModal(false);
        setPostToDelete(null);
    };

    return (
        <>
            <Table className="table-fixed">
                <thead>
                <tr>
                    <th className="table__picture-col"/>
                    <th>Title</th>
                    <th>Status</th>
                    <th>Kind</th>
                    <th>Author</th>
                    <th>Created At</th>
                    <th>Tags</th>
                    <th className="table-Title-align-left">Options</th>
                </tr>
                </thead>
                <tbody>
                {posts.map(post => (
                    <ResourceListRow
                        key={post.id}
                        post={post}
                        onDeletePost={() => onDeletePost(post)}
                    />
                ))}
                </tbody>
            </Table>
            <PaginationControls page={page} onChange={onPageChange} isMore={more}/>
            {postToDelete && (
                <DeleteResourceModal post={postToDelete} show={showDeleteModal} onHide={closeDeleteModal}/>
            )}
        </>
    );
};
