import React from 'react';

import {IProfile} from 'modules/profile/models/IProfile';
import {ISystemTask} from 'shared/models/systemTask';
import {sendVerificationEmail, deleteUserVerification} from 'modules/user/api';

import {FormCard} from 'components/profile/shared/FormCard/FormCard';
import {ProfileAdminActionsRow} from 'components/profile/ViewProfile/ProfileAdminActions/ProfileAdminActionsRow';

interface IProfileAdminActionsProps {
    profile: IProfile;
}

const profileAdminTasks: ISystemTask<string>[] = [
    {
        name: 'Un-verify account',
        action: deleteUserVerification,
        isSafe: true,
    },
    {
        name: 'Send Verification Email',
        action: sendVerificationEmail,
        isSafe: true,
    },
];

export const ProfileAdminActions = ({profile}: IProfileAdminActionsProps) => {

    return (
        <FormCard title="Admin Tasks">
            {profileAdminTasks.map(profileAdminTask => (
                <ProfileAdminActionsRow key={profileAdminTask.name} userId={profile.id} systemTask={profileAdminTask}/>
            ))}
        </FormCard>
    );
};
