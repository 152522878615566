import React from 'react';

import {useAuth} from 'shared/auth/hooks/useAuth';

import {Spinner} from 'shared/components/loading/Spinner/Spinner';

export const LogoutPage = () => {
    const {logout} = useAuth();

    React.useEffect(() => {
        logout({returnTo: window.location.origin});
    });

    return (
        <div className="p-5 text-center">
            <Spinner/>
        </div>
    );
};
