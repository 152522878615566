import React from 'react';

import {Route, Routes} from 'react-router-dom';

import {Scope} from 'shared/auth/models';

import {LogoutPage} from 'shared/auth/components/LogoutPage';
import {RequireAuth} from 'shared/auth/components/RequireAuth/RequireAuth';

interface IAppWrapperProps {
    children: React.ReactNode;
}

export const AppWrapper = ({children}: IAppWrapperProps) => {

    return (
        <div className="App browser-height">
            <Routes>
                <Route
                    path="/logout"
                    element={(
                        <LogoutPage/>
                    )}
                />
                <Route
                    path="*"
                    element={(
                        <RequireAuth scopes={[Scope.System]}>
                            {children}
                        </RequireAuth>
                    )}
                />
            </Routes>
        </div>
    );
};
