import React from 'react';

import {Control, Path} from 'react-hook-form';
import {Button, Card, Form} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronUp} from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';

import {IArticleCreate} from 'modules/help/models';
import {useGuaranteedAuth} from 'shared/auth/hooks/useAuth';

import {InputController} from 'shared/components/form/InputController/InputController';
import {FileDropzoneController} from 'shared/components/form/FileDropzoneController/FileDropzoneController';
import {ArticleEditorController} from 'shared/components/form/ArticleEditorController/ArticleEditorController';

interface ICreateArticleSectionProps {
    name: Path<IArticleCreate>;
    index: number;
    disabled?: boolean;
    onDelete: (index: number) => void;
    control: Control<IArticleCreate>;
    activeAccordion: number;
    setActiveAccordion: (index: number) => void;
}

export const CreateArticleSection = ({
    name,
    activeAccordion,
    index,
    disabled,
    control,
    onDelete,
    setActiveAccordion,
}: ICreateArticleSectionProps) => {
    const {tokenData} = useGuaranteedAuth();
    const contentRef = React.useRef<HTMLDivElement>(null);
    const [maxHeightAccordion, setMaxHeightAccordion] = React.useState<string | number>('');

    const AccordionFormClass = classnames('AdminAddArticle__Accordion', {
        'AdminAddArticle__Accordion--not-active': activeAccordion !== index,
    });
    const RotateIconClass = classnames('AdminAddArticle__RotateIcon', {
        'AdminAddArticle__RotateIcon--not-active': activeAccordion !== index,
    });

    React.useEffect(() => {
        setMaxHeightAccordion(
            activeAccordion === index && contentRef && contentRef.current && contentRef.current.scrollHeight
                ? contentRef.current.scrollHeight + 140
                : '0',
        );
    }, [activeAccordion, setMaxHeightAccordion, control, contentRef, index]);

    return (
        <div className="mt-3">
            <Card className="AdminAddArticle__card">
                <Card.Body className="AdminAddArticle__body">
                    <div
                        style={{cursor: 'pointer'}}
                        onClick={() => setActiveAccordion(index)}
                        className="d-flex justify-content-between"
                    >
                        <div className=""></div>
                        <div className={RotateIconClass}>
                            <FontAwesomeIcon icon={faChevronUp}/>
                        </div>
                    </div>
                    <div
                        ref={contentRef}
                        style={{
                            maxHeight: maxHeightAccordion,

                        }}
                        className={AccordionFormClass}
                    >
                        <Form.Group className="mb-3" controlId={`${name}.name`}>
                            <Form.Label className="AdminAddArticle__form-title">Section Title</Form.Label>
                            <InputController
                                className="AdminAddArticle__form-input"
                                placeholder="Name"
                                control={control}
                                name={`${name}.name` as Path<IArticleCreate>}
                                disabled={disabled}
                            />
                        </Form.Group>
                        <div>
                            <Form.Group
                                className="mb-3 AdminAddArticle__form-group"
                                controlId={`${name}.description`}
                            >
                                <Form.Label className="AdminAddArticle__form-title">Description</Form.Label>
                                <ArticleEditorController
                                    placeholder="Description"
                                    control={control}
                                    name={`${name}.description` as Path<IArticleCreate>}
                                    disabled={disabled}
                                />
                            </Form.Group>
                        </div>
                        <div>
                            <Form.Group className="mb-3 AdminAddArticle__form-group" controlId={`${name}.file_id`}>
                                <Form.Label className="AdminAddArticle__form-title">Image</Form.Label>
                                <FileDropzoneController
                                    userId={tokenData.id}
                                    name={`${name}.file_id` as Path<IArticleCreate>}
                                    control={control}
                                    disabled={disabled}
                                    folder={`user/${tokenData}/post`}
                                />
                            </Form.Group>
                        </div>
                        <div className="text-right mt-3">
                            <Button
                                onClick={() => {
                                    onDelete(index);
                                }}
                                className="btn-featured-red"
                                disabled={disabled}
                            >
                                Remove Section
                            </Button>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
};
