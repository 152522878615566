import React from 'react';

import {Dropdown} from 'react-bootstrap';

import {IDropdownSelectOption} from 'shared/components/form/DropdownSelect/DropdownSelect';

interface IDropdownSelectItemProps {
    option: IDropdownSelectOption;
    onClick: () => void;
    active?: boolean;
}

export const DropdownSelectItem = ({option, onClick, active}: IDropdownSelectItemProps) => {

    return (
        <Dropdown.Item
            key={option.value}
            href={`#/${option.value}`}
            onClick={() => onClick()}
            active={active}
        >
            {option.title}
        </Dropdown.Item>
    );
};
