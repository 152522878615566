import React, {useState} from 'react';

import {Button, Form, Row, Modal} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {useSetRecoilState} from 'recoil';
import {zodResolver} from '@hookform/resolvers/zod';

import {IIssue} from 'modules/issue/models';
import {toastAxiosError} from 'shared/utils/error';
import {toast} from 'shared/utils/toast';
import {updateIssue} from 'modules/issue/api';
import {createIssueForm, IIssueForm} from 'modules/issue/forms';

import {CreateIssueForm} from 'components/issues/shared/CreateIssueModal/CreateIssueForm';

import './style.scss';
import {issueInsertSelector} from 'modules/issue/state/issue-insert';

interface ICreateIssueModalProps {
    show: boolean;
    onHide: () => void;
    issue?: IIssue;
}

export const UpdateIssueModal = ({onHide, show, issue}: ICreateIssueModalProps) => {
    const {control, handleSubmit, reset, setValue} = useForm<IIssueForm>({
        resolver: zodResolver(createIssueForm),
    });

    React.useEffect(() => {
        if (show && issue) {
            reset({
                ...issue,
            });
        }
    }, [issue, show, reset]);

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const insertIssue = useSetRecoilState(issueInsertSelector);

    const onSubmit = handleSubmit((formData) => {
        if (isSubmitting || !issue) {
            return;
        }
        setIsSubmitting(true);

        (async () => {
            try {
                const updatedIssue = await updateIssue(issue.id, formData);
                insertIssue(updatedIssue);
                toast.success('Issue updated', 'The issue was updated successfully.');
                resetForm();
            } catch (e) {
                toastAxiosError(e, 'Error updating Issue');
            } finally {
                setIsSubmitting(false);
            }
        })();
    });

    const resetForm = () => {
        reset();
        onHide();
    };

    return (
        <Modal show={show} onHide={onHide} size="lg">
            <Modal.Header>
                <Modal.Title>
                    Update Issue
                </Modal.Title>
            </Modal.Header>
            <Form onSubmit={onSubmit} noValidate>
                <Modal.Body>
                    <Row className="CreateIssue">
                        <CreateIssueForm control={control} disabled={isSubmitting} setValue={setValue}/>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" variant="primary" disabled={isSubmitting}>
                        Save
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};
