import {useRecoilLoadable} from 'shared/recoil/hooks/useRecoilLoadable';
import {profileLookupSelector, profileLookupReadSelector} from 'modules/profile/state/profile-lookup';

export const useProfileLoadable = (profileId: string) => {
    const {data, loading, error} = useRecoilLoadable({
        state: profileLookupSelector(profileId),
        loadable: profileLookupReadSelector(profileId),
    });
    return {
        profile: data,
        loading,
        error,
    };
};
