import React from 'react';

import {Spinner} from 'shared/components/loading/Spinner/Spinner';

export const LoadingSpinner = () => {
    return (
        <div className="p-3 text-center">
            <Spinner/>
        </div>
    );
};
