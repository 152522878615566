import {categoryListReadSelector, categoryListSelector} from '../state/category-list';
import {useRecoilLoadable} from 'shared/recoil/hooks/useRecoilLoadable';

export const useCategoryListLoadable = () => {
    const {data, loading, error} = useRecoilLoadable({
        state: categoryListSelector,
        loadable: categoryListReadSelector,
    });
    return {
        categories: data?.categories,
        loading,
        error,
    };
};
