import axios from 'axios';

import {Config} from 'config';
import {IProfileUpdate} from 'modules/profile/models/IProfileUpdate';
import {IProfile} from 'modules/profile/models/IProfile';
import {IProfileListQuery, IProfileQuery} from 'modules/profile/models/IProfileListQuery';
import {IProfileListResult} from 'modules/profile/models/IProfileListResult';
import {IProfileSummary} from 'modules/profile/models/IProfileSummary';
import {IProfileCountResult} from 'modules/profile/models/IProfileCountResult';
import {getAccessTokenSilently} from 'shared/utils/token';
import {getAuthHeaders} from 'shared/utils/auth';

const getURLForProfileAPI = (path: string): string => {
    return `${Config.mainServiceURL}/profiles${path}`;
};

const getURLForProfileSummaryAPI = (path: string) => {
    return `${Config.mainServiceURL}/profile_summary${path}`;
};

export const processProfile = (profile: IProfile): IProfile => {
    if (!profile.skills) {
        profile.skills = [];
    }
    if (!profile.work_experience) {
        profile.work_experience = [];
    }
    if (!profile.extra_experience) {
        profile.extra_experience = [];
    }
    if (!profile.tag_ids) {
        profile.tag_ids = [];
    }
    if (!profile.service_types) {
        profile.service_types = [];
    }
    if (!profile.social_links) {
        profile.social_links = [];
    }
    return profile;
};

export const readProfile = async (profileId: string): Promise<IProfile> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getURLForProfileAPI(`/${profileId}`), {
        headers: getAuthHeaders(accessToken),
    });
    return processProfile(response.data);
};

export const readProfileList = async ({
    isPublished,
    isFeatured,
    profession,
    searchTerm,
    limit,
    cursor,
}: IProfileListQuery): Promise<IProfileListResult> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getURLForProfileAPI('/'), {
        withCredentials: true,
        headers: getAuthHeaders(accessToken),
        params: {
            search_term: searchTerm,
            is_published: isPublished,
            is_featured: isFeatured,
            profession,
            limit,
            cursor,
        },
    });
    return {
        profiles: response.data?.profiles,
        nextCursor: response.data?.next_cursor,
    };
};

export const updateProfile = async (profileId: string, profileUpdate: IProfileUpdate): Promise<IProfile> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.patch(getURLForProfileAPI(`/${profileId}`), profileUpdate, {
        headers: getAuthHeaders(accessToken),
    });
    return processProfile(response.data);
};

export const readProfileSummary = async (profileId: string): Promise<IProfileSummary> => {
    const response = await axios.get(getURLForProfileSummaryAPI(`/${profileId}`));
    return response.data;
};

export const deleteProfile = async (profileId: string) => {
    const accessToken = await getAccessTokenSilently();
    await axios.delete(getURLForProfileAPI(`/${profileId}`), {
        headers: getAuthHeaders(accessToken),
    });
    return;
};

export const readProfileCount = async ({
    isPublished,
    isFeatured,
    profession,
    searchTerm,
}: IProfileQuery): Promise<IProfileCountResult> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getURLForProfileAPI('/count'), {
        withCredentials: true,
        headers: getAuthHeaders(accessToken),
        params: {
            search_term: searchTerm,
            is_published: isPublished,
            is_featured: isFeatured,
            profession,
        },
    });
    return {
        profileCount: response.data?.profile_count,
    };
};
