import React from 'react';

import {Form, Button} from 'react-bootstrap';
import {useSetRecoilState} from 'recoil';

import {closeJob} from 'modules/job/api';
import {jobInsertSelector} from 'modules/job/state/job-insert';
import {toast} from 'shared/utils/toast';
import {toastAxiosError} from 'shared/utils/error';
import {useBool} from 'shared/hooks/useBool';

import {Modal} from 'react-bootstrap';
import {ModalBody, ModalFooter} from 'react-bootstrap';
import {Spinner} from 'shared/components/loading/Spinner/Spinner';

interface ICloseJobModalProps {
    jobId?: string;
    show: boolean;
    onHide: () => void;
}

export const CloseJobModal = ({jobId, show, onHide}: ICloseJobModalProps) => {
    const [isSubmitting, setIsSubmitting] = useBool();

    const insertJob = useSetRecoilState(jobInsertSelector);

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (!jobId) {
            return;
        }
        setIsSubmitting(true);
        (async () => {
            try {
                const job = await closeJob(jobId);
                insertJob(job);
                toast.success('Job Cancelled', 'The job was successfully cancelled');
                onHide();
            } catch (e) {
                toastAxiosError(e, 'Job Cancel Error');
            } finally {
                setIsSubmitting(false);
            }
        })();
    };

    React.useEffect(() => {
        if (!show) {
            setIsSubmitting(false);
        }
    }, [show, setIsSubmitting]);

    return (
        <Modal show={show} onHide={onHide} isSubmitting={isSubmitting} title="Close Job">
            <Form onSubmit={onSubmit}>
                <ModalBody>
                    Are you sure you want to close this job?
                </ModalBody>
                <ModalFooter>
                    <Button type="button" variant="outline-secondary" disabled={isSubmitting} onClick={onHide}>
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        variant="danger"
                        disabled={isSubmitting}
                        className="d-flex align-items-center"
                    >
                        {isSubmitting && (
                            <div>
                                <Spinner size="sm" className="mr-1"/>
                            </div>
                        )}
                        Close Job
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
};
