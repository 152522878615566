import React from 'react';

import {Link} from 'react-router-dom';

import {ITag} from 'modules/tag/models';

import {FormatDate} from 'shared/components/date/FormatDate/FormatDate';
import {TableDropdown} from 'shared/components/TableDropdown/TableDropdown';
import {Tag} from 'shared/components/tag/Tag/Tag';
import {TableDropdownItem} from 'shared/components/TableDropdown/TableDropdownItem';
import {IconBin, IconEdit} from 'shared/icons/common';

interface ITagListRowProps {
    tag: ITag;
    handleDelete: () => void;
    handleUpdate: () => void;
}

export const TagListRow = ({tag, handleDelete, handleUpdate}: ITagListRowProps) => {
    return (
        <tr>
            <td>
                <Link to="#" onClick={() => handleUpdate()} className="table__link">
                    {tag.title}
                </Link>
            </td>
            <td className="table__badge-col-lg">
                <Tag color={tag.color} title={tag.title} size="lg" badge={true}/>
            </td>
            <td className="table__date-col">
                <FormatDate>{tag.created_at}</FormatDate>
            </td>
            <td className="table__options-col">
                <TableDropdown>
                    <TableDropdownItem onClick={handleUpdate} icon={IconEdit} variant="warning">
                        Edit Tag
                    </TableDropdownItem>
                    <TableDropdownItem onClick={handleDelete} icon={IconBin} variant="danger">
                        Delete Tag
                    </TableDropdownItem>
                </TableDropdown>
            </td>
        </tr>
    );
};
