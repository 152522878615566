import React from 'react';

import {Container} from 'react-bootstrap';
import classNames from 'classnames';

import {AppNavbar} from 'shared/components/app/AppNavbar/AppNavbar';
import {DashboardSidebar} from 'shared/components/app/DashboardSidebar/DashboardSidebar';

import 'shared/components/app/style.scss';

interface IDashboardProps {
    children: React.ReactNode;
}

export const Dashboard = ({children}: IDashboardProps) => {
    const [isCollapsed, setIsCollapsed] = React.useState<boolean>(false);

    const className = classNames('Dashboard__content', {
        'Dashboard__content--collapsed': isCollapsed,
    });

    return (
        <div className="Dashboard browser-height">
            <DashboardSidebar
                onCollapse={setIsCollapsed}
                collapse={isCollapsed}
            />
            <div className={className}>
                <AppNavbar/>
                <Container fluid={true} className="Dashboard__container">
                    {children}
                </Container>
            </div>
        </div>
    );
};
