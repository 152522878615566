import {DefaultValue, selector} from 'recoil';

import {IPost} from 'modules/post/models/IPost';
import {postListInsertSelector} from 'modules/post/state/post-list';
import {resetFeaturedPosts} from 'modules/post/state/featured-post';
import {postLookupAtom} from 'modules/post/state/post-lookup';

export const insertPostSelector = selector<IPost>({
    key: 'insertPostSelector',
    get: () => {
        throw new Error('This is a "write only" selector');
    },
    set: ({get, set}, newValue) => {
        if (newValue instanceof DefaultValue) {
            return;
        }

        set(postLookupAtom(newValue.id), newValue);
        set(postListInsertSelector, newValue);
        set(resetFeaturedPosts, get(resetFeaturedPosts) + 1);
    },
});
