import React from 'react';

import {Col, Row} from 'react-bootstrap';
import {Control} from 'react-hook-form';

import {IProfile} from 'modules/profile/models/IProfile';
import {IProfileUpdate} from 'modules/profile/models/IProfileUpdate';
import {professionValues} from 'modules/profile/constants';

import {FormCard} from 'components/profile/shared/FormCard/FormCard';
import {FormController} from 'shared/components/form/FormController/FormController';
import {CheckboxController} from 'shared/components/form/CheckboxController/CheckboxController';

interface IProfileFormDetailsProps {
    profile: IProfile;
    control: Control<IProfileUpdate>;
    isSubmitting: boolean;
}

export const ProfileFormDetails = ({control, isSubmitting}: IProfileFormDetailsProps) => {

    return (
        <FormCard title="Personal Information">
            <Row>
                <Col lg={3}>
                    <FormController
                        name="firstname"
                        label="First Name"
                        control={control}
                        disabled={isSubmitting}
                    />
                </Col>
                <Col lg={3}>
                    <FormController
                        name="lastname"
                        label="Last Name"
                        control={control}
                        disabled={isSubmitting}
                    />
                </Col>
                <Col lg={3}>
                    <FormController
                        name="title"
                        label="Title (Ms., Mrs., Miss, Mr.)"
                        control={control}
                        disabled={isSubmitting}
                    />
                </Col>
                <Col lg={3}>
                    <FormController
                        name="profession"
                        label="Profession"
                        as="select"
                        control={control}
                        disabled={isSubmitting}
                    >
                        <option value="">
                            Select
                        </option>
                        {professionValues.map(professionVal => (
                            <option key={professionVal.value} value={professionVal.value}>
                                {professionVal.title}
                            </option>
                        ))}
                    </FormController>
                </Col>
            </Row>
            <Row>
                <Col lg={6} className="pt-5">
                    <CheckboxController
                        type="checkbox"
                        control={control}
                        name="is_published"
                        label="Is Published"
                        disabled={isSubmitting}
                        inline
                    />
                    <CheckboxController
                        type="checkbox"
                        control={control}
                        name="is_featured"
                        label="Is Featured"
                        disabled={isSubmitting}
                        inline
                    />
                    <CheckboxController
                        type="checkbox"
                        control={control}
                        name="is_owner"
                        label="Is Owner"
                        disabled={isSubmitting}
                        inline
                    />
                </Col>
            </Row>
        </FormCard>
    );
};
