export enum Scope {
    Service = 'SERVICE',
    System = 'SYSTEM_EDIT',
    SelfEdit = 'SELF_EDIT',
    RoleClient = 'ROLE_CLIENT',
    RoleProvider = 'ROLE_PROVIDER',
}

export interface ITokenData {
    id: string;
    scopes: Scope[];
    isSystem: boolean;
    picture?: string;
    nickname?: string;
    name?: string;
    email?: string;
}

export interface ILogoutOptions {
    returnTo?: string;
}

export interface ILoginOptions {
    redirectUri?: string;
}
