import React from 'react';

import {Container} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import {useAuth} from 'shared/auth/hooks/useAuth';

import {LoginButton} from 'shared/auth/components/LoginButton';
import {ProfilePicture} from 'shared/components/ProfilePicture/ProfilePicture';

export const NotAuthenticated = () => {
    const {isAuthenticated, tokenData} = useAuth();

    return (
        <Container className="p-5">
            {(isAuthenticated && tokenData) ? (
                <>
                    <div className="d-flex align-items-center mb-3">
                        <ProfilePicture url={tokenData.picture} alt={tokenData.nickname} size="xs"/>
                        <div className="ml-2">
                            <div>
                                {tokenData.nickname}
                            </div>
                            <div className="text-muted small">
                                {tokenData.email}
                            </div>
                        </div>
                    </div>
                    <p className="text-muted mb-3">
                        Your account does not have administrator permissions. Please log out and use another account.
                    </p>
                    <Link to="/logout" className="btn btn-primary">
                        Log Out
                    </Link>
                </>
            ) : (
                <>
                    <p className="text-muted mb-3">
                        You need to login with an administrator account before continuing.
                    </p>
                    <LoginButton/>
                </>
            )}
        </Container>
    );
};
