import React from 'react';

import {Badge} from 'react-bootstrap';
import classNames from 'classnames';

import {IssueStatus} from 'modules/issue/models';

import './style.scss';

interface IIssueStatusBadgeProps {
    status: string;
}

export const IssueStatusBadge = ({status}: IIssueStatusBadgeProps) => {
    const className = classNames('badge--lg', {
        'badge--success-muted': status === IssueStatus.OPEN,
        'badge--danger-muted': status !== IssueStatus.OPEN,
    });

    return (
        <Badge className={className} pill>{status}</Badge>
    );
};
