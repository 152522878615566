import React from 'react';

import {Modal} from 'react-bootstrap';

import {JobModalContent} from 'components/job/shared/JobModal/JobModalContent';

import './style.scss';

interface IJobModalProps {
    onHide: () => void;
    jobId?: string;
}

export const JobModal = ({onHide, jobId}: IJobModalProps) => {

    return (
        <Modal show={!!jobId} onHide={onHide} size="lg">
            {jobId && (
                <JobModalContent
                    onHide={onHide}
                    jobId={jobId}
                />
            )}
        </Modal>
    );
};
