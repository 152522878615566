import {selector, selectorFamily, atomFamily} from 'recoil';

import {readProfile} from 'modules/profile/api/profile';
import {IProfile} from 'modules/profile/models/IProfile';
import {profileListLookupSelector} from 'modules/profile/state/profile-list';
import {guardRecoilDefaultValue, throwWriteOnlySelectorError} from 'shared/recoil/utils';

export const profileLookupAtom = atomFamily<IProfile | undefined, string>({
    key: 'profileLookupAtom',
    default: undefined,
});

export const profileLookupSelector = selectorFamily<IProfile | undefined, string>({
    key: 'profileLookupSelector',
    get: (profileId: string) => ({get}) => {
        const atomValue = get(profileLookupAtom(profileId));
        if (atomValue) {
            return atomValue;
        }

        const profileInList = get(profileListLookupSelector(profileId));
        if (profileInList) {
            return profileInList;
        }

        return undefined;
    },
    set: () => ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue) || !newValue) {
            return;
        }
        set(profileLookupInsertSelector, newValue);
    },
});

export const profileLookupReadSelector = selectorFamily<IProfile, string>({
    key: 'profileLookupReadSelector',
    get: (profileId: string) => async ({get}): Promise<IProfile> => {
        const profile = get(profileLookupSelector(profileId));
        if (profile) {
            return profile;
        }
        return await readProfile(profileId);
    },
});

export const profileLookupInsertSelector = selector<IProfile>({
    key: 'profileLookupInsertSelector',
    get: throwWriteOnlySelectorError,
    set: ({set, get}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        set(profileLookupAtom(newValue.id), newValue);
    },
});

export const profileLookupRemoveSelector = selector<string>({
    key: 'profileLookupRemoveSelector',
    get: throwWriteOnlySelectorError,
    set: ({set, get}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        set(profileLookupAtom(newValue), undefined);
    },
});
