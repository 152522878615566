import React from 'react';

import {Form, Button} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';
import {Link} from 'react-router-dom';
import {useSetRecoilState} from 'recoil';

import {IProfile} from 'modules/profile/models/IProfile';
import {IProfileUpdate} from 'modules/profile/models/IProfileUpdate';
import {IUser} from 'modules/user/models';
import {profileInsertSelector} from 'modules/profile/state/profile-insert';
import {useBool} from 'shared/hooks/useBool';
import {updateProfile} from 'modules/profile/api/profile';
import {toast} from 'shared/utils/toast';
import {updateProfileForm} from 'modules/profile/form';

import {ProfileDetailsCard} from 'components/profile/shared/ProfileDetailsCard/ProfileDetailsCard';
import {ProfileFormPrivateContact} from 'components/profile/ViewProfile/forms/ProfileFormPrivateContact';
import {toastAxiosError} from 'shared/utils/error';
import {ProfileFormDetails} from 'components/profile/ViewProfile/forms/ProfileFormDetails';
import {ProfileFormPublicContact} from 'components/profile/ViewProfile/forms/ProfileFormPublicContact';
import {ProfileFormAboutMe} from 'components/profile/ViewProfile/forms/ProfileFormAboutMe';
import {ProfileFormClaimToFame} from 'components/profile/ViewProfile/forms/ProfileFormClaimToFame';
import {ProfileFormResume} from 'components/profile/ViewProfile/forms/ProfileFormResume';
import {ProfileFormFees} from 'components/profile/ViewProfile/forms/ProfileFormFees';
import {ProfileFormSkills} from 'components/profile/ViewProfile/forms/ProfileSettingsSkills';
import {ProfileFormTags} from 'components/profile/ViewProfile/forms/ProfileSettingsTags';
import {ProfileFormReferredBy} from 'components/profile/ViewProfile/forms/ProfileFormReferredBy';
import {ProfileAdminActions} from 'components/profile/ViewProfile/ProfileAdminActions/ProfileAdminActions';
import {ViewProfileBilling} from 'components/profile/ViewProfile/ViewProfileBilling/ViewProfileBilling';

interface IViewProfileContentProps {
    profile: IProfile;
    user: IUser;
}

const profileToProfileFormDefaults = (profile: IProfile): IProfileUpdate => {
    return {
        ...profile,
        claim_to_fame: profile.claim_to_fame || '',
        summary: profile.summary || '',
        referred_by: profile.referred_by || '',
        title: profile.title || '',
    };
};

export const ViewProfileContent = ({profile, user}: IViewProfileContentProps) => {
    const [isSubmitting, setIsSubmitting] = useBool();
    const insertProfile = useSetRecoilState(profileInsertSelector);

    const {handleSubmit, control, reset} = useForm<IProfileUpdate>({
        resolver: zodResolver(updateProfileForm),
        defaultValues: profileToProfileFormDefaults(profile),
    });

    const onSubmit = ({resume_id, ...formData}: IProfileUpdate) => {
        setIsSubmitting(true);
        (async () => {
            try {
                const updatedProfile = await updateProfile(profile.id, {
                    ...formData,
                    resume_id: resume_id ?? '', // the server will remove it if set to an empty string
                });
                insertProfile(updatedProfile);
                toast.success('Update Profile', 'Profile updated successfully');
                reset(profileToProfileFormDefaults(updatedProfile));
            } catch (e) {
                toastAxiosError(e, 'Update Profile Error');
            } finally {
                setIsSubmitting(false);
            }
        })();
    };

    return (
        <>
            <ProfileDetailsCard profile={profile} user={user}/>
            <ProfileAdminActions profile={profile}/>
            <ViewProfileBilling profile={profile}/>
            <Form onSubmit={handleSubmit(onSubmit)}>
                <ProfileFormDetails profile={profile} control={control} isSubmitting={isSubmitting}/>
                <ProfileFormPrivateContact profile={profile} control={control} isSubmitting={isSubmitting}/>
                <ProfileFormPublicContact profile={profile} control={control} isSubmitting={isSubmitting}/>
                <ProfileFormAboutMe profile={profile} control={control} isSubmitting={isSubmitting}/>
                <ProfileFormClaimToFame profile={profile} control={control} isSubmitting={isSubmitting}/>
                <ProfileFormResume profile={profile} control={control} isSubmitting={isSubmitting}/>
                <ProfileFormFees profile={profile} control={control} isSubmitting={isSubmitting}/>
                <ProfileFormSkills profile={profile} control={control} isSubmitting={isSubmitting}/>
                <ProfileFormTags profile={profile} control={control} isSubmitting={isSubmitting}/>
                <ProfileFormReferredBy profile={profile} control={control} isSubmitting={isSubmitting}/>
                <div className="text-right mt-5">
                    <Button
                        as={Link}
                        to="/profiles"
                        variant="default"
                        disabled={isSubmitting}
                        className="mr-3"
                    >
                        Cancel
                    </Button>
                    <Button type="submit" disabled={isSubmitting}>
                        Save Changes
                    </Button>
                </div>
            </Form>
        </>
    );
};
