import {selector} from 'recoil';

import {profileListInsertSelector, profileListRemoveSelector} from 'modules/profile/state/profile-list';
import {profileLookupInsertSelector, profileLookupRemoveSelector} from 'modules/profile/state/profile-lookup';
import {IProfile} from 'modules/profile/models/IProfile';
import {throwWriteOnlySelectorError, guardRecoilDefaultValue} from 'shared/recoil/utils';

export const profileInsertSelector = selector<IProfile>({
    key: 'profileInsertSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        set(profileLookupInsertSelector, newValue);
        set(profileListInsertSelector, newValue);
    },
});

export const profileRemoveSelector = selector<string>({
    key: 'profileRemoveSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        set(profileLookupRemoveSelector, newValue);
        set(profileListRemoveSelector, newValue);
    },
});
