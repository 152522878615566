import React from 'react';

import {Col, Form} from 'react-bootstrap';
import {Control, UseFormSetValue} from 'react-hook-form';

import {IFile} from 'modules/file/models/IFile';
import {IIssueForm} from 'modules/issue/forms';
import {issueStatusOptions} from 'modules/issue/constants';
import {useGuaranteedAuth} from 'shared/auth/hooks/useAuth';

import {DropdownSelectController} from 'shared/components/form/DropdownSelect/DropdownSelectController';
import {InputController} from 'shared/components/form/InputController/InputController';
import {FileControl} from 'shared/components/form/FileControl/FileControl';

interface ICreateIssueFormProps {
    control: Control<IIssueForm>;
    disabled: boolean;
    setValue: UseFormSetValue<IIssueForm>;
}

export const CreateIssueForm = ({control, disabled, setValue}: ICreateIssueFormProps) => {
    const {tokenData} = useGuaranteedAuth();

    const onFileChange = (files: IFile[]) => {
        setValue('image_id', files[0]?.id);
    };

    return (
        <>
            <Col xl={6}>
                <Form.Group controlId="UpdateIssueForm__status">
                    <Form.Label className="matterLable">Status</Form.Label>
                    <DropdownSelectController
                        control={control}
                        name="status"
                        options={issueStatusOptions}
                        className="form_cc_title_input  justify-content-between d-flex align-items-center"
                    />
                </Form.Group>
                <Form.Group controlId="firstname">
                    <Form.Label className="matterLable">Title</Form.Label>
                    <InputController
                        className="Input__box-shodow--none title_input"
                        name="title"
                        control={control}
                        disabled={disabled}
                    />
                </Form.Group>
                <Form.Group>
                    <Form.Label className="matterLable">
                        Upload a picture to illustrate what will be changed on the platform
                    </Form.Label>
                    <FileControl
                        userId={tokenData.id}
                        maxFiles={1}
                        onChange={onFileChange}
                        disabled={disabled}
                        folder="issue"
                    />
                </Form.Group>
            </Col>
            <Col xl={6}>
                <Form.Group controlId="description" className="h-100">
                    <Form.Label className="matterLable">Description</Form.Label>
                    <InputController
                        name="description"
                        className="Input__box-shodow--none description_height"
                        control={control}
                        disabled={disabled}
                        asType="textarea"
                    />
                </Form.Group>
            </Col>
        </>
    );
};
