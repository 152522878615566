import {userLookupAtom, userLookupReadSelector} from 'modules/user/state/user-lookup';
import {useRecoilLoadable} from 'shared/recoil/hooks/useRecoilLoadable';

export const useUserLoadable = (userId: string) => {
    const {data, loading, error} = useRecoilLoadable({
        state: userLookupAtom(userId),
        loadable: userLookupReadSelector(userId),
    });
    return {
        user: data,
        loading,
        error,
    };
};
