import React from 'react';

import {Button} from 'react-bootstrap';

import {useAuth} from 'shared/auth/hooks/useAuth';

export const LoginButton = () => {
    const {login} = useAuth();

    return (
        <Button variant="outline-success" onClick={() => login()}>
            Log In
        </Button>
    );
};
