import axios from 'axios';

import {getAccessTokenSilently} from 'shared/utils/token';
import {Config} from 'config';
import {getAuthHeaders} from 'shared/utils/auth';
import {SystemTaskCallable} from 'shared/models/systemTask';

export const flushMemcache: SystemTaskCallable = async () => {
    const accessToken = await getAccessTokenSilently();
    return await axios.post(`${Config.mainServiceURL}/system/flush_memcache`, null, {
        headers: getAuthHeaders(accessToken),
    });
};

export const initializeMainMessagingSubscriptions: SystemTaskCallable = async () => {
    const accessToken = await getAccessTokenSilently();
    return await axios.post(`${Config.mainServiceURL}/system/initialize_messaging_subscriptions`, null, {
        headers: getAuthHeaders(accessToken),
    });
};

export const initializeChatMessagingSubscriptions: SystemTaskCallable = async () => {
    const accessToken = await getAccessTokenSilently();
    return await axios.post(`${Config.chatServiceURL}/system/initialize_messaging_subscriptions`, null, {
        headers: getAuthHeaders(accessToken),
    });
};

export const initializeDocumentMessagingSubscriptions: SystemTaskCallable = async () => {
    const accessToken = await getAccessTokenSilently();
    return await axios.post(`${Config.documentServiceURL}/system/initialize_messaging_subscriptions`, null, {
        headers: getAuthHeaders(accessToken),
    });
};

export const initializeHelpMessagingSubscriptions: SystemTaskCallable = async () => {
    const accessToken = await getAccessTokenSilently();
    return await axios.post(`${Config.helpServiceURL}/system/initialize_messaging_subscriptions`, null, {
        headers: getAuthHeaders(accessToken),
    });
};

export const refreshHomepageCache: SystemTaskCallable = async () => {
    const accessToken = await getAccessTokenSilently();
    return await axios.post(`${Config.mainServiceURL}/system/refresh_homepage_cache`, null, {
        headers: getAuthHeaders(accessToken),
    });
};

export const touchPosts: SystemTaskCallable = async () => {
    const accessToken = await getAccessTokenSilently();
    return await axios.post(`${Config.mainServiceURL}/system/touch_posts`, null, {
        headers: getAuthHeaders(accessToken),
    });
};

export const touchProfiles: SystemTaskCallable = async () => {
    const accessToken = await getAccessTokenSilently();
    return await axios.post(`${Config.mainServiceURL}/system/touch_profiles`, null, {
        headers: getAuthHeaders(accessToken),
    });
};

export const rebuildPostSearch: SystemTaskCallable = async () => {
    const accessToken = await getAccessTokenSilently();
    return await axios.post(`${Config.mainServiceURL}/system/rebuild_post_search`, null, {
        headers: getAuthHeaders(accessToken),
    });
};

export const rebuildProfileSearch: SystemTaskCallable = async () => {
    const accessToken = await getAccessTokenSilently();
    return await axios.post(`${Config.mainServiceURL}/system/rebuild_profile_search`, null, {
        headers: getAuthHeaders(accessToken),
    });
};

export const touchArticles: SystemTaskCallable = async () => {
    const accessToken = await getAccessTokenSilently();
    return await axios.post(`${Config.helpServiceURL}/system/touch_articles`, null, {
        headers: getAuthHeaders(accessToken),
    });
};

export const touchCategories: SystemTaskCallable = async () => {
    const accessToken = await getAccessTokenSilently();
    return await axios.post(`${Config.helpServiceURL}/system/touch_categories`, null, {
        headers: getAuthHeaders(accessToken),
    });
};

export const touchFiles: SystemTaskCallable = async () => {
    const accessToken = await getAccessTokenSilently();
    return await axios.post(`${Config.fileServiceURL}/system/touch_files`, null, {
        headers: getAuthHeaders(accessToken),
    });
};

export const touchChats: SystemTaskCallable = async () => {
    const accessToken = await getAccessTokenSilently();
    return await axios.post(`${Config.chatServiceURL}/system/touch_chats`, null, {
        headers: getAuthHeaders(accessToken),
    });
};

export const downloadPasswordResetLinks: SystemTaskCallable = async () => {
    const accessToken = await getAccessTokenSilently();
    return await axios.post(`${Config.mainServiceURL}/system/download_password_reset_links`, null, {
        headers: getAuthHeaders(accessToken),
    });
};

export const ensureUUIDs: SystemTaskCallable = async () => {
    const accessToken = await getAccessTokenSilently();
    return await axios.post(`${Config.userServiceURL}/system/ensure_uuids`, null, {
        headers: getAuthHeaders(accessToken),
    });
};

export const resetUserPasswords: SystemTaskCallable = async () => {
    const accessToken = await getAccessTokenSilently();
    return await axios.post(`${Config.userServiceURL}/system/reset_user_passwords`, null, {
        headers: getAuthHeaders(accessToken),
    });
};

export const checkProfileFiles: SystemTaskCallable = async () => {
    const accessToken = await getAccessTokenSilently();
    return await axios.post(`${Config.mainServiceURL}/profiles/check_file_permissions`, null, {
        headers: getAuthHeaders(accessToken),
    });
};

export const touchEvents: SystemTaskCallable = async () => {
    const accessToken = await getAccessTokenSilently();
    return await axios.post(`${Config.mainServiceURL}/system/touch_events`, null, {
        headers: getAuthHeaders(accessToken),
    });
};
