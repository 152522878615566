import React from 'react';

import {Badge} from 'react-bootstrap';

import {IBadgeProps} from 'shared/models/badge';

interface IJobProfileVisibilityBadgeProps {
    isVisible: boolean;
}

export const JobProfileVisibilityBadge = ({isVisible}: IJobProfileVisibilityBadgeProps) => {
    const badgeProps = React.useMemo<IBadgeProps>(() => {
        if (isVisible) {
            return {
                variant: 'success',
                children: 'Visible',
            };
        } else {
            return {
                variant: 'secondary',
                children: 'Hidden',
            };
        }
    }, [isVisible]);

    return (
        <Badge pill className="badge--lg" {...badgeProps}/>
    );
};
