export enum TagColor {
    Blue = 'blue',
    Indigo = 'indigo',
    Purple = 'purple',
    Pink = 'pink',
    Red = 'red',
    Orange = 'orange',
    Yellow = 'yellow',
    Green = 'green',
    Teal = 'teal',
    Cyan = 'cyan',
    White = 'white',
    Gray = 'gray',
    DarkGrey = 'gray-dark',
}

export interface ITag {
    id: string;
    title: string;
    color: TagColor;
    created_at: string;
    isChecked?: boolean;
}

export interface IReadTagsRequest {
    cursor?: string;
    limit?: number;
}

export interface IReadTagsResponse {
    tags: ITag[];
    next_cursor?: string;
}

export interface ITagColor {
    name: string;
    color: TagColor;
}

export interface ITagUpdate {
    title?: string;
    color?: TagColor;
}

export interface ITagCreate {
    title: string;
    color: TagColor;
}

export const tagColors: ITagColor[] = [
    {
        name: 'Blue',
        color: TagColor.Blue,
    },
    {
        name: 'Indigo',
        color: TagColor.Indigo,
    },
    {
        name: 'Purple',
        color: TagColor.Purple,
    },
    {
        name: 'Pink',
        color: TagColor.Pink,
    },
    {
        name: 'Red',
        color: TagColor.Red,
    },
    {
        name: 'Orange',
        color: TagColor.Orange,
    },
    {
        name: 'Yellow',
        color: TagColor.Yellow,
    },
    {
        name: 'Green',
        color: TagColor.Green,
    },
    {
        name: 'Teal',
        color: TagColor.Teal,
    },
    {
        name: 'Cyan',
        color: TagColor.Cyan,
    },
    {
        name: 'White',
        color: TagColor.White,
    },
    {
        name: 'Grey',
        color: TagColor.Gray,
    },
    {
        name: 'Dark Grey',
        color: TagColor.DarkGrey,
    },
];
