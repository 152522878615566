import {atom, selectorFamily} from 'recoil';

import {IPostQuery} from 'modules/post/models/IPostListQuery';
import {readPostCount} from 'modules/post/api';
import {comparePostFilters} from '../utils';
import {guardRecoilDefaultValue} from 'shared/recoil/utils';

interface IPostCountState {
    filters: IPostQuery;
    postCount: number;
    resetVersion: number;

    [key: string]: number | IPostQuery;
}

export const postCountAtom = atom<IPostCountState | undefined>({
    key: 'postCountAtom',
    default: undefined,
});

export const postCountResetAtom = atom<number>({
    key: 'postCountResetAtom',
    default: 0,
});

export const postCountSelector = selectorFamily<IPostCountState | undefined, IPostQuery>({
    key: 'postCountSelector',
    get: (filters) => ({get}) => {
        const atomValue = get(postCountAtom);
        const resetVersion = get(postCountResetAtom);
        if (
            atomValue &&
            comparePostFilters(filters, atomValue.filters) &&
            atomValue.resetVersion === resetVersion
        ) {
            return atomValue;
        }
        return undefined;
    },
    set: () => ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue) || !newValue) {
            return null;
        }
        set(postCountAtom, newValue);
    },
});

export const postCountReadSelector = selectorFamily<IPostCountState, IPostQuery>({
    key: 'postCountReadSelector',
    get: (filters) => async ({get}) => {
        const currentValue = get(postCountSelector(filters));
        if (currentValue) {
            return currentValue;
        }
        const resetVersion = get(postCountResetAtom);
        const postCountResult = await readPostCount({
            ...filters,
        });
        return {
            filters: filters,
            postCount: postCountResult.postCount,
            resetVersion,
        } as IPostCountState;
    },
});
