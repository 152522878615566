import React from 'react';

import {Col, Row} from 'react-bootstrap';
import {Control} from 'react-hook-form';

import {IProfile} from 'modules/profile/models/IProfile';
import {IProfileUpdate} from 'modules/profile/models/IProfileUpdate';

import {FormCard} from 'components/profile/shared/FormCard/FormCard';
import {FormController} from 'shared/components/form/FormController/FormController';

interface IProfileFormPublicContactProps {
    profile: IProfile;
    control: Control<IProfileUpdate>;
    isSubmitting: boolean;
}

export const ProfileFormPublicContact = ({control, isSubmitting}: IProfileFormPublicContactProps) => {

    return (
        <FormCard title="Public Contact Details">
            <Row>
                <Col lg={3}>
                    <FormController
                        name="contact_details_display.email"
                        label="Email Address"
                        control={control}
                        disabled={isSubmitting}
                    />
                </Col>
                <Col lg={3}>
                    <FormController
                        name="contact_details_display.phone_number"
                        label="Phone Number"
                        control={control}
                        disabled={isSubmitting}
                    />
                </Col>
                <Col lg={6}>
                    <FormController
                        name="contact_details_display.address"
                        label="Address"
                        control={control}
                        disabled={isSubmitting}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={3}>
                    <FormController
                        name="contact_details_display.city"
                        label="City"
                        control={control}
                        disabled={isSubmitting}
                    />
                </Col>
                <Col lg={3}>
                    <FormController
                        name="contact_details_display.state"
                        label="State"
                        control={control}
                        disabled={isSubmitting}
                    />
                </Col>
                <Col lg={3}>
                    <FormController
                        name="contact_details_display.country"
                        label="Country"
                        control={control}
                        disabled={isSubmitting}
                    />
                </Col>
            </Row>
        </FormCard>
    );
};
