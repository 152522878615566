import {atom, selector, selectorFamily} from 'recoil';

import {IJob, IJobQuery} from 'modules/job/models';
import {readJobList} from 'modules/job/api';
import {CursorList} from 'shared/types/cursor-list';
import {guardRecoilDefaultValue, throwWriteOnlySelectorError} from 'shared/recoil/utils';

export interface IJobListStateFilters {
    filters: IJobQuery;
    page: number;
    limit: number;

    [key: string]: number | IJobQuery;
}

interface IJobListAtom {
    filters: IJobQuery;
    limit: number;
    jobs: IJob[];
    cursors: CursorList;
    page: number;
    more: boolean;
    _resetVersion: number;

    [key: string]: number | boolean | IJob[] | CursorList | IJobQuery;
}

const compareFilters = (filters1: IJobQuery, filters2: IJobQuery) => {
    return (
        filters1.user_id === filters2.user_id &&
        filters1.tag_id === filters2.tag_id &&
        filters1.status === filters2.status &&
        filters1.is_published === filters2.is_published &&
        filters1.limit === filters2.limit &&
        filters1.cursor === filters2.cursor &&
        filters1.search_term === filters2.search_term
    );
};

export const jobListAtom = atom<IJobListAtom | undefined>({
    key: 'jobListAtom',
    default: undefined,
});

export const jobListResetAtom = atom<number>({
    key: 'jobListResetAtom',
    default: 0,
});

export const jobListSelector = selectorFamily<IJobListAtom | undefined, IJobListStateFilters>({
    key: 'jobListSelector',
    get: ({page, limit, filters}) => ({get}) => {
        const atomValue = get(jobListAtom);
        const resetVersion = get(jobListResetAtom);
        if (
            atomValue &&
            atomValue.hasLoaded &&
            page === atomValue.page &&
            limit === atomValue.limit &&
            compareFilters(filters, atomValue.filters) &&
            atomValue._resetVersion === resetVersion
        ) {
            return {...atomValue};
        }
        return undefined;
    },
    set: () => ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue) || !newValue) {
            return null;
        }
        set(jobListAtom, {...newValue});
    },
});

export const jobListReadSelector = selectorFamily<IJobListAtom, IJobListStateFilters>({
    key: 'jobListReadSelector',
    get: (filters) => async ({get}) => {
        const currentValue = get(jobListSelector(filters));
        if (currentValue) {
            return currentValue;
        }

        const atomValue = get(jobListAtom);
        const resetVersion = get(jobListResetAtom);

        const result = await readJobList({
            ...filters.filters,
            limit: filters.limit,
            cursor: filters.page === 0 || !atomValue ? undefined : atomValue.cursors[filters.page - 1],
        });

        // add the cursor to the list of cursors so that we can paginate backwards
        const newCursors = atomValue ? Array.from(atomValue.cursors) : [];
        newCursors[filters.page] = result.next_cursor;

        return {
            filters: filters.filters,
            limit: filters.limit,
            jobs: result.jobs,
            cursors: newCursors,
            page: filters.page,
            more: !!result.next_cursor,
            hasLoaded: true,
            _resetVersion: resetVersion,
        } as IJobListAtom;
    },
});

export const jobListRemoveSelector = selector<string>({
    key: 'jobListRemoveSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        // TODO: update totals once we have "counts" in the database
        const atomValue = get(jobListAtom);
        if (atomValue) {
            set(jobListAtom, {
                ...atomValue,
                jobs: atomValue.jobs.filter(job => job.id !== newValue),
            });
        }
    },
});

export const jobListInsertSelector = selector<IJob>({
    key: 'jobListInsertSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }

        // if the job is present in the store then update it. Otherwise, force a reload.
        const atomValue = get(jobListAtom);
        const index = atomValue?.jobs?.findIndex(job => job.id === newValue.id);
        if (atomValue && typeof index !== 'undefined' && index !== -1) {
            const newJobs = Array.from(atomValue.jobs);
            newJobs.splice(index, 1, newValue);
            set(jobListAtom, {
                ...atomValue,
                jobs: newJobs,
            });
        } else if (atomValue && atomValue.jobs.length < atomValue.limit && atomValue.filters.user_id === newValue.user_id) {
            const newJobs = [...atomValue.jobs, newValue];
            set(jobListAtom, {
                ...atomValue,
                jobs: newJobs,
            });
        } else {
            set(jobListResetAtom, get(jobListResetAtom) + 1);
        }
    },
});
