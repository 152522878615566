import {useRecoilLoadable} from 'shared/recoil/hooks/useRecoilLoadable';
import {
    profileListReadSelector,
    IProfileListStateFilters,
    profileListSelector,
} from 'modules/profile/state/profile-list';

export const useProfileListLoadable = (filters: IProfileListStateFilters) => {
    const {data, loading, error} = useRecoilLoadable({
        state: profileListSelector(filters),
        loadable: profileListReadSelector(filters),
    });
    return {
        profileList: data,
        loading,
        error,
    };
};
