import React from 'react';

import {Button, Modal, Form} from 'react-bootstrap';
import {useSetRecoilState} from 'recoil';

import {postListRemoveSelector} from 'modules/post/state/post-list';
import {toastAxiosError} from 'shared/utils/error';
import {toast} from 'shared/utils/toast';
import {IPost} from 'modules/post/models/IPost';
import {deletePost} from 'modules/post/api';

interface IDeleteResourceModalProps {
    show: boolean;
    onHide: () => void;
    post: IPost;
}

export const DeleteResourceModal = ({show, onHide, post}: IDeleteResourceModalProps) => {
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
    const removePost = useSetRecoilState(postListRemoveSelector);

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        setIsSubmitting(true);

        (async () => {
            try {
                await deletePost(post.id);
                toast.success('Post Deleted', 'The post was successfully deleted.');
                removePost(post);
                onHide();
            } catch (e) {
                toastAxiosError(e, 'Error Deleting Post');
            } finally {
                setIsSubmitting(false);
            }
        })();
    };

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    Delete Post
                </Modal.Title>
            </Modal.Header>
            <Form onSubmit={onSubmit}>
                <Modal.Body>
                    <div className="AdmineDeletModel">
                        <p>Are you sure?</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onHide} disabled={isSubmitting} variant="outline-secondary">
                        Cancel
                    </Button>
                    <Button type="submit" disabled={isSubmitting} variant="danger">
                        Okay
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};
