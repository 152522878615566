import React from 'react';

import {ControllerRenderProps} from 'react-hook-form';

import {TagColor, tagColors} from 'modules/tag/models';

import {ColorSelectorItem} from 'shared/components/ColorSelector/ColorSelectorItem';

import './style.scss';

interface IColorSelectorControlProps extends ControllerRenderProps {
    disabled?: boolean;
    defaultValue?: TagColor;
    isInvalid?: boolean;
}

export const ColorSelectorControl = ({
    name,
    value,
    disabled,
    onChange,
}: IColorSelectorControlProps) => {
    return (
        <div className="ColorSelector__control">
            {tagColors.map((color, index) => (
                <ColorSelectorItem
                    key={color.color}
                    name={name}
                    onChange={onChange}
                    value={color.color}
                    isChecked={color.color === value}
                    disabled={disabled}
                />
            ))}
        </div>
    );
};
