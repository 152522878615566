import {useRecoilLoadable} from 'shared/recoil/hooks/useRecoilLoadable';
import {issueLookupSelector, issueLookupReadSelector} from 'modules/issue/state/issue-lookup';

export const useIssueLoadable = (issueId: string) => {
    const {data, loading, error} = useRecoilLoadable({
        state: issueLookupSelector(issueId),
        loadable: issueLookupReadSelector(issueId),
    });
    return {
        issue: data,
        loading,
        error,
    };
};
