import React from 'react';

import { Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

import { ITag } from 'modules/tag/models';

import './style.scss';

interface ITagProps {
    className?: string;
    size: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    title: string;
    color: string;
    isOptionClose?: boolean;
    data?: ITag;
    onRemove?: (tagId: string) => void;
    badge?: true;
}

export const Tag = ({color, title, className, size, isOptionClose, onRemove, data, badge}: ITagProps) => {
    const classNameComputed = classNames(className, {
        'Tag': !badge,
        [`Tag--${size}`]: !badge && size,
        [`badge--${size}`]: badge && size,
    });

    const onRemoveClick = (e: React.MouseEvent<SVGSVGElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (onRemove && data) {
            onRemove(data.id);
        }
    };

    return (
        <Badge className={classNameComputed} variant={color} pill>
            <span>{title}</span>
            {isOptionClose && (
                <FontAwesomeIcon
                    onClick={onRemoveClick}
                    className="Tag__close-icon"
                    icon={faTimes}
                />
            )}
        </Badge>
    );
};

Tag.defaultProps = {
    size: 'sm',
};
