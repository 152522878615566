import {IFileManagerStateFilters} from 'modules/file/state/file-manager';
import {useRecoilLoadable} from 'shared/recoil/hooks/useRecoilLoadable';
import {fileListSelector, fileListReadSelector} from 'modules/file/state/file-list';

export const useFileListLoadable = (filters: IFileManagerStateFilters) => {
    const {data, loading, error} = useRecoilLoadable({
        state: fileListSelector(filters),
        loadable: fileListReadSelector(filters),
    });
    return {
        fileList: data,
        loading,
        error,
    };
};
