import React from 'react';

import {PostKind} from 'modules/post/models/postKind';
import {IPostListQuery} from 'modules/post/models/IPostListQuery';
import {useDebounce} from 'shared/hooks/useDebounce';

import {TableSearch} from 'shared/components/TableSearch/TableSearch';
import {ResourceKindControl} from 'components/resources/ResourceList/ResourceKindControl/ResourceKindControl';

interface IResourceListHeaderProps {
    value: IPostListQuery;
    onChange: (value: IPostListQuery) => void;
    disabled?: boolean;
}

export const ResourceListHeader = ({value, onChange, disabled}: IResourceListHeaderProps) => {
    const [searchTerm, setSearchTerm] = React.useState<string | undefined>(value.searchTerm);
    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    const onKindChange = (kind: PostKind | undefined) => {
        onChange({
            ...value,
            kind,
        });
    };

    React.useEffect(() => {
        if (debouncedSearchTerm !== value.searchTerm) {
            onChange({
                ...value,
                searchTerm: debouncedSearchTerm,
            });
        }
    }, [value, debouncedSearchTerm, onChange]);

    return (
        <div className="d-flex">
            <TableSearch
                placeholder="Search Posts"
                value={searchTerm}
                onChange={setSearchTerm}
                disabled={disabled}
            />
            <ResourceKindControl
                value={value.kind}
                onChange={onKindChange}
                className="ml-3"
                disabled={disabled}
            />
        </div>
    );
};
