import React from 'react';

import {Form} from 'react-bootstrap';
import {Controller, FieldValues, UseControllerProps, Control, Path} from 'react-hook-form';

import {ArticleEditor} from 'shared/components/form/ArticleEditor/ArticleEditor';

interface IArticleEditorControllerProps<FormType> extends UseControllerProps<FormType> {
    control: Control<FormType>;
    name: Path<FormType>;
    disabled?: boolean;
    placeholder?: string;
    id?: string;
    isErrorNotShow?: boolean;
}

export const ArticleEditorController = <FormType extends FieldValues>({
    disabled,
    name,
    control,
    placeholder,
    id,
    isErrorNotShow,
}: React.PropsWithChildren<IArticleEditorControllerProps<FormType>>) => {

    return (
        <Controller
            name={name}
            control={control}
            render={({field: {onChange, value, name: controlName}, fieldState: {invalid, error}}) => (
                <>
                    <ArticleEditor
                        id={id}
                        name={controlName}
                        onChange={onChange}
                        isInvalid={!!error}
                        disabled={disabled}
                        value={value}
                        placeholder={placeholder}
                    />
                    {!isErrorNotShow && invalid && error && (
                        <Form.Control.Feedback type="invalid" className="d-block">
                            {error.message}
                        </Form.Control.Feedback>
                    )}
                </>
            )}
        />
    );
};

ArticleEditorController.defaultValue = {
    type: 'text',
};
