import React from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationCircle} from '@fortawesome/free-solid-svg-icons';

interface ILoadingErrorProps {
    children?: React.ReactNode;
}

export const LoadingError = ({children}: ILoadingErrorProps) => (
    <div className="text-center text-danger">
        <FontAwesomeIcon icon={faExclamationCircle} size="3x"/>
        <div>
            {children}
        </div>
    </div>
);
