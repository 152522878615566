import {selector} from 'recoil';

import {ITag} from 'modules/tag/models';
import {tagListAtom} from 'modules/tag/state/tag-list';
import {guardRecoilDefaultValue, throwWriteOnlySelectorError} from 'shared/recoil/utils';

export const tagInsertSelector = selector<ITag>({
    key: 'tagInsertSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }

        const tagList = get(tagListAtom);
        if (tagList) {
            const tagIndex = tagList.findIndex(tag => tag.id === newValue.id);
            const newTags = Array.from(tagList);
            if (tagIndex !== -1) {
                newTags.splice(tagIndex, 1, newValue);
            } else {
                newTags.push(newValue);
            }
            set(tagListAtom, newTags);
        }
    },
});
