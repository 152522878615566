import React from 'react';

import {Link} from 'react-router-dom';

import {useTitle} from 'shared/hooks/useTitle';

import './style.scss';

export const NotFound = () => {
    useTitle('Not Found');

    return (
        <div className="NotFound">
            <h1 className="NotFound__title">Page not found</h1>
            <p className="NotFound__description">We could not find the page you're looking for</p>
            <div className="NotFound__button-wrapper">
                <Link to="/" className="btn btn-featured NotFound__button btn-lg">
                    Return Home
                </Link>
            </div>
            <div className="NotFound__button-wrapper">
                <img src="/assets/notfound.png" alt="not found" className="NotFound__image"/>
            </div>
        </div>
    );
};
