import {selectorFamily, atomFamily} from 'recoil';

import {IFile} from 'modules/file/models/IFile';
import {readFile} from 'modules/file/api';
import {guardRecoilDefaultValue} from 'shared/recoil/utils';
import {fileListFileSelector} from 'modules/file/state/file-list';

export const fileLookupAtom = atomFamily<IFile | undefined, string>({
    key: 'fileLookupAtom',
    default: undefined,
});

export const fileLookupSelector = selectorFamily<IFile | undefined, string>({
    key: 'fileLookupSelector',
    get: (fileId) => ({get}) => {
        const valueInLookup = get(fileLookupAtom(fileId));
        if (valueInLookup) {
            return valueInLookup;
        }

        const valueInList = get(fileListFileSelector(fileId));
        if (valueInList) {
            return valueInList;
        }

        return undefined;
    },
    set: () => async ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue) || !newValue) {
            return;
        }
        set(fileLookupAtom(newValue.id), newValue);
    },
});

export const fileLookupReadSelector = selectorFamily<IFile, string>({
    key: 'fileLookupReadSelector',
    get: (fileId) => async ({get}) => {
        const currentValue = get(fileLookupSelector(fileId));
        if (currentValue) {
            return currentValue;
        }
        return await readFile(fileId);
    },
});
