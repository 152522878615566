import React from 'react';

import {Control, Controller, Path} from 'react-hook-form';

import {IProfileUpdate} from 'modules/profile/models/IProfileUpdate';
import {ServiceType} from 'modules/profile/models/serviceType';

import {ServicesControl} from 'components/profile/shared/ServicesController/ServicesControl';

interface IServicesControllerProps {
    control: Control<IProfileUpdate>;
    name: Path<IProfileUpdate>;
    disabled?: boolean;
}

export const ServicesController = ({control, name, disabled}: IServicesControllerProps) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({field: {ref, value, ...field}, fieldState}) => (
                <ServicesControl
                    disabled={disabled}
                    invalid={fieldState.invalid}
                    error={fieldState.error}
                    value={value ? value as ServiceType[] : []}
                    {...field}
                />
            )}
        />
    );
};
