import React from 'react';

import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button, Card, Container} from 'react-bootstrap';
import {Link, Params, useParams} from 'react-router-dom';

import {useTitle} from 'shared/hooks/useTitle';
import {toTitleCase} from 'shared/utils/string';
import {useIssueLoadable} from 'modules/issue/hooks/useIssueLoadable';

import {IssueStatusBadge} from 'components/issues/shared/IssueStatusBadge/IssueStatusBadge';
import {HtmlComponent} from 'shared/components/HtmlContent/HtmlContent';
import {LoadingSpinner} from 'shared/components/loading/LoadingSpinner/LoadingSpinner';
import {PageError} from 'shared/components/PageError/PageError';

import './style.scss';

export const ViewIssue = () => {
    const params = useParams() as Params<'issueId'>;
    const issueId = params.issueId as string;

    const {issue, loading, error} = useIssueLoadable(issueId);

    useTitle('View Issue');

    return (
        <Container>
            <Button as={Link} to="/issues" variant="featured" className="mb-4">
                <FontAwesomeIcon icon={faArrowLeft}/> Back to issues
            </Button>
            {loading && (
                <LoadingSpinner/>
            )}
            {error && (
                <PageError description="Error reading issue"/>
            )}
            {issue && (
                <>
                    <h3 className="IssueView__type mb-4">
                        <IssueStatusBadge status={toTitleCase(issue.status)}/>
                    </h3>
                    <Card className="p-5">
                        <div className="d-sm-flex justify-content-between">
                            <div className="align-content-center flex-wrap text-center">
                                <h1 className="IssueView__title">{issue.title}</h1>
                            </div>
                        </div>
                    </Card>
                    <HtmlComponent className="IssueView__content py-5">
                        {issue.description}
                    </HtmlComponent>
                </>
            )}
        </Container>
    );
};
