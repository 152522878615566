import {selectorFamily, atomFamily} from 'recoil';

import {readUser} from 'modules/user/api';
import {IUser} from 'modules/user/models';

export const userLookupAtom = atomFamily<IUser | undefined, string>({
    key: 'userLookupAtom',
    default: undefined,
});

export const userLookupReadSelector = selectorFamily<IUser, string>({
    key: 'userLookupReadSelector',
    get: (userId: string) => async ({get}) => {
        const atomValue = get(userLookupAtom(userId));
        if (atomValue) {
            return atomValue;
        }
        return await readUser(userId);
    },
});
