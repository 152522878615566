import React from 'react';

import {IJobWorkType} from 'modules/job/models';
import {jobWorkTypeToFriendlyName} from 'modules/job/utils';

interface IJobWorkTypeBadgeProps {
    workType: IJobWorkType;
}

export const JobWorkTypeBadge = ({workType}: IJobWorkTypeBadgeProps) => {
    const friendlyWorkType = React.useMemo<string | null>(() => jobWorkTypeToFriendlyName(workType), [workType]);

    return (
        <>{friendlyWorkType}</>
    );
};
