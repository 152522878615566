import React from 'react';

import { useRecoilState } from 'recoil';

import { fileTokenReadSelector, fileTokenResetAtom } from '../state/file-token';

export const FileTokenRefresherContent = () => {
    const [resetVersion, setResetVersion] = useRecoilState(fileTokenResetAtom);
    const [token, setToken] = useRecoilState(fileTokenReadSelector);

    React.useEffect(() => {
        setToken(token);
    }, [token, setToken]);

    React.useEffect(() => {
        if (token) {
            const timeout = setTimeout(() => {
                setResetVersion(resetVersion + 1);
            }, token.expires_in * 1000 - 10000);
            return () => clearTimeout(timeout);
        }
    }, [token, resetVersion, setToken, setResetVersion]);

    return null;
};
