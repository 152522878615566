import React from 'react';

import {ModalFooter, Button} from 'react-bootstrap';

import {Spinner} from 'shared/components/loading/Spinner/Spinner';

interface IModalFormFooterProps {
    onHide: () => void;
    isSubmitting: boolean;
}

export const ModalFormFooter = ({isSubmitting, onHide}: IModalFormFooterProps) => {
    const handleHide = () => {
        if (!isSubmitting) {
            onHide();
        }
    };

    return (
        <ModalFooter>
            <Button variant="secondary" onClick={handleHide} disabled={isSubmitting}>
                Close
            </Button>
            <Button variant="primary" type="submit" disabled={isSubmitting}>
                {isSubmitting && (
                    <Spinner className="mr-1" size="sm"/>
                )}
                Save changes
            </Button>
        </ModalFooter>
    );
};
