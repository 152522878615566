import {IProfile} from '../models/IProfile';
import {IProfileListQuery} from '../models/IProfileListQuery';
import {IProfileSummary} from 'modules/profile/models/IProfileSummary';

interface IPartialProfile {
    firstname: string;
    lastname: string;
}

export const getFullName = ({firstname, lastname}: IPartialProfile) => `${firstname} ${lastname}`;

export const compareProfileFilters = (filters1: IProfileListQuery, filters2: IProfileListQuery) => {
    return (
        filters1.profession === filters2.profession &&
        filters1.limit === filters2.limit &&
        filters1.cursor === filters2.cursor &&
        filters1.isPublished === filters2.isPublished &&
        filters1.isFeatured === filters2.isFeatured &&
        filters1.searchTerm === filters2.searchTerm
    );
};

export const profileToProfileSummary = (profile: IProfile): IProfileSummary => {
    return {
        id: profile.id,
        firstname: profile.firstname,
        lastname: profile.lastname,
        image_id: profile.image_id,
        image_url: profile.image_url,
        contact_details_display: profile.contact_details_display,
    };
};
