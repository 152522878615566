import React from 'react';

import {Button, Form, Modal} from 'react-bootstrap';
import {useSetRecoilState} from 'recoil';

import {ICategory} from 'modules/help/models';
import {deleteCategory} from 'modules/help/api';
import {categoryListRemoveSelector} from 'modules/help/state/category-list';
import {toastAxiosError} from 'shared/utils/error';
import {toast} from 'shared/utils/toast';

interface IDeleteCategoryModalProps {
    show: boolean;
    onHide: () => void;
    category?: ICategory;
}

export const DeleteCategoryModal = ({show, onHide, category}: IDeleteCategoryModalProps) => {
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
    const removeCategory = useSetRecoilState(categoryListRemoveSelector);

    const onSubmit = ((e: React.FormEvent) => {
        e.preventDefault();

        if (!category) {
            return;
        }

        setIsSubmitting(true);
        (async () => {
            try {
                await deleteCategory(category.id);
                removeCategory(category.id);
                onHide();
                toast.success('Delete Category', 'Category deleted successfully.');
            } catch (e) {
                toastAxiosError(e, 'Error deleting category');
            } finally {
                setIsSubmitting(false);
            }
        })();
    });

    return (
        <Modal
            show={show && !!category}
            size="sm"
            title="Delete Category"
            isSubmitting={isSubmitting}
            onHide={onHide}
        >
            <Modal.Header closeButton={!isSubmitting}>
                <Modal.Title>
                    Delete Category
                </Modal.Title>
            </Modal.Header>
            <Form onSubmit={onSubmit}>
                <Modal.Body>
                    <p>Are you sure you wish to delete this category?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" variant="danger" disabled={isSubmitting}>
                        Delete Category
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};
