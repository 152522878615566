import React from 'react';

import {Modal, Button} from 'react-bootstrap';
import {AxiosResponse, AxiosError} from 'axios';

import {IServiceTask} from 'shared/models/systemTask';
import {useBool} from 'shared/hooks/useBool';
import {toast} from 'shared/utils/toast';
import {toastAxiosError} from 'shared/utils/error';

import {LoadingSpinner} from 'shared/components/loading/LoadingSpinner/LoadingSpinner';
import {ServiceBadge} from 'components/System/ServiceBadge';

interface ISystemTaskModalProps {
    show: boolean;
    onHide: () => void;
    systemTask?: IServiceTask;
}

export const SystemTaskModal = ({systemTask, show, onHide}: ISystemTaskModalProps) => {
    const [isSubmitting, setIsSubmitting] = useBool();
    const [response, setResponse] = React.useState<AxiosResponse | undefined>(undefined);

    React.useEffect(() => {
        if (systemTask && show && !isSubmitting && !response) {
            setIsSubmitting(true);
            (async () => {
                try {
                    const _response = await systemTask.action();
                    setResponse(_response);
                    toast.success(systemTask.name, 'Task completed successfully');
                } catch (e) {
                    setResponse((e as AxiosError).response);
                    toastAxiosError(e, systemTask.name);
                } finally {
                    setIsSubmitting(false);
                }
            })();
        }
    }, [show, systemTask, isSubmitting, response, setIsSubmitting]);

    React.useEffect(() => {
        if (!show) {
            setIsSubmitting(false);
            setResponse(undefined);
        }
    }, [show, setResponse, setIsSubmitting]);

    return (
        <Modal show={show} onHide={onHide} size="lg">
            <Modal.Header closeButton={!isSubmitting}>
                <div>
                    <Modal.Title>{systemTask?.name ?? ''}</Modal.Title>
                    {systemTask && (
                        <ServiceBadge service={systemTask.service}/>
                    )}
                </div>
            </Modal.Header>
            <Modal.Body>
                {isSubmitting && (
                    <LoadingSpinner/>
                )}
                {response && (
                    <>
                        <p>
                            <strong>Request Details:</strong>
                        </p>
                        <table className="w-100 mb-3">
                            <tbody>
                            <tr>
                                <td>
                                    <strong>Status</strong>
                                </td>
                                <td>
                                    {response.status}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <strong>URL</strong>
                                </td>
                                <td>
                                    {response.request.responseURL}
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <p>
                            <strong>Response:</strong>
                        </p>
                        <pre className="w-100 border p-2">
                            {JSON.stringify(response.data, null, 2)}
                        </pre>
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button type="button" variant="outline-secondary" onClick={onHide} disabled={isSubmitting}>
                    Done
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
