import React from 'react';

import {IPendingFile} from 'shared/models/pending-file/IPendingFile';

import {FileDropzone} from 'shared/components/form/FileDropzone/FileDropzone';
import {
    ImageUploadedContainer,
} from 'shared/components/form/FileDropzoneController/ImageUploadedContainer/ImageUploadedContainer';

interface IFileDropzoneControllerControllerProps {
    userId: string;
    disabled?: boolean;
    onChange: (fileId?: string) => void;
    value?: string;
    folder: string;
}

export const FileDropzoneControllerContent = ({
    userId,
    disabled,
    value,
    onChange,
    folder,
}: IFileDropzoneControllerControllerProps) => {
    const [completedFiles, setCompletedFiles] = React.useState<IPendingFile[]>([]);

    const onDeleteFile = (file?: IPendingFile) => {
        onChange('');
        setCompletedFiles([]);
    };

    return (
        <>
            <FileDropzone
                userId={userId}
                onFileAdded={(file) => onChange(file.id)}
                onSuccessFilesChanges={(PendingFiles) => setCompletedFiles(PendingFiles)}
                onPendingFilesChange={(newPendingFiles) => null}
                disabled={disabled}
                multiple={false}
                className="FileDropzoneController"
                folder={folder}
            >
                <div className="FileDropzoneController__content">
                    <img className="FileDropzoneController__image" src="/assets/Uplodad.svg" alt="Upload a file"/>
                    <p className="mb-0">Upload or drop a file here</p>
                </div>
            </FileDropzone>
            {completedFiles && completedFiles.map((file) => (
                <ImageUploadedContainer
                    onDelete={() => onDeleteFile(file)}
                    name={file.filename}
                    percentage={file.isComplete}
                />
            ))}
        </>
    );
};
