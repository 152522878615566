import {atomFamily, selectorFamily} from 'recoil';

import {readSubscription} from '../api';
import {IBillingSubscription} from '../models';

export const subscriptionAtom = atomFamily<IBillingSubscription | undefined, string>({
    key: 'subscriptionAtom',
    default: undefined,
});

export const subscriptionReadSelector = selectorFamily<IBillingSubscription, string>({
    key: 'subscriptionReadSelector',
    get: (userId) => async ({get}) => {
        const atomValue = get(subscriptionAtom(userId));
        if (atomValue) {
            return atomValue;
        }
        return await readSubscription(userId);
    },
});
