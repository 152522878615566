import {atom, selector, selectorFamily} from 'recoil';

import {IProfile} from 'modules/profile/models/IProfile';
import {IProfileListQuery} from 'modules/profile/models/IProfileListQuery';
import {CursorList} from 'shared/types/cursor-list';
import {readProfileList} from 'modules/profile/api/profile';
import {compareProfileFilters} from '../utils';
import {throwWriteOnlySelectorError, guardRecoilDefaultValue} from 'shared/recoil/utils';

export interface IProfileListStateFilters {
    filters: IProfileListQuery;
    page: number;

    [key: string]: number | IProfileListQuery;
}

interface IProfileListState {
    filters: IProfileListQuery;
    profiles: IProfile[];
    cursors: CursorList;
    page: number;
    more: boolean;
    resetVersion: number;

    [key: string]: number | boolean | IProfile[] | CursorList | IProfileListQuery;
}

export const profileListAtom = atom<IProfileListState | undefined>({
    key: 'profileListAtom',
    default: undefined,
});

export const profileListResetAtom = atom<number>({
    key: 'profileListResetAtom',
    default: 0,
});

export const profileListSelector = selectorFamily<IProfileListState | undefined, IProfileListStateFilters>({
    key: 'profileListSelector',
    get: ({page, filters}) => ({get}) => {
        const atomValue = get(profileListAtom);
        const resetVersion = get(profileListResetAtom);
        if (
            atomValue &&
            page === atomValue.page &&
            compareProfileFilters(filters, atomValue.filters) &&
            atomValue.resetVersion === resetVersion
        ) {
            return atomValue;
        }
        return undefined;
    },
    set: (_) => ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue) && newValue) {
            return null;
        }
        set(profileListAtom, newValue);
    },
});

export const profileListReadSelector = selectorFamily<IProfileListState, IProfileListStateFilters>({
    key: 'profileListReadSelector',
    get: (filters) => async ({get}) => {
        const currentValue = get(profileListSelector(filters));
        if (currentValue) {
            return currentValue;
        }

        const atomValue = get(profileListAtom);
        const resetVersion = get(profileListResetAtom);

        const cursors = atomValue && filters.page > 0 ? Array.from(atomValue.cursors) : [];

        // load a fresh page from the server
        const profileList = await readProfileList({
            ...filters.filters,
            cursor: filters.page === 0 ? undefined : cursors[filters.page - 1],
        });

        // add the cursor to the list of cursors, so we can paginate backwards
        cursors[filters.page] = profileList.nextCursor;

        return {
            filters: filters.filters,
            page: filters.page,
            profiles: profileList.profiles,
            cursors,
            more: !!profileList.nextCursor,
            hasLoaded: true,
            resetVersion,
        } as IProfileListState;
    },
});

export const profileListLookupSelector = selectorFamily<IProfile | undefined, string>({
    key: 'profileListLookupSelector',
    get: (profileId) => ({get}) => {
        const atomValue = get(profileListAtom);
        return atomValue?.profiles?.find(profile => profile.id === profileId);
    },
});

export const profileListInsertSelector = selector<IProfile>({
    key: 'profileListInsertSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }

        const atomValue = get(profileListAtom);
        if (!atomValue) {
            return;
        }

        // if the profile is present in the store then update it. Otherwise, force a reload.
        const index = atomValue.profiles.findIndex(profile => profile.id === newValue.id);
        if (index !== -1) {
            const newProfiles = Array.from(atomValue.profiles);
            newProfiles.splice(index, 1, newValue);
            set(profileListAtom, {
                ...atomValue,
                profiles: newProfiles,
            });
        } else {
            set(profileListResetAtom, get(profileListResetAtom) + 1);
        }
    },
});

export const profileListRemoveSelector = selector<string>({
    key: 'profileListRemoveSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }

        const atomValue = get(profileListAtom);
        if (!atomValue) {
            return;
        }

        // if the profile is in the store then remove it. If this leads to an empty list then reset it. Otherwise,
        // do nothing if the profile is not in state.
        const index = atomValue.profiles.findIndex(profile => profile.id === newValue);
        if (index !== -1) {
            const newProfiles = atomValue.profiles.filter(profile => profile.id !== newValue);
            if (newProfiles.length > 0) {
                set(profileListAtom, {
                    ...atomValue,
                    profiles: newProfiles,
                });
            } else {
                set(profileListResetAtom, get(profileListResetAtom) + 1);
            }
        }
    },
});
