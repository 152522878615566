import React from 'react';

import {useAuth0} from '@auth0/auth0-react';

import {ITokenData, ILogoutOptions, ILoginOptions, Scope} from 'shared/auth/models';
import {usePermissionsLoadable} from 'shared/auth/hooks/usePermissionsLoadable';
import {getUUIDFromUser} from 'shared/auth/utils/get-uuid-from-token';

interface IUseAuthProviderValue {
    tokenData: ITokenData | undefined;
    isLoading: boolean;
    isAuthenticated: boolean;
    getAccessTokenSilently: () => Promise<string>;
    logout: (logoutOptions?: ILogoutOptions) => void;
    login: (loginOptions?: ILoginOptions) => Promise<void>;
}

interface IUseGuaranteedAuthValue {
    tokenData: ITokenData;
    getAccessTokenSilently: () => Promise<string>;
    logout: (logoutOptions?: ILogoutOptions) => void;
    login: (loginOptions?: ILoginOptions) => Promise<void>;
}

const stub = (): never => {
    throw new Error('You forgot to wrap your component in <AuthProvider>.');
};

const initialAuthContext = {
    isLoading: true,
    isAuthenticated: false,
    tokenData: undefined,
    getAccessTokenSilently: stub,
    logout: stub,
    login: stub,
};

export const authContext = React.createContext<IUseAuthProviderValue>(initialAuthContext);

export const useAuth = () => {
    return React.useContext(authContext);
};

export const useGuaranteedAuth = (): IUseGuaranteedAuthValue => {
    const {tokenData, ...rest} = React.useContext(authContext);
    if (!tokenData) {
        throw new Error('not authenticated');
    }
    return {
        tokenData: tokenData,
        ...rest,
    };
};

// Provider hook that creates auth object and handles state
export function useAuthProvider(): IUseAuthProviderValue {
    const {user, isAuthenticated, isLoading, logout, getAccessTokenSilently, loginWithRedirect} = useAuth0();
    const {scopes} = usePermissionsLoadable(isAuthenticated);

    const handleLogout = (logoutOptions?: ILogoutOptions) => {
        return logout(logoutOptions);
    };

    const login = async (loginOptions?: ILoginOptions) => {
        return loginWithRedirect(loginOptions);
    };

    const tokenData = React.useMemo<ITokenData | undefined>(() => {
        if (!user || !scopes) {
            return undefined;
        }
        return {
            id: getUUIDFromUser(user),
            scopes,
            isSystem: scopes.includes(Scope.System),
            picture: user.picture,
            nickname: user.nickname,
            email: user.email,
            name: user.name,
        };
    }, [user, scopes]);

    // Return the user object and auth methods
    return {
        tokenData,
        isLoading,
        isAuthenticated,
        getAccessTokenSilently,
        logout: handleLogout,
        login,
    };
}
