import {useParams, Params} from 'react-router-dom';

import {useProfileLoadable} from 'modules/profile/hooks/useProfileLoadable';

export const useProfileFromPathLoadable = () => {
    const params = useParams() as Params<'profileId'>;
    const profileId = params.profileId as string;

    return {
        profileId,
        ...useProfileLoadable(profileId),
    };
};
