import React from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft, faChevronRight} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import {Link} from 'react-router-dom';

import './style.scss';

interface IPaginationControlsProps {
    page: number;
    onChange: (page: number) => void;
    isMore: boolean;
    className?: string;
    disabled?: boolean;
}

export const PaginationControls = ({
    page,
    onChange,
    isMore,
    className,
    disabled,
}: IPaginationControlsProps) => {

    const paginationText = `Page ${page + 1}`;

    const onPrevClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        if (!disabled && page !== 0) {
            onChange(page - 1);
        }
    };

    const onNextClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        if (!disabled && isMore) {
            onChange(page + 1);
        }
    };

    const prevLinkClassName = classNames('PaginationControls__link', {
        'PaginationControls__link--disabled': disabled || page === 0,
    });

    const nextLinkClassName = classNames('PaginationControls__link', {
        'PaginationControls__link--disabled': disabled || !isMore,
    });

    return (
        <div className={classNames('PaginationControls', className)}>
            <div className="PaginationControls__controls">
                <Link
                    to="#"
                    onClick={onPrevClick}
                    className={prevLinkClassName}
                >
                    <FontAwesomeIcon icon={faChevronLeft}/>
                </Link>
                <div className="PaginationControls__page-number">
                    {!disabled && (
                        paginationText
                    )}
                </div>
                <Link
                    to="#"
                    onClick={onNextClick}
                    className={nextLinkClassName}
                >
                    <FontAwesomeIcon icon={faChevronRight}/>
                </Link>
            </div>
        </div>
    );
};
