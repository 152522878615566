import React from 'react';

import {Link} from 'react-router-dom';

import {IPost} from 'modules/post/models/IPost';

import {TableDropdown} from 'shared/components/TableDropdown/TableDropdown';
import {TableDropdownItem} from 'shared/components/TableDropdown/TableDropdownItem';
import {IconBin, IconEdit} from 'shared/icons/common';
import {PostKindBadge} from 'components/resources/shared/PostKindBadge';
import {UserIdName} from 'shared/components/user/UserIdName/UserIdName';
import {FormatDate} from 'shared/components/date/FormatDate/FormatDate';
import {TagListSlider} from 'shared/components/tag/TagListSlider/TagListSlider';
import {PostStatusBadge} from 'components/resources/shared/PostStatusBadge';
import {UserIdProfilePicture} from 'shared/components/user/UserIdProfilePicture/UserIdProfilePicture';

interface IResourceListRowProps {
    post: IPost;
    onDeletePost: () => void;
}

export const ResourceListRow = ({post, onDeletePost}: IResourceListRowProps) => {
    const updatePostURL: string = `/resources/${post.id}/update`;

    return (
        <tr>
            <td className="table__picture-col">
                <UserIdProfilePicture id={post.user_id} round size="xs" table />
            </td>
            <td>
                <Link to={updatePostURL} className="table__link">
                    {post.title}
                </Link>
            </td>
            <td className="table__badge-col">
                <PostStatusBadge post={post} />
            </td>
            <td className="table__badge-col">
                <PostKindBadge kind={post.kind} />
            </td>
            <td>
                <UserIdName id={post.user_id} />
            </td>
            <td className="table__date-col">
                <FormatDate>{post.created_at}</FormatDate>
            </td>
            <td className="table__tags-col">
                <TagListSlider tagIds={post.tag_ids} />
            </td>
            <td className="table__options-col">
                <TableDropdown>
                    <TableDropdownItem
                        icon={IconEdit}
                        variant="warning"
                        to={updatePostURL}
                    >
                        Update Post
                    </TableDropdownItem>
                    <TableDropdownItem
                        icon={IconBin}
                        variant="danger"
                        onClick={onDeletePost}
                    >
                        Delete Post
                    </TableDropdownItem>
                </TableDropdown>
            </td>
        </tr>
    );
};
