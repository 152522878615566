import {IJobLocation, IJobWorkType} from 'modules/job/models';
import {australiaStates} from 'shared/location/locations';

export const jobWorkTypeToFriendlyName = (workType: IJobWorkType): string | null => {
    if (workType.is_virtual && workType.is_in_person) {
        return 'Virtual or in person';
    } else if (workType.is_in_person) {
        return 'In person';
    } else if (workType.is_virtual) {
        return 'Virtual';
    } else {
        return null;
    }
};

export const australiaStateToFriendlyName = (state: string): string => {
    const matchingState = australiaStates.find(australiaState => australiaState.code === state);
    return matchingState?.name ?? state;
};

export const jobLocationToFriendlyName = (location: IJobLocation | undefined): string => {
    const state = !!location?.state?.length ? australiaStateToFriendlyName(location.state) : undefined;
    let parts = [location?.city, state];
    parts = parts.filter(part => !!part?.length);
    return !!parts.length ? parts.join(', ') : 'Not Specified';
};
