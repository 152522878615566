import {fileLookupSelector, fileLookupReadSelector} from 'modules/file/state/file-lookup';
import {useRecoilLoadable} from 'shared/recoil/hooks/useRecoilLoadable';

export const useFileLoadable = (fileId: string) => {
    const {data, loading, error} = useRecoilLoadable({
        state: fileLookupSelector(fileId),
        loadable: fileLookupReadSelector(fileId),
    });
    return {
        file: data,
        loading,
        error,
    };
};
