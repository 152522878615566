import React from 'react';

import {Button, Form, Modal} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';
import {z} from 'zod';
import {useSetRecoilState} from 'recoil';

import {ICategory, CategoryIcon, ICategoryPictureCreate} from 'modules/help/models';
import {updateCategory} from 'modules/help/api';
import {categoryListInsertSelector} from 'modules/help/state/category-list';
import {toastAxiosError} from 'shared/utils/error';

import {InputController} from 'shared/components/form/InputController/InputController';

interface ICreateCategoryModalProps {
    show: boolean;
    onHide: () => void;
    category?: ICategory;
}

interface ICategoryUpdateForm {
    name?: string;
    description?: string;
    icon: CategoryIcon | null;
}

const categoryUpdateForm = z.object({
    name: z.string().nonempty(),
    description: z.string().nonempty(),
    icon: z.string().optional().nullable(),
});

export const UpdateCategoryModal = ({show, onHide, category}: ICreateCategoryModalProps) => {
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
    const insertCategory = useSetRecoilState(categoryListInsertSelector);

    const {
        control,
        handleSubmit,
        reset,
    } = useForm<ICategoryUpdateForm>({
        resolver: zodResolver(categoryUpdateForm),
    });

    React.useEffect(() => {
        if (show && category) {
            reset({
                name: category.name,
                description: category.description,
                icon: category.picture && 'icon' in category.picture ? category.picture.icon : null,
            });
        }
    }, [show, reset, category]);

    const onSubmit = handleSubmit(({icon, ...formData}) => {
        if (!category) {
            return;
        }

        let picture: ICategoryPictureCreate | undefined = undefined;
        if (icon) {
            picture = {
                icon,
            };
        }

        setIsSubmitting(true);
        (async () => {
            try {
                const updatedCategory = await updateCategory(category.id, {
                    picture,
                    ...formData,
                });
                insertCategory(updatedCategory);
                onHide();
            } catch (e) {
                toastAxiosError(e, 'Error updating category');
            } finally {
                setIsSubmitting(false);
            }
        })();
    });

    return (
        <Modal
            show={show && !!category}
            size="lg"
            isSubmitting={isSubmitting}
            onHide={onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Update Category
                </Modal.Title>
            </Modal.Header>
            <Form onSubmit={onSubmit}>
                <Modal.Body>
                    <Form.Group>
                        <InputController
                            control={control}
                            name="name"
                            placeholder="Name"
                            disabled={isSubmitting}
                        />
                    </Form.Group>
                    <Form.Group>
                        <InputController
                            control={control}
                            name="description"
                            placeholder="Description"
                            asType="textarea"
                            disabled={isSubmitting}
                        />
                    </Form.Group>
                    <Form.Group>
                        <InputController
                            control={control}
                            name="icon"
                            asType="select"
                            disabled={isSubmitting}
                        >
                            <option disabled value="" selected>
                                -- Select an Icon --
                            </option>
                            {Object.values(CategoryIcon).map(icon => (
                                <option key={icon} value={icon}>{icon}</option>
                            ))}
                        </InputController>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" variant="featured-blue" disabled={isSubmitting}>
                        Update
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};
