import React from 'react';

import {IIssue} from 'modules/issue/models';

import {FormatDate} from 'shared/components/date/FormatDate/FormatDate';
import {TableDropdown} from 'shared/components/TableDropdown/TableDropdown';
import {TableDropdownItem} from 'shared/components/TableDropdown/TableDropdownItem';
import {IconBin, IconEdit} from 'shared/icons/common';
import {IssueStatusBadge} from 'components/issues/shared/IssueStatusBadge/IssueStatusBadge';
import {Link} from 'react-router-dom';

interface IAdminIssuesRowProps {
    issue: IIssue;
    onDelete: () => void;
    onUpdate: () => void;
}

export const IssueListRow = ({issue, onDelete, onUpdate}: IAdminIssuesRowProps) => {

    return (
        <tr>
            <td>
                <Link to={`/issues/${issue.id}`} className="text-dark">
                    {issue?.title}
                </Link>
            </td>
            <td>
                <div className="text-truncate" style={{maxWidth: '200px'}}>
                    {issue?.description}
                </div>
            </td>
            <td className="table__date-col">
                <FormatDate>{issue?.created_at}</FormatDate>
            </td>
            <td className="table__badge-col text-center">
                <IssueStatusBadge status={issue?.status}/>
            </td>
            <td className="table__options-col">
                <TableDropdown>
                    <TableDropdownItem icon={IconEdit} variant="warning" onClick={onUpdate}>
                        Update Issue
                    </TableDropdownItem>
                    <TableDropdownItem icon={IconBin} variant="danger" onClick={onDelete}>
                        Delete Issue
                    </TableDropdownItem>
                </TableDropdown>
            </td>
        </tr>
    );
};
