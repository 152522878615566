import React from 'react';

import classnames from 'classnames';

import { ReactComponent as CancelIcon } from 'shared/icons/legalmatters/Crossdelete.svg';

interface IImageUploadedContainerProps {
    name: string;
    percentage: boolean | undefined;
    onDelete?: () => void;
}

export const ImageUploadedContainer = ({name, percentage, onDelete}: IImageUploadedContainerProps) => {
    const ImageIconPath = name.includes('pdf') ? '/assets/legalmatters/pdf.png' : '/assets/legalmatters/jpeg.png';
    const completeProgressClassName = classnames('LegalMatter__UploadImg--ProgressBar', {
        'LegalMatter__UploadImg--ProgressBar--active': percentage === true,
    });
    const completeBarClassName = classnames('progress-bar', {
        'progress-bar-complete': percentage === false,
    });

    return (
        <>
            <div className="LegalMatter__UploadImg--container">
                <div className="LegalMatter__UploadImg--left">
                    <img src={ImageIconPath} alt="Girl in a jacket" width="35" height="35" />
                </div>
                <div className="LegalMatter__UploadImg--center">
                    <div className="LegalMatter__UploadImg--text">{name}</div>
                    <div className={completeProgressClassName}>
                        <div className="progress">
                            <div className={completeBarClassName} />
                        </div>
                    </div>
                </div>
                <div className="LegalMatter__UploadImg--right">
                    <CancelIcon
                        className="LegalMatter__UploadImg--Cancel-icon"
                        onClick={() => onDelete?.()}
                    />
                </div>
            </div>
        </>
    );
};
