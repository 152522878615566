import React from 'react';
import {ErrorBoundary} from 'react-error-boundary';

import {Spinner} from 'shared/components/loading/Spinner/Spinner';
import {UserIdNameInner} from 'shared/components/user/UserIdName/UserIdNameInner';

interface IUserIdNameProps {
    id: string;
    className?: string;
}

export const UserIdName = ({id, className}: IUserIdNameProps) => {
    return (
        <ErrorBoundary
            fallback={(
                <span className="text-danger">User Not Found!</span>
            )}
        >
            <React.Suspense
                fallback={(
                    <Spinner size="sm" variant="primary"/>
                )}
            >
                <UserIdNameInner className={className ? className : ''} id={id}/>
            </React.Suspense>
        </ErrorBoundary>
    );
};
