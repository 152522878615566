import React from 'react';

import {useProfileFromPathLoadable} from 'modules/profile/hooks/useProfileFromPathLoadable';
import {useDashboardTitle} from 'shared/hooks/useDashboardTitle';
import {useTitle} from 'shared/hooks/useTitle';

import {DashboardHeader} from 'shared/components/app/DashboardHeader/DashboardHeader';
import {LoadingSpinner} from 'shared/components/loading/LoadingSpinner/LoadingSpinner';
import {PageError} from 'shared/components/PageError/PageError';
import {ViewProfileContent} from 'components/profile/ViewProfile/ViewProfileContent';
import {useUserLoadable} from 'modules/user/hooks/useUserLoadable';

export const ViewProfile = () => {
    const {profile, profileId, loading: profileLoading, error: profileError} = useProfileFromPathLoadable();
    const {user, loading: userLoading, error: userError} = useUserLoadable(profileId);

    const loading = profileLoading || userLoading;
    const error = profileError || userError;

    useDashboardTitle('View Profile');
    useTitle('View Profile');

    return (
        <>
            <DashboardHeader/>
            {loading && (
                <LoadingSpinner/>
            )}
            {error && (
                <PageError description="Error loading profile">
                    There was an error loading the profile.
                </PageError>
            )}
            {profile && user && (
                <ViewProfileContent profile={profile} user={user}/>
            )}
        </>
    );
};
