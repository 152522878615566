import React from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons';

import {IJob} from 'modules/job/models';
import {jobLocationToFriendlyName} from 'modules/job/utils';

interface IJobLocationProps {
    job: IJob;
}

export const JobLocation = ({job}: IJobLocationProps) => {
    const jobLocation = React.useMemo(() => jobLocationToFriendlyName(job.location), [job.location]);

    return (
        <small className="text-muted">
            <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2"/>
            {jobLocation}
        </small>
    );
};
