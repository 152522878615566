import React, {FormEvent} from 'react';

import {useSetRecoilState} from 'recoil';
import {Button, Form, Modal} from 'react-bootstrap';

import {IProfile} from 'modules/profile/models/IProfile';
import {toast} from 'shared/utils/toast';
import {getErrorMessage} from 'shared/utils/error';
import {deleteProfile} from 'modules/profile/api/profile';
import {profileRemoveSelector} from 'modules/profile/state/profile-insert';

import 'components/profile/ProfileList/style.scss';

interface IDeleteProfileModalProps {
    show: boolean;
    onHide: () => void;
    profile?: IProfile;
}

export const DeleteProfileModal = ({show, onHide, profile}: IDeleteProfileModalProps) => {
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
    const removeProfile = useSetRecoilState(profileRemoveSelector);

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault();

        if (!profile) {
            return;
        }

        setIsSubmitting(true);
        try {
            await deleteProfile(profile.id);
            removeProfile(profile.id);
            toast.success('User Deleted', 'The user was successfully deleted.');
            onHide();
        } catch (e) {
            toast.error('Error Deleting User', getErrorMessage(e));
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton={!isSubmitting}>
                <Modal.Title>Are you sure?</Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit}>
                <Modal.Body>
                    <p>
                        After deleting the user, they will no longer be able to access the platform, they will be
                        removed from any matters, documents etc that they are part of and will not be able to make any
                        payments that aare still required of them (if any).
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={onHide} disabled={isSubmitting}>
                        Cancel
                    </Button>
                    <Button type="submit" variant="danger" disabled={isSubmitting}>
                        {isSubmitting ? 'Deleting profile...' : 'Okay'}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};
