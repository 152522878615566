import React from 'react';

import {useRecoilStateLoadable, useRecoilValue} from 'recoil';

import {guaranteedCurrentUserScopesSelector, currentUserScopesAtom} from '../state/current-user-scopes';
import {Scope} from 'shared/auth/models';

export const usePermissionsLoadable = (isAuthenticated: boolean) => {
    const scopes = useRecoilValue(currentUserScopesAtom);
    const [scopesLoadable, setScopes] = useRecoilStateLoadable(guaranteedCurrentUserScopesSelector);

    React.useEffect(() => {
        if (scopesLoadable.state === 'hasValue') {
            setScopes(scopesLoadable.contents);
        }
    }, [scopesLoadable, setScopes]);

    if (!isAuthenticated) {
        return {
            scopes: [] as Scope[],
            loading: false,
            error: false,
        };
    } else if (scopes) {
        return {
            scopes,
            loading: false,
            error: undefined,
        };
    } else if (scopesLoadable.state === 'hasValue') {
        return {
            scopes: scopesLoadable.contents,
            loading: false,
            error: undefined,
        };
    } else if (scopesLoadable.state === 'loading') {
        return {
            scopes: undefined,
            loading: true,
            error: undefined,
        };
    } else {
        return {
            scopes: undefined,
            loading: false,
            error: scopesLoadable.errorOrThrow(),
        };
    }
};
