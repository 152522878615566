import React from 'react';

import {Badge, Form} from 'react-bootstrap';
import classNames from 'classnames';

import {TagColor} from 'modules/tag/models';

interface IColorSelectorItemProps {
    name: string;
    value?: TagColor;
    isChecked?: boolean;
    disabled?: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const ColorSelectorItem = ({name, value, isChecked, disabled, onChange}: IColorSelectorItemProps) => {
    const badgeClassName = classNames('ColorSelector__item-badge', {
        'ColorSelector__item-badge--active': isChecked,
        'ColorSelector__item-badge--disabled': disabled,
    });
    const labelClassName = classNames('ColorSelector__item-label', {
        'ColorSelector__item-label--disabled': disabled,
    });

    return (
        <Form.Check id={`ColorSelectorItem.${value}`}>
            <Form.Check.Input
                type="radio"
                name={name}
                value={value}
                disabled={disabled}
                onChange={onChange}
                hidden
            />
            <Form.Check.Label className={labelClassName}>
                {isChecked && (
                    <img src="/assets/checked.svg" alt="checked" className="ColorSelector__item-check-icon"/>
                )}
                <Badge className={badgeClassName} variant={value} pill/>
            </Form.Check.Label>
        </Form.Check>
    );
};
