import React from 'react';

import {authContext, useAuthProvider} from 'shared/auth/hooks/useAuth';

import {AccessTokenHelper} from 'shared/auth/components/AccessTokenHelper';

interface IAuthProviderContentProps {
    children?: React.ReactNode;
}

export function AuthProviderContent({children}: IAuthProviderContentProps) {
    const auth = useAuthProvider();

    return (
        <authContext.Provider value={auth}>
            <AccessTokenHelper/>
            {children}
        </authContext.Provider>
    );
}
