import React from 'react';

import {useRecoilValue} from 'recoil';

import {profileSummaryReadSelector} from 'modules/profile/state/profile-summary';

interface IUserIdNameInnerProps {
    id: string;
    className?: string;
}

export const UserIdNameInner = ({id, className}: IUserIdNameInnerProps) => {
    const profileSummary = useRecoilValue(profileSummaryReadSelector(id));

    const fullName = `${profileSummary.firstname} ${profileSummary.lastname}`;

    return (
        <span className={className}>{fullName}</span>
    );
};
