import React from 'react';

import {Badge} from 'react-bootstrap';

import {toTitleCase} from 'shared/utils/string';
import {PostKind} from 'modules/post/models/postKind';

interface IPostKindBadgeProps {
    kind: PostKind;
}

export const PostKindBadge = ({kind}: IPostKindBadgeProps) => {
    const badgeVariant = (kind === PostKind.Article) ? 'primary' : 'warning';

    return (
        <Badge variant={badgeVariant} className="badge--lg">{toTitleCase(kind)}</Badge>
    );
};
