import React from 'react';

import {Badge} from 'react-bootstrap';

import {JobStatus} from 'modules/job/models';
import {IBadgeProps} from 'shared/models/badge';
import classNames from 'classnames';

interface IJobStatusBadgeProps {
    status: JobStatus;
    size?: 'sm';
    className?: string;
}

export const JobStatusBadge = ({status, size, className}: IJobStatusBadgeProps) => {
    const badgeProps = React.useMemo<IBadgeProps>(() => {
        switch (status) {
            case JobStatus.Closed:
                return {
                    variant: '-danger-muted',
                    children: 'Closed',
                };
            case JobStatus.Open:
                return {
                    variant: '-success-muted',
                    children: 'Open',
                };
        }
    }, [status]);

    const classNameComputed = classNames(className, {
        'badge--lg': !size,
        [`badge--${size}`]: size,
    });

    return (
        <Badge pill className={classNameComputed} {...badgeProps}/>
    );
};
