import React from 'react';

import {Button} from 'react-bootstrap';
import {ArrayPath, Control, FieldArray, FieldValues, Path, useFieldArray} from 'react-hook-form';

import {IWorkExperience} from 'modules/profile/models/IWorkExperience';

import {WorkExperienceItem} from 'components/profile/shared/WorkExperienceController/WorkExperienceItem';

interface IWorkExperienceControllerProps<FormType> {
    name: ArrayPath<FormType>;
    disabled?: boolean;
    control: Control<FormType>;
}

export const WorkExperienceController = <FormType extends FieldValues>({
    disabled,
    name,
    control,
}: IWorkExperienceControllerProps<FormType>) => {
    const {fields, append, remove} = useFieldArray<FormType>({
        control,
        name,
    });

    return (
        <>
            <Button
                type="button"
                variant=""
                className="btnAddOne"
                onClick={() => append(({name: '', description: ''} as IWorkExperience) as FieldArray<FormType>)}
            >
                +Add
            </Button>
            {fields.map((field, index) => (
                <WorkExperienceItem
                    key={field.id}
                    name={`${name}.${index}` as Path<FormType>}
                    index={index}
                    onDelete={() => remove(index)}
                    disabled={disabled}
                    control={control}
                />
            ))}
        </>
    );
};
