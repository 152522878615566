import React from 'react';

import {Button, Form, Modal} from 'react-bootstrap';
import {useSetRecoilState} from 'recoil';
import {useForm} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';

import {TagColor, ITagCreate} from 'modules/tag/models';
import {toast} from 'shared/utils/toast';
import {toastAxiosError} from 'shared/utils/error';
import {createTag} from 'modules/tag/api';
import {tagInsertSelector} from 'modules/tag/state/tag-insert';
import {tagForm} from 'modules/tag/forms';

import {ColorSelector} from 'shared/components/ColorSelector/ColorSelector';

interface ICreateTagModalProps {
    show: boolean;
    onHide: () => void;
}

export const CreateTagModal = ({show, onHide}: ICreateTagModalProps) => {
    const {
        register,
        handleSubmit,
        reset,
        control,
        formState: {errors},
    } = useForm<ITagCreate>({
        resolver: zodResolver(tagForm),
    });
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
    const insertTag = useSetRecoilState(tagInsertSelector);

    const onSubmit = handleSubmit((formData) => {
        setIsSubmitting(true);
        (async () => {
            try {
                const tag = await createTag({
                    title: formData.title,
                    color: formData.color as TagColor,
                });
                insertTag(tag);
                onHide();
                toast.success('Tag created', 'The new tag was created successfully.');
            } catch (e) {
                toastAxiosError(e, 'Error Creating Tag');
            } finally {
                setIsSubmitting(false);
            }
        })();
    });

    React.useEffect(() => {
        if (!show) {
            reset();
        }
    }, [show, reset]);

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    Create a New Tag
                </Modal.Title>
            </Modal.Header>
            <Form onSubmit={onSubmit}>
                <Modal.Body>
                    <Form.Group controlId="AdminCreateTag__title">
                        <Form.Label className="form-label--sm">Title</Form.Label>
                        <Form.Control
                            {...register('title')}
                            isInvalid={!!errors?.title}
                            disabled={isSubmitting}
                        />
                        {errors && errors.title && (
                            <Form.Control.Feedback type="invalid">{errors.title.message}</Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group controlId="AdminCreateTag__color">
                        <Form.Label className="form-label--sm" id="AdminCreateTag__color_label">
                            Color
                        </Form.Label>
                        <Form.Text className="AdminCreateTag__color_form-text mb-3">
                            Select a color for the tag badge.
                        </Form.Text>
                        <ColorSelector
                            control={control}
                            name="color"
                            defaultValue={TagColor.Blue}
                            disabled={isSubmitting}
                        />
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" variant="primary" className="ml-1">
                        ADD
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};
