import React from 'react';

import {useTagListLoadable} from 'modules/tag/hooks/useTagListLoadable';

import {Tag} from 'shared/components/tag/Tag/Tag';

interface ITagIdProps {
    tagId: string;
    className?: string;
    size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    badge?: true;
}

export const TagId = ({tagId, className, size, badge}: ITagIdProps) => {
    const {tags} = useTagListLoadable();
    const tag = tags?.find(tagInState => tagInState.id === tagId);

    if (tag) {
        return (
            <Tag color={tag.color} title={tag.title} size={size} className={className} badge={badge}/>
        );
    } else {
        return null;
    }
};

TagId.defaultProps = {
    size: 'sm',
};
