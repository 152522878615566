import {IPostListQuery} from 'modules/post/models/IPostListQuery';
import {PostKind} from 'modules/post/models/postKind';

export const comparePostFilters = (filters1: IPostListQuery, filters2: IPostListQuery) => {
    return (
        filters1.userId === filters2.userId &&
        filters1.kind === filters2.kind &&
        filters1.isPublished === filters2.isPublished &&
        filters1.isFeatured === filters2.isFeatured &&
        filters1.orderBy === filters2.orderBy &&
        filters1.searchTerm === filters2.searchTerm &&
        JSON.stringify(filters1.tagIds) === JSON.stringify(filters2.tagIds)
    );
};

export const postKindToFriendlyName = (postKind: PostKind | undefined): string => {
    switch (postKind) {
        case undefined:
            return 'All';
        case PostKind.Article:
            return 'Articles';
        case PostKind.Webinar:
            return 'Webinars';
        case PostKind.Podcast:
            return 'Podcasts';
        case PostKind.Faq:
            return 'FAQs';
        default:
            throw new Error(`Post kind not implemented: ${postKind}`);
    }
};
