import axios from 'axios';

import {ICreateIssue, IIssue, IIssueUpdate, IReadIssuesQuery, IReadIssuesResult} from 'modules/issue/models';
import {getAccessTokenSilently} from 'shared/utils/token';
import {Config} from 'config';
import {getAuthHeaders} from 'shared/utils/auth';

const getURLForIssueAPI = (path: string) => {
    return `${Config.mainServiceURL}/issues${path}`;
};

export const createIssue = async (issueData: ICreateIssue): Promise<IIssue> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(getURLForIssueAPI('/'), issueData, {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const updateIssue = async (issueId: string, issueData: IIssueUpdate): Promise<IIssue> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.patch(getURLForIssueAPI(`/${issueId}`), issueData, {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const readIssue = async (issueId: string): Promise<IIssue> => {
    const response = await axios.get(getURLForIssueAPI(`/${issueId}`));
    return response.data;
};

export const readIssueList = async (params?: IReadIssuesQuery): Promise<IReadIssuesResult> => {
    const response = await axios.get(getURLForIssueAPI('/'), {
        params,
    });
    return response.data;
};

export const deleteIssue = async (issueId: string): Promise<void> => {
    const accessToken = await getAccessTokenSilently();
    await axios.delete(getURLForIssueAPI(`/${issueId}`), {
        headers: getAuthHeaders(accessToken),
    });
};
