import React from 'react';

import {Button} from 'react-bootstrap';

import {ChooseProfilePictureModal} from 'components/profile/shared/UploadProfilePicture/ChooseProfilePictureModal';
import {UploadProfilePictureModal} from 'components/profile/shared/UploadProfilePicture/UploadProfilePictureModal';

import './style.scss';

interface IUploadProfilePictureProps {
    userId: string;
}

interface IFileNameAndData {
    name: string;
    data: string;
}

export const UploadProfilePicture = ({userId}: IUploadProfilePictureProps) => {
    const fileInputRef = React.useRef<HTMLInputElement>(null);
    const [showUploadModal, setShowUploadModal] = React.useState<boolean>(false);
    const [showChooseModal, setShowChooseModal] = React.useState<boolean>(false);
    const [fileData, setFileData] = React.useState<IFileNameAndData>();

    const onFileChanged = () => {
        const file = fileInputRef.current && fileInputRef.current.files && fileInputRef.current.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                if (e.target && e.target.result) {
                    const fileName = file.name;
                    const fileData = e.target.result;
                    setFileData({name: fileName, data: fileData as string});
                    setShowUploadModal(true);
                    // clear the file input so that onChange is triggered even if same file is chosen again
                    if (fileInputRef.current) {
                        fileInputRef.current.value = '';
                    }
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const onUploadClick = () => {
        if (fileInputRef.current && fileInputRef.current.click) {
            fileInputRef.current.click();
        }
    };

    return (
        <>
            <div className="w-100">
                <input
                    ref={fileInputRef}
                    type="file"
                    id="upload"
                    accept="image/*"
                    className="UploadProfilePicture__input"
                    onChange={onFileChanged}
                    style={{display: 'none'}}
                />
                <div className="d-flex justify-content-center">
                    <Button variant="link" onClick={onUploadClick}>
                        Upload
                    </Button>
                    <Button variant="link" onClick={() => setShowChooseModal(true)}>
                        Use Existing
                    </Button>
                </div>
            </div>
            {(showUploadModal && fileData) && (
                <UploadProfilePictureModal
                    show={showUploadModal}
                    onClose={() => setShowUploadModal(false)}
                    fileName={fileData.name}
                    fileData={fileData.data}
                    userId={userId}
                />
            )}
            {(showChooseModal) && (
                <ChooseProfilePictureModal
                    userId={userId}
                    show={showChooseModal}
                    onClose={() => setShowChooseModal(false)}
                />
            )}
        </>
    );
};
