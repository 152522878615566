export const toTitleCase = (text: string): string => {
    return text.split(' ')
        .map(word => word.trim())
        .map(word => {
            return word.charAt(0).toUpperCase() + word.substring(1, word.length);
        })
        .join(' ');
};

export const truncateContent = (content: string, contentSize: number) => {
    if (content) {
        return content.length >= contentSize ? content.substring(0, (contentSize - 3)) + '...' : content;
    }
};

export const capitalizeFirstLetter = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
};

/**
 * Parse a string to an enum and raise an exception if it's invalid.
 * @param enumObj
 * @param str
 */
export const parseEnumValue = <T extends Record<string, string>>(enumObj: T, str: string): T[keyof T] => {
    const enumValues = Object.values(enumObj);

    if (!enumValues.includes(str)) {
        throw new Error(`Invalid enum value: ${str}`);
    }

    return str as T[keyof T];
};
