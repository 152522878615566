export const issueStatusOptions = [
    {
        title: 'Open',
        value: 'OPEN',
    },
    {
        title: 'Closed',
        value: 'CLOSED',
    },
];
