import {useEffect, useState} from 'react';

export const useDebounce = <ValueType>(value: ValueType, delay: number) => {
    const [debouncedValue, setDebouncedValue] = useState<ValueType>(value);

    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);

        return () => {
            clearTimeout(handler);
        };
    }, [delay, value]);

    return debouncedValue;
};
