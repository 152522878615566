import {articleListReadSelector, IArticleListStateFilters, articleListSelector} from '../state/article-list';
import {useRecoilLoadable} from 'shared/recoil/hooks/useRecoilLoadable';

export const useArticleListLoadable = (filters: IArticleListStateFilters) => {
    const {data, loading, error} = useRecoilLoadable({
        state: articleListSelector(filters),
        loadable: articleListReadSelector(filters),
    });
    return {
        articles: data?.articles,
        loading,
        error,
    };
};
