import React from 'react';

import {Dropdown} from 'react-bootstrap';

type DropdownToggle = typeof Dropdown['Toggle']['prototype'];

type CustomToggleProps = {
    className?: string;
    onClick: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
    children?: React.ReactNode;
};

export const CustomToggle = React.forwardRef<DropdownToggle, CustomToggleProps>((
    {children, className, onClick},
    ref,
) => (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a
        className={className}
        href="#"
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </a>
));
