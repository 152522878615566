import React from 'react';

import {IProfileListStateFilters} from 'modules/profile/state/profile-list';
import {useDashboardTitle} from 'shared/hooks/useDashboardTitle';
import {useTitle} from 'shared/hooks/useTitle';
import {useDebounce} from 'shared/hooks/useDebounce';
import {useProfileListLoadable} from 'modules/profile/hooks/useProfileListLoadable';

import {EmptyTable} from 'shared/components/EmptyTable/EmptyTable';
import {PageError} from 'shared/components/PageError/PageError';
import {TableSearch} from 'shared/components/TableSearch/TableSearch';
import {ProfileListTable} from 'components/profile/ProfileList/ProfileListTable';
import {LoadingSpinner} from 'shared/components/loading/LoadingSpinner/LoadingSpinner';

import 'components/profile/ProfileList/style.scss';
import {Row} from 'react-bootstrap';

export const ProfileList = () => {
    useDashboardTitle('Profiles');
    useTitle('Profiles');

    const [filters, setFilters] = React.useState<IProfileListStateFilters>({
        page: 0,
        filters: {
            limit: 20,
            searchTerm: '',
        },
    });
    const [searchTerm, setSearchTerm] = React.useState<string | undefined>('');
    const debouncedSearchTerm = useDebounce(searchTerm, 500);

    const {profileList, loading, error} = useProfileListLoadable(filters);

    React.useEffect(() => {
        if (debouncedSearchTerm !== filters.filters.searchTerm) {
            setFilters({
                ...filters,
                filters: {
                    ...filters.filters,
                    searchTerm: debouncedSearchTerm,
                },
                page: 0,
            });
        }
    }, [filters, debouncedSearchTerm]);

    return (
        <>
            <Row className="mt-3">
                <div className="col-lg-4">
                    <TableSearch
                        placeholder="Search Profiles"
                        value={searchTerm}
                        onChange={searchTerm => setSearchTerm(searchTerm)}
                        disabled={loading}
                    />
                </div>
            </Row>
            {loading && (
                <LoadingSpinner/>
            )}
            {error && (
                <PageError description="Error loading profiles">
                    There was an error loading the profiles
                </PageError>
            )}
            {profileList && !!profileList.profiles.length && (
                <ProfileListTable filters={filters} setFilters={setFilters}/>
            )}
            {profileList && !profileList.profiles.length && (
                <EmptyTable>
                    {!!filters.filters.searchTerm?.length ? (
                        'No profiles match the search.'
                    ) : (
                        'There are no profiles.'
                    )}
                </EmptyTable>
            )}
        </>
    );
};
