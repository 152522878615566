import axios, {AxiosError} from 'axios';
import {toast} from '../toast';

export function getErrorMessage(error: AxiosError) {
    try {
        const data = error.response?.data;
        if (typeof data == 'string') {
            return data;
        } else if ('detail' in data && typeof data.detail == 'string') {
            return data.detail;
        } else if ('detail' in data && Array.isArray(data.detail)) {
            const detail = data.detail;
            const name = detail[0].loc[detail[0].loc.length - 1];
            const message = detail[0].msg;
            return `${name}: ${message}`;
        }
    } catch {
        if (error.message && error.response?.statusText) {
            return `${error.message}: ${error.response.statusText}`;
        } else {
            return error.message;
        }
    }
}

export const toastAxiosError = (error: unknown, title: string): void => {
    if (axios.isAxiosError(error)) {
        toast.error(title, getErrorMessage(error));
    } else {
        throw error;
    }
};
