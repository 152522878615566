import React from 'react';

import {Badge} from 'react-bootstrap';

import {IBadgeProps} from 'shared/models/badge';

interface IJobVisibilityBadgeProps {
    isVisible: boolean;
}

export const JobVisibilityBadge = ({isVisible}: IJobVisibilityBadgeProps) => {
    const badgeProps = React.useMemo<IBadgeProps>(() => {
        if (isVisible) {
            return {
                variant: 'success',
                children: 'Published',
            };
        } else {
            return {
                variant: 'secondary',
                children: 'Draft',
            };
        }
    }, [isVisible]);

    return (
        <Badge pill className="badge--lg" {...badgeProps}/>
    );
};
