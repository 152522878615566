import React from 'react';

import {Modal as BootstrapModal, ModalProps} from 'react-bootstrap';

interface IModalProps extends ModalProps {
    onHide: () => void;
    title: React.ReactNode;
    isSubmitting: boolean;
    children: React.ReactNode;
}

export const Modal = ({onHide, title, isSubmitting, children, ...rest}: IModalProps) => {
    const handleHide = () => {
        if (!isSubmitting) {
            onHide();
        }
    };

    return (
        <BootstrapModal onHide={handleHide} {...rest}>
            <BootstrapModal.Header closeButton={!isSubmitting}>
                <BootstrapModal.Title>
                    {title}
                </BootstrapModal.Title>
            </BootstrapModal.Header>
            {children}
        </BootstrapModal>
    );
};
