import React from 'react';

import {Link} from 'react-router-dom';
import {Button, Table} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft} from '@fortawesome/free-solid-svg-icons';

import {IArticle} from 'modules/help/models';
import {useDashboardTitle} from 'shared/hooks/useDashboardTitle';
import {useTitle} from 'shared/hooks/useTitle';
import {useArticleListLoadable} from 'modules/help/hooks/useArticleListLoadable';
import {useCategoryFromPath} from 'modules/help/hooks/useCategoryFromPath';

import {EmptyTable} from 'shared/components/EmptyTable/EmptyTable';
import {ArticleRow} from 'components/help-center/CategoryPage/ArticleRow';
import {DeleteArticleModal} from 'components/help-center/components/DeleteArticleModal/DeleteArticleModal';
import {LoadingSpinner} from 'shared/components/loading/LoadingSpinner/LoadingSpinner';
import {PageError} from 'shared/components/PageError/PageError';
import {DashboardHeader} from 'shared/components/app/DashboardHeader/DashboardHeader';

export const CategoryPage = () => {
    const {category, categoryId} = useCategoryFromPath();

    const [articleToDelete, setArticleToDelete] = React.useState<IArticle | undefined>(undefined);

    const pageTitle = `Articles - ${category.name}`;
    useDashboardTitle(pageTitle);
    useTitle(pageTitle);

    const {articles, loading, error} = useArticleListLoadable({
        categoryId,
    });

    return (
        <>
            <DashboardHeader>
                <Button as={Link} to="/help-center" variant="outline-secondary mr-3">
                    <FontAwesomeIcon icon={faChevronLeft} className="mr-2"/>
                    Back
                </Button>
                <Button as={Link} to="articles/add" variant="featured-blue">
                    Add Article
                </Button>
            </DashboardHeader>
            {loading && (
                <LoadingSpinner/>
            )}
            {error && (
                <PageError description="Error loading articles"/>
            )}
            {articles && !!articles.length && (
                <Table className="table-fixed">
                    <thead>
                    <tr>
                        <th className="">Article Name</th>
                        <th className="table__truncate-col">Article Description</th>
                        <th className="table__date-col">Updated At</th>
                        <th className="table__options-col">Options</th>
                    </tr>
                    </thead>
                    <tbody>
                    {articles.map(article => (
                        <ArticleRow
                            key={article.id}
                            article={article}
                            onDelete={() => setArticleToDelete(article)}
                        />
                    ))}
                    </tbody>
                </Table>
            )}
            {articles && !articles.length && (
                <EmptyTable>There are no articles in this category</EmptyTable>
            )}
            <DeleteArticleModal
                article={articleToDelete}
                onHide={() => setArticleToDelete(undefined)}
                show={!!articleToDelete}
            />
        </>
    );
};
