import React from 'react';

import {Link} from 'react-router-dom';
import classNames from 'classnames';

import {PostKind} from 'modules/post/models/postKind';
import {postKindToFriendlyName} from 'modules/post/utils';

interface IResourceKindControlItemProps {
    value: PostKind | undefined;
    currentValue: PostKind | undefined;
    onClick: (value: PostKind | undefined) => void;
    disabled?: boolean;
}

export const ResourceKindControlItem = ({value, currentValue, onClick, disabled}: IResourceKindControlItemProps) => {
    const className = classNames('ResourceKindControl__link', {
        'ResourceKindControl__link--active': currentValue === value,
    });

    const handleClick = () => {
        if (disabled) {
            return;
        }
        onClick(value);
    };

    return (
        <Link to="#" className={className} onClick={handleClick}>
            {postKindToFriendlyName(value)}
        </Link>
    );
};
