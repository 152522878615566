import React from 'react';

import {Button} from 'react-bootstrap';
import {ArrayPath, FieldArray, FieldValues, Path, UseControllerProps, useFieldArray} from 'react-hook-form';

import {ISkill} from 'modules/profile/models/ISkill';

import {SkillsControllerItem} from 'components/profile/shared/SkillsController/SkillsControllerItem';

interface ISkillsControllerProps<FormType> extends UseControllerProps<FormType> {
    disabled?: boolean;
}

export const SkillsController = <FormType extends FieldValues>({
    disabled,
    control,
    name,
}: ISkillsControllerProps<FormType>) => {
    const {fields, append, remove} = useFieldArray({
        control,
        name: name as ArrayPath<FormType>,
    });

    return (
        <>
            <Button
                type="button"
                variant=""
                onClick={() => append(({name: '', level: 0} as ISkill) as FieldArray<FormType>)}
                className="btnAddOne"
            >
                +Add
            </Button>
            {fields.map((field, index) => (
                <SkillsControllerItem
                    key={field.id}
                    name={`skills.${index}` as Path<FormType>}
                    index={index}
                    onDelete={() => remove(index)}
                    disabled={disabled}
                    control={control}
                />
            ))}
        </>
    );
};
