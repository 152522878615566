import {IFileCreate, ICreateFileFromBlob} from 'modules/file/models/IFileCreate';
import {createFileUpload} from '../../file-upload/api';
import {createFileFromUpload, uploadBlob} from '../api';
import {Config} from 'config';

export interface IGetFileDownloadUrlProps {
    id: string;
    filename?: string;
    attachment?: boolean;
    public_url?: string;
    token?: string;
}

export const getFileDownloadUrl = ({id, filename, attachment, token, public_url}: IGetFileDownloadUrlProps): string => {
    if (public_url) {
        return public_url;
    }
    const params: string[] = [];
    if (attachment) {
        params.push('attachment=true');
    }
    if (token) {
        params.push(`token=${token}`);
    }
    const downloadUrl = filename
        ? `${Config.fileServiceURL}/files/${id}/download/${filename}`
        : `${Config.fileServiceURL}/files/${id}/download`;
    return `${downloadUrl}?${params.join('&')}`;
};

export const fileToBlob = async (file: File): Promise<Blob> => {
    return new Blob([new Uint8Array(await file.arrayBuffer())], {type: file.type});
};

export const createFileFromFile = async ({file, userId, folder}: IFileCreate) => {
    const blob = await fileToBlob(file);
    return await createFileFromBlob({
        blob,
        userId,
        filename: file.name,
        contentType: file.type,
        size: file.size,
        folder,
    });
};

export const createFileFromBlob = async ({
    blob,
    filename,
    contentType,
    size,
    userId,
    folder,
}: ICreateFileFromBlob) => {
    const fileUpload = await createFileUpload({
        userId,
        filename,
        contentType,
        size,
        folder,
    });
    await uploadBlob({
        blob,
        uploadUrl: fileUpload.url,
    });
    return await createFileFromUpload(fileUpload.id);
};
