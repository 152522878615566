export const jobDescriptionLengthMin = 20;
export const jobDescriptionLengthMax = 500;

export enum JobStatus {
    Open = 'OPEN',
    Closed = 'CLOSED',
}

export interface IJobLocation {
    country: string;
    state: string;
    city?: string;
}

export interface IJobWorkType {
    is_virtual: boolean;
    is_in_person: boolean;
}

export interface IJob {
    id: string;
    user_id: string;
    is_published: boolean;
    name: string;
    description: string;
    status: JobStatus;
    work_type: IJobWorkType;
    is_profile_visible: boolean;
    location: IJobLocation;
    created_at: string;
    updated_at: string;
}

export interface IJobUpdate {
    is_published?: boolean;
    name?: string;
    description?: string;
    work_type?: IJobWorkType;
    is_profile_visible?: boolean;
    location?: IJobLocation;
}

export interface IJobCreate {
    is_published: boolean;
    name: string;
    description: string;
    is_profile_visible: boolean;
    work_type?: IJobWorkType;
    location: IJobLocation;
}

export interface IJobQuery {
    user_id?: string;
    is_published?: boolean;
    status?: JobStatus;
    search_term?: string;

    [key: string]: string | boolean | undefined | number | JobStatus;
}

export interface IJobListQuery extends IJobQuery {
    cursor?: string;
    limit: number;
}

export interface IJobListResult {
    jobs: IJob[];
    next_cursor?: string;
}

export interface IJobChat {
    chat_id: string;
}
