import React from 'react';

import {useSetRecoilState} from 'recoil';

import {IPendingFile} from 'shared/models/pending-file/IPendingFile';
import {IFile} from 'modules/file/models/IFile';
import {fileInsertSelector} from 'modules/file/state/file-insert';

import {PDFIcon, UploadIcon} from 'shared/icons/EditProfile';
import {FileDropzone} from 'shared/components/form/FileDropzone/FileDropzone';
import {ResumeControllerFile} from 'components/profile/shared/ResumeController/ResumeControllerFile';
import {
    ImageUploadedContainer,
} from 'shared/components/form/FileDropzoneController/ImageUploadedContainer/ImageUploadedContainer';

import './style.scss';

interface IResumeUploaderProps {
    userId: string;
    disabled?: boolean;
    onChange: (fileId?: string) => void;
    value?: string;
}

export const ResumeUploader = ({
    userId,
    value,
    disabled,
    onChange,
}: IResumeUploaderProps) => {
    const [pendingFiles, setPendingFiles] = React.useState<IPendingFile[]>([]);
    const insertFile = useSetRecoilState(fileInsertSelector);

    const onFileAdded = (file: IFile) => {
        onChange(file.id);
        insertFile(file);
    };

    return (
        <>
            {!value && (
                <FileDropzone
                    userId={userId}
                    onFileAdded={onFileAdded}
                    onPendingFilesChange={newPendingFiles => setPendingFiles(newPendingFiles)}
                    disabled={disabled}
                    multiple={false}
                    className="ResumeController__resume--upload"
                    folder={`users/${userId}/public`}
                >
                    <div className="ResumeController__resume--upload--flex-center">
                        <div className="ResumeController__resume--upload--left">
                            <PDFIcon/>
                        </div>
                        <div className="ResumeController__resume--upload--left--center text-left pl-4">
                            <p className={'m-0 text-left ResumeController__resume--place-holder'}>
                                Upload Resume...
                            </p>
                        </div>
                        <div className="ResumeController__resume--upload--right--center">
                            <UploadIcon/>
                        </div>
                    </div>
                </FileDropzone>
            )}
            {value && (
                <ResumeControllerFile fileId={value} onDelete={() => onChange(undefined)}/>
            )}
            {pendingFiles.map(pendingFile => (
                <ImageUploadedContainer
                    name={pendingFile.filename}
                    percentage={false}
                />
            ))}
        </>
    );
};
