import {useEffect} from 'react';

const defaultTitle = process.env.REACT_APP_NAME ?? 'Rightful';

export const useTitle = (title?: string, overrideBaseTitle?: boolean) => {
    useEffect(() => {
        if (!title) {
            document.title = defaultTitle;
        } else {
            document.title = overrideBaseTitle ? title : `${title} - ${defaultTitle}`;
        }
    }, [title, overrideBaseTitle]);
};
