import React from 'react';

import {Link} from 'react-router-dom';
import {Container} from 'react-bootstrap';

import {useDashboardTitle} from 'shared/hooks/useDashboardTitle';

import {AppNavbarDropdown} from 'shared/components/app/AppNavbar/AppNavbarDropdown';

import './style.scss';

export const AppNavbar = () => {
    const title = useDashboardTitle();

    return (
        <header className="AppNavbar">
            <Container fluid className="d-flex align-items-center">
                <h1 className="mr-auto">
                    {title}
                </h1>
                <div className="AppNavbar__icon">
                    <Link to="/" className="AppNavbar__icon--link">
                        <img src="/assets/HOME.svg" alt="home"/>
                    </Link>
                </div>
                <AppNavbarDropdown/>
            </Container>
        </header>
    );
};
