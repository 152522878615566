import React from 'react';

import {FieldError} from 'react-hook-form';
import {Form} from 'react-bootstrap';

import {ServiceType} from 'modules/profile/models/serviceType';
import {getServiceTypeName} from 'shared/utils/service-types';

interface IServicesControlProps {
    disabled?: boolean;
    name: string;
    value: ServiceType[];
    onChange: (value: ServiceType[]) => void;
    onBlur: () => void;
    invalid?: boolean;
    error?: FieldError;
}

export const ServicesControl = ({value, invalid, onChange, onBlur, name, disabled}: IServicesControlProps) => {

    const handleChange = (serviceType: ServiceType) => {
        const index = value && value.indexOf(serviceType);
        if (index === -1) {
            onChange(value.concat([serviceType]));
        } else {
            onChange(value.filter(valueInList => valueInList !== serviceType));
        }
    };

    return (
        <Form.Group controlId="serviceTypes">
            <Form.Label>Service Types</Form.Label>
            <div>
                {Object.values(ServiceType).map((serviceType, index) => (
                    <Form.Check
                        custom
                        inline
                        name={name}
                        type="checkbox"
                        onChange={() => handleChange(serviceType)}
                        key={serviceType}
                        value={serviceType}
                        label={getServiceTypeName(serviceType)}
                        id={`servicesController.${serviceType}`}
                        disabled={disabled}
                        isInvalid={invalid}
                        checked={value && value.indexOf(serviceType) !== -1}
                        onBlur={onBlur}
                    />
                ))}
            </div>
        </Form.Group>
    );
};
