import {z} from 'zod';

import {IArticleSection} from '../models';

export interface IArticleForm {
    name: string;
    description?: string;
    sections: IArticleSection[];
    is_published: boolean,
    is_featured: boolean,
}

export const articleSectionForm = z.object({
    name: z.string().nonempty(),
    description: z.string(),
    file_id: z.string().optional().nullable(),
});

export const articleForm = z.object({
    name: z.string().nonempty(),
    description: z.string(),
    sections: z.array(articleSectionForm).optional(),
    is_published: z.boolean(),
    is_featured: z.boolean(),
});
