import React, {useState} from 'react';

import {useRecoilValue} from 'recoil';
import {Col, Form, Row} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCaretDown} from '@fortawesome/free-solid-svg-icons';

import {ITag} from 'modules/tag/models';
import {tagListReadSelector} from 'modules/tag/state/tag-list';

import {TagSelectorBadge} from './TagSelectorBadge';

import './style.scss';

interface ITagSelectorProps {
    disabled?: boolean;
    value: string[];
    onChange: (tagIds: string[]) => void;
    xs?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    md?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    lg?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
}

export const TagSelector = ({disabled, value, onChange, xs, md, lg}: ITagSelectorProps) => {
    const tags = useRecoilValue(tagListReadSelector);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const selectedTags = React.useMemo<ITag[]>(
        () => (tags ? tags.filter((tag) => value && value.indexOf(tag.id) !== -1) : []),
        [value, tags],
    );

    React.useEffect(() => {
        if (isOpen && disabled) {
            setIsOpen(false);
        }
    }, [isOpen, disabled]);

    const handleOpenOption = () => {
        setIsOpen(!isOpen);
    };

    React.useEffect(() => {
        // Close the dropdown-menu when the user clicks outside of the component
        const onWindowClick = (event: MouseEvent) => {
            const target = event?.target as HTMLElement;
            const parentNode = target?.parentNode as HTMLElement;
            if (
                target &&
                !target.matches('.TagSelector__control') &&
                !target.matches('.TagSelector__selected-tags') &&
                !target.matches('.TagSelector--hide') &&
                !(parentNode && parentNode.matches('.TagSelector--hide'))
            ) {
                setIsOpen(false);
            }
        };

        // listen to click events on window. Return a callback function to destroy the event listener when the
        // component is unmounted or this useEffect hook is called again.
        window.addEventListener('click', onWindowClick);
        return () => {
            window.removeEventListener('click', onWindowClick);
        };
    }, [setIsOpen]);

    const handleTagClick = (tagId: string, isChecked: boolean) => {
        if (disabled) {
            return;
        }
        if (isChecked) {
            onChange([...value, tagId]);
        } else {
            onChange(value.filter((valueTagId) => valueTagId !== tagId));
        }
    };

    return (
        <div className="TagSelector">
            <div onClick={handleOpenOption} className="TagSelector__control">
                <div className="TagSelector__selected-tags">
                    {selectedTags && selectedTags.map((tag) => (
                        <TagSelectorBadge
                            key={tag.id}
                            onRemove={() => onChange(value.filter((tagId) => tagId !== tag.id))}
                            isOption={true}
                            tag={tag}
                        />
                    ))}
                    {selectedTags && selectedTags.length < 1 && (
                        <div className="TagSelector__control-placeholder TagSelector--hide">Select tags</div>
                    )}
                </div>
                <FontAwesomeIcon className="TagSelector__caret TagSelector--hide" icon={faCaretDown}/>
            </div>
            {isOpen && (
                <div className="TagSelector__dropdown-menu TagSelector--hide">
                    <Row className="TagSelector--hide">
                        {tags.map((tag) => (
                            <Col
                                className="TagSelector--hide TagSelector__dropdown-column"
                                xs={xs ? xs : '12'}
                                md={md ? md : '6'}
                                lg={lg ? lg : '4'}
                                key={tag.id}
                            >
                                <Form.Check
                                    id={`tag.${tag.id}`}
                                    checked={value.indexOf(tag.id) !== -1}
                                    className="TagSelector--hide TagSelector__checkbox ASD"
                                    name={tag.title}
                                    type="checkbox"
                                    custom
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        handleTagClick(tag.id, e.target.checked);
                                    }}
                                    disabled={disabled}
                                />
                                <label className="TagSelector__dropdown-tag-label" htmlFor={`tag.${tag.id}`}>
                                    <TagSelectorBadge isOption={false} TagClassName="m-0" tag={tag}/>
                                </label>
                            </Col>
                        ))}
                    </Row>
                </div>
            )}
        </div>
    );
};
