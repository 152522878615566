import React from 'react';

import { useRecoilState } from 'recoil';

import useUnmount from 'shared/hooks/useUnmount';
import {dashboardTitleAtom} from 'shared/state/dashboard-title';

export const useDashboardTitle = (optionalTitle?: string) => {
    const [dashboardTitle, setDashboardTitle] = useRecoilState(dashboardTitleAtom);

    React.useEffect(() => {
        if (optionalTitle && optionalTitle !== dashboardTitle) {
            setDashboardTitle(optionalTitle);
        }
    }, [optionalTitle, dashboardTitle, setDashboardTitle]);

    useUnmount(() => {
        setDashboardTitle(undefined);
    });

    return React.useMemo(() => dashboardTitle, [dashboardTitle]);
};
