import React from 'react';
import {Control} from 'react-hook-form';

import {IProfile} from 'modules/profile/models/IProfile';
import {IProfileUpdate} from 'modules/profile/models/IProfileUpdate';

import {FormCard} from 'components/profile/shared/FormCard/FormCard';
import {ResumeController} from 'components/profile/shared/ResumeController/ResumeController';

interface IProfileFormResumeProps {
    profile: IProfile;
    control: Control<IProfileUpdate>;
    isSubmitting: boolean;
}

export const ProfileFormResume = ({profile, control, isSubmitting}: IProfileFormResumeProps) => {

    return (
        <FormCard title="Resume">
            <ResumeController
                name="resume_id"
                userId={profile.id}
                disabled={isSubmitting}
                control={control}
            />
        </FormCard>
    );
};
