import React from 'react';

import ReactDOM from 'react-dom';
import {BrowserRouter} from 'react-router-dom';
import {RecoilRoot} from 'recoil';

import reportWebVitals from './reportWebVitals';

import {App} from 'App';
import {ToastContainer} from 'shared/components/ToastContainer/ToastContainer';
import {AuthProvider} from 'shared/auth/components/AuthProvider/AuthProvider';

import './styles/_main.scss';

ReactDOM.render(
    <React.StrictMode>
        <RecoilRoot>
            <BrowserRouter>
                <AuthProvider>
                    <App/>
                </AuthProvider>
            </BrowserRouter>
        </RecoilRoot>
        <ToastContainer/>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
