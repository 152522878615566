export enum UserRole {
    System = 'SYSTEM',
    Client = 'CLIENT',
    Provider = 'PROVIDER',
}

export interface IUserIdentity {
    connection: string;
    user_id: string;
    provider: string;
    is_social?: boolean;
}

export interface IUser {
    id: string;
    name?: string;
    given_name?: string;
    family_name?: string;
    email: string;
    is_verified: boolean;
    identities: IUserIdentity[];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    app_metadata?: any;
    logins_count?: number;
    last_login?: string;
}

export interface IUserUpdate {
    email?: string;
    username?: string;
    password?: string;
    is_activated?: boolean;
}

export interface IUserCreate {
    role: UserRole;
    email: string;
    username?: string;
    password: string;
    is_activated?: boolean;
    is_accepted_terms?: boolean;
    is_subscribed?: boolean;
}
