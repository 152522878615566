import {atom, DefaultValue, selectorFamily} from 'recoil';

import {IPost} from 'modules/post/models/IPost';
import {IPostListResult} from 'modules/post/models/IPostListResult';
import {IReadFeaturedPostsRequest} from 'modules/post/models/IReadFeaturedPostsRequest';
import {readFeaturedPostList} from 'modules/post/api';

interface IFeaturedPostsState {
    filters: IReadFeaturedPostsRequest;
    posts: IPost[];
    nextCursor?: string;
    hasLoaded: boolean;
    _version: number;

    [key: string]: string | boolean | undefined | IPost[] | IReadFeaturedPostsRequest | number;
}

const compareFilters = (filters1: IReadFeaturedPostsRequest, filters2: IReadFeaturedPostsRequest) => {
    return (
        filters1.userId === filters2.userId &&
        filters1.kind === filters2.kind &&
        filters1.limit === filters2.limit &&
        filters1.cursor === filters2.cursor
    );
};

export const featuredPostsAtom = atom<IFeaturedPostsState>({
    key: 'featuredPostsAtom',
    default: {
        filters: {},
        posts: [],
        hasLoaded: false,
        _version: 0,
    },
});

export const resetFeaturedPosts = atom<number>({
    key: 'resetFeaturedPosts',
    default: 0,
});

export const featuredPostsSelector = selectorFamily<IPostListResult, IReadFeaturedPostsRequest>({
    key: 'featuredPostsSelector',
    get: (filters) => async ({get}) => {
        const state = get(featuredPostsAtom);
        const resetVersion = get(resetFeaturedPosts);
        if (state.hasLoaded && compareFilters(filters, state.filters) && state._version === resetVersion) {
            return {
                posts: state.posts,
                nextCursor: state.nextCursor,
            } as IPostListResult;
        } else {
            return await readFeaturedPostList(filters);
        }
    },
    set: (filters) => ({get, set}, newValue) => {
        if (newValue instanceof DefaultValue) {
            return;
        }
        const atomState = get(featuredPostsAtom);
        if (!compareFilters(atomState.filters, filters) || newValue.posts !== atomState.posts) {
            set(featuredPostsAtom, {
                filters,
                ...newValue,
                hasLoaded: true,
                _version: get(resetFeaturedPosts),
            });
        }
    },
});
