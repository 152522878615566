import React from 'react';

import {Link, useMatch, useResolvedPath} from 'react-router-dom';
import {Nav} from 'react-bootstrap';
import classNames from 'classnames';

import 'shared/components/app/DashboardSidebarLink/style.scss';

interface IDashboardSidebarLinkProps {
    size?: 'sm';
    icon?: React.ElementType;
    to: string;
    match?: string;
    exact?: boolean;
    collapse?: boolean;
    onClick?: () => void;
    className?: string;
}

export const DashboardSidebarLink = ({
    size,
    icon,
    children,
    to,
    exact,
    collapse,
    onClick,
    className,
    match: matchProp,
}: React.PropsWithChildren<IDashboardSidebarLinkProps>) => {
    const resolved = useResolvedPath(matchProp ?? to);
    const match = useMatch({path: resolved.pathname, end: exact || false});

    const Icon = icon;

    const linkClassName = classNames('DashboardSidebarLink', className, {
        [`DashboardSidebarLink--${size}`]: size,
        'DashboardSidebarLink--collapsed': collapse,
    });

    const iconClassName = classNames('DashboardSidebarLink__icon', {
        'DashboardSidebarLink__icon--collapsed': collapse,
    });

    const titleClassName = classNames('DashboardSidebarLink__title', {
        'DashboardSidebarLink__title--collapsed': collapse,
        [`DashboardSidebarLink__title--${size}`]: size,
    });

    const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        // if no "to" prop is provided then prevent the default action of navigating to "#"
        if (!to) {
            e.preventDefault();
        }
        onClick?.();
    };

    return (
        <Nav.Link as={Link} to={to ?? '#'} className={linkClassName} active={!!match} onClick={handleClick}>
            {Icon && <Icon className={iconClassName} />}
            <span className={titleClassName}>{children}</span>
        </Nav.Link>
    );
};
