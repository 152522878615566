import {Params, useParams} from 'react-router-dom';

import {postLookupReadSelector, postLookupSelector} from '../state/post-lookup';
import {useRecoilLoadable} from 'shared/recoil/hooks/useRecoilLoadable';

export const usePostFromPathLoadable = () => {
    const params = useParams() as Params<'postId'>;
    const postId = params.postId as string;

    const {data, loading, error} = useRecoilLoadable({
        state: postLookupSelector(postId),
        loadable: postLookupReadSelector(postId),
    });

    return {
        postId,
        post: data,
        loading,
        error,
    };
};
