import React from 'react';

import {Controller, FieldValues, UseControllerProps} from 'react-hook-form';
import {Form} from 'react-bootstrap';

import {TagSelector} from 'components/profile/shared/TagSelector/TagSelector';

import './style.scss';

interface ITagSelectorControllerProps<FormType> extends UseControllerProps<FormType> {
    disabled?: boolean;
    xs?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    md?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    lg?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
}

export const TagSelectorController = <FormType extends FieldValues>({
    control,
    name,
    disabled,
    defaultValue,
    xs,
    md,
    lg,
}: ITagSelectorControllerProps<FormType>) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({field, fieldState: {invalid, isDirty, error}}) => (
                <>
                    <TagSelector {...field} xs={xs} md={md} lg={lg} disabled={disabled}/>
                    {error && isDirty && (
                        <Form.Control.Feedback type="invalid" className="d-block m-0">
                            {error.message}
                        </Form.Control.Feedback>
                    )}
                </>
            )}
        />
    );
};
