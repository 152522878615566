import {IIssueListStateFilters, issueListSelector, issueListReadSelector} from 'modules/issue/state/issue-list';
import {useRecoilLoadable} from 'shared/recoil/hooks/useRecoilLoadable';

export const useIssueListLoadable = (filters: IIssueListStateFilters) => {
    const {data, loading, error} = useRecoilLoadable({
        state: issueListSelector(filters),
        loadable: issueListReadSelector(filters),
    });
    return {
        issueList: data,
        loading,
        error,
    };
};
