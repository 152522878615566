import {selector} from 'recoil';

import {IJob} from 'modules/job/models';
import {jobLookupInsertSelector, jobLookupRemoveSelector} from 'modules/job/state/job-lookup';
import {jobListInsertSelector, jobListRemoveSelector} from 'modules/job/state/job-list';
import {throwWriteOnlySelectorError, guardRecoilDefaultValue} from 'shared/recoil/utils';

export const jobInsertSelector = selector<IJob>({
    key: 'jobInsertSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        set(jobLookupInsertSelector, newValue);
        set(jobListInsertSelector, newValue);
    },
});

export const jobRemoveSelector = selector<string>({
    key: 'jobRemoveSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        set(jobLookupRemoveSelector, newValue);
        set(jobListRemoveSelector, newValue);
    },
});
