import React from 'react';

import {Nav} from 'react-bootstrap';
import classNames from 'classnames';

import {
    LogoutIcon,
    DashboardIcon,
    ProfileIcon,
    ResourceIcon,
    GavelIcon,
    TagIcon,
    HelpCenter, GroupIcon,
} from 'shared/icons/dashboard';
import {DashboardSidebarLink} from 'shared/components/app/DashboardSidebarLink/DashboardSidebarLink';

import './style.scss';
import {IconCog} from 'shared/icons/common';

interface IDashboardSidebarProps {
    collapse: boolean;
    onCollapse: (value: boolean) => void;
}

export const DashboardSidebar = ({collapse, onCollapse}: IDashboardSidebarProps) => {
    const className = classNames('Dashboard__aside', {
        'Dashboard__aside--collapsed': collapse,
    });

    return (
        <div className={className}>
            <div className="DashboardSidebar__header">
                <img
                    src="/assets/Group2091SidebarLogo.png"
                    alt="Rightful"
                    onClick={() => onCollapse(!collapse)}
                    className="DashboardSidebar__header-image"
                />
            </div>
            <Nav defaultActiveKey="/home" className="flex-column">
                <DashboardSidebarLink
                    collapse={collapse}
                    to="/"
                    icon={DashboardIcon}
                    exact
                >
                    Dashboard
                </DashboardSidebarLink>
                <DashboardSidebarLink
                    collapse={collapse}
                    to="profiles"
                    icon={ProfileIcon}
                >
                    Profiles
                </DashboardSidebarLink>
                <DashboardSidebarLink
                    collapse={collapse}
                    to="resources"
                    icon={ResourceIcon}
                >
                    Resources
                </DashboardSidebarLink>
                <DashboardSidebarLink
                    collapse={collapse}
                    to="issues"
                    icon={GavelIcon}
                >
                    Issues
                </DashboardSidebarLink>
                <DashboardSidebarLink
                    collapse={collapse}
                    to="jobs"
                    icon={GroupIcon}
                >
                    Jobs
                </DashboardSidebarLink>
                <DashboardSidebarLink
                    collapse={collapse}
                    to="tags"
                    icon={TagIcon}
                >
                    Tags
                </DashboardSidebarLink>
                <DashboardSidebarLink
                    collapse={collapse}
                    to="help-center"
                    icon={HelpCenter}
                >
                    Help Center
                </DashboardSidebarLink>
                <DashboardSidebarLink
                    collapse={collapse}
                    to="system"
                    icon={IconCog}
                >
                    System Tasks
                </DashboardSidebarLink>
            </Nav>
            <Nav className="DashboardSidebar__footer flex-column">
                <DashboardSidebarLink
                    collapse={collapse}
                    to="/logout"
                    icon={LogoutIcon}
                    className="mb-0"
                >
                    Logout
                </DashboardSidebarLink>
            </Nav>
        </div>
    );
};
