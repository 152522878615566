import {atom, selector, selectorFamily} from 'recoil';

import {IFile} from 'modules/file/models/IFile';
import {readFile, readFileList} from 'modules/file/api';
import {guardRecoilDefaultValue} from 'shared/recoil/utils';

export interface IFileManagerStateFilters {
    page: number;
    userId?: string;
    limit: number;
    folder: string;

    [key: string]: string | number | undefined;
}

interface IFileManagerState {
    files: IFile[];
    cursors: (string | undefined)[];
    page: number;
    limit: number;
    more: boolean;
    userId?: string;
    folder: string;

    [key: string]: string | number | undefined | boolean | IFile[] | (string | undefined)[];
}

export const fileManagerAtom = atom<IFileManagerState | undefined>({
    key: 'fileManagerAtom',
    default: undefined,
});

export const fileManagerSelector = selectorFamily<IFileManagerState | undefined, IFileManagerStateFilters>({
    key: 'fileManagerSelector',
    get: ({page, limit, userId, folder}) => async ({get}) => {
        const fileManagerState = get(fileManagerAtom);
        if (
            fileManagerState &&
            fileManagerState.userId === userId &&
            fileManagerState.folder === folder &&
            fileManagerState.limit === limit &&
            fileManagerState.folder === folder &&
            fileManagerState.page === page
        ) {
            return fileManagerState;
        }
        return undefined;
    },
    set: (_) => async ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue) || !newValue) {
            return;
        }
        set(fileManagerAtom, newValue);
    },
});

export const fileManagerReadSelector = selectorFamily<IFileManagerState, IFileManagerStateFilters>({
    key: 'fileManagerReadSelector',
    get: (filters) => async ({get}) => {
        const currentValue = get(fileManagerSelector(filters));
        if (currentValue) {
            return currentValue;
        }

        const fileManagerState = get(fileManagerAtom);

        // load a fresh page from the server
        const result = await readFileList({
            userId: filters.userId,
            limit: filters.limit,
            folder: filters.folder,
            cursor: filters.page === 0 ? undefined : fileManagerState?.cursors[filters.page - 1],
        });

        // add the cursor to the list of cursors so we can paginate backwards
        const newCursors = fileManagerState ? Array.from(fileManagerState.cursors) : [];
        newCursors[filters.page] = result.nextCursor;

        return {
            files: result.files,
            cursors: newCursors,
            page: filters.page,
            limit: filters.limit,
            userId: filters.userId,
            folder: filters.folder,
            more: !!result.nextCursor,
        } as IFileManagerState;
    },
});

export const readFileSelector = selectorFamily<IFile, string>({
    key: 'fileManager:readFile',
    get: (fileId) => async ({get}) => {
        const fileManagerState = get(fileManagerAtom);
        const fileInStore = fileManagerState?.files?.find((file) => file.id === fileId);
        return fileInStore ?? (await readFile(fileId));
    },
});

export const fileOptionalSelector = selectorFamily<IFile | undefined, string | undefined>({
    key: 'fileManager:readFileOptional',
    get: (fileId) => async ({get}) => {
        return fileId ? get(readFileSelector(fileId)) : undefined;
    },
});

export const fileListInsertSelector = selector<IFile>({
    key: 'insertFileSelector',
    get: () => {
        throw new Error('This is a "write only" selector');
    },
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }

        const atomValue = get(fileManagerAtom);
        if (!atomValue) {
            return;
        }

        const files: IFile[] = Array.from(atomValue?.files);
        const index = files.findIndex((file) => file.id === newValue.id);
        if (index !== -1) {
            files.splice(index, 1, newValue);
        } else {
            files.push(newValue);
        }
        set(fileManagerAtom, {
            ...atomValue,
            files,
        });
    },
});

export const fileListRemoveSelector = selector<string>({
    key: 'fileListRemoveSelector',
    get: () => {
        throw new Error('This is a write-only selector');
    },
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        const atomValue = get(fileManagerAtom);
        if (atomValue) {
            // TODO: update totals once we have "counts"
            set(fileManagerAtom, {
                ...atomValue,
                files: atomValue.files.filter((file) => file.id !== newValue),
            });
        }
    },
});
