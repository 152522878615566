import React from 'react';

import {Controller, FieldValues, UseControllerProps} from 'react-hook-form';
import {Form} from 'react-bootstrap';

import {ColorSelectorControl} from 'shared/components/ColorSelector/ColorSelectorControl';

interface IColorSelectorProps<FormType> extends UseControllerProps<FormType> {
    disabled?: boolean;
}

export const ColorSelector = <FormType extends FieldValues>({
    control,
    name,
    disabled,
    defaultValue,
}: IColorSelectorProps<FormType>) => {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({field, fieldState: {invalid, isDirty, error}}) => (
                <>
                    <ColorSelectorControl
                        {...field}
                        isInvalid={invalid && isDirty}
                        disabled={disabled}
                    />
                    {error && isDirty && (
                        <Form.Control.Feedback type="invalid" className="d-block">
                            {error.message}
                        </Form.Control.Feedback>
                    )}
                </>
            )}
        />
    );
};
