import React from 'react';

import {Table} from 'react-bootstrap';
import {useParams, Params, useNavigate} from 'react-router-dom';

import {useJobListLoadable} from 'modules/job/hooks/useJobListLoadable';
import {useDashboardTitle} from 'shared/hooks/useDashboardTitle';
import {useTitle} from 'shared/hooks/useTitle';

import {DashboardHeader} from 'shared/components/app/DashboardHeader/DashboardHeader';
import {LoadingSpinner} from 'shared/components/loading/LoadingSpinner/LoadingSpinner';
import {PageError} from 'shared/components/PageError/PageError';
import {EmptyTable} from 'shared/components/EmptyTable/EmptyTable';
import {JobListRow} from 'components/job/JobList/JobListRow';
import {ProfilePicture} from 'shared/components/ProfilePicture/ProfilePicture';
import {JobModal} from 'components/job/shared/JobModal/JobModal';
import { CloseJobModal } from '../CancelJobModal/CloseJobModal';

export const JobList = () => {
    useDashboardTitle('Jobs');
    useTitle('Jobs');

    const navigate = useNavigate();

    const params = useParams() as Params<'jobId'>;
    const jobId = params.jobId as string;

    const {jobList, loading, error} = useJobListLoadable({
        page: 0,
        limit: 100,
        filters: {},
    });
    const [cancelJobId, setCancelJobId] = React.useState<string | undefined>(undefined);

    return (
        <>
            <DashboardHeader/>
            {loading && (
                <LoadingSpinner/>
            )}
            {error && (
                <PageError description="Error loading Jobs"/>
            )}
            {jobList && !jobList.jobs.length && (
                <EmptyTable>
                    No Jobs
                </EmptyTable>
            )}
            {jobList && !!jobList.jobs.length && (
                <Table className="table-fixed">
                    <thead>
                    <tr>
                        <th className="table__picture-col table_th_common">
                            <ProfilePicture alt="Profile Picture" imgClassName="article" round size="xs" table/>
                        </th>
                        <th>Job</th>
                        <th className="table__date-col-lg text-center">User</th>
                        <th className="table__date-col-lg text-center">Job Format</th>
                        <th className="table__badge-col text-center">Publication</th>
                        <th className="table__badge-col text-center">Anonymity</th>
                        <th className="table__badge-col text-center">Job Status</th>
                        <th className="table__details-col-lg">Location</th>
                        <th className="table__options-col">Options</th>
                    </tr>
                    </thead>
                    <tbody>
                    {jobList.jobs.map(job => (
                        <JobListRow
                            key={job.id}
                            job={job}
                            onCancel={() => setCancelJobId(job.id)}
                        />
                    ))}
                    </tbody>
                </Table>
            )}
            <JobModal jobId={jobId} onHide={() => navigate('/jobs')}/>
            <CloseJobModal jobId={cancelJobId} show={!!cancelJobId} onHide={() => setCancelJobId(undefined)} />
        </>
    );
};
