import axios from 'axios';

import {getAccessTokenSilently} from 'shared/utils/token';
import {getAuthHeaders} from 'shared/utils/auth';
import {Config} from 'config';
import {IPaymentSettings, IPaymentSettingsInviteURL} from 'modules/payment/models';

export const getURLForPaymentAPI = (path: string): string => {
    return `${Config.paymentServiceURL}${path}`;
};

export const readPaymentSettings = async (userId: string): Promise<IPaymentSettings> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getURLForPaymentAPI(`/payment_settings/${userId}`), {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const createPaymentSettingsInviteURL = async (userId: string): Promise<IPaymentSettingsInviteURL> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getURLForPaymentAPI(`/payment_settings/${userId}/invite_url`), {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const readSystemBankAccount = async () => {
    const accessToken = await getAccessTokenSilently();
    return await axios.get(getURLForPaymentAPI('/system/payment/bank_account'), {
        headers: getAuthHeaders(accessToken),
    });
};

export const initializePaymentMessagingSubscriptions = async () => {
    const accessToken = await getAccessTokenSilently();
    return await axios.post(getURLForPaymentAPI('/system/initialize_messaging_subscriptions'), null, {
        headers: getAuthHeaders(accessToken),
    });
};
