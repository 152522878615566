import {selectorFamily, atomFamily} from 'recoil';

import {IIssue} from 'modules/issue/models';
import {readIssue} from 'modules/issue/api';
import {issueListAtom} from 'modules/issue/state/issue-list';
import {guardRecoilDefaultValue} from 'shared/recoil/utils';

export const issueLookupAtom = atomFamily<IIssue | undefined, string>({
    key: 'issueLookupAtom',
    default: undefined,
});

export const issueLookupSelector = selectorFamily<IIssue | undefined, string>({
    key: 'issueLookupSelector',
    get: (issueId: string) => ({get}) => {
        const valueInLookup = get(issueLookupAtom(issueId));
        if (valueInLookup) {
            return valueInLookup;
        }

        const listAtomValue = get(issueListAtom);
        return listAtomValue?.issues?.find(issue => issue.id === issueId);
    },
    set: () => ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue) || !newValue) {
            return;
        }
        set(issueLookupAtom(newValue.id), newValue);
    },
});

export const issueLookupReadSelector = selectorFamily<IIssue, string>({
    key: 'issueLookupReadSelector',
    get: (issueId: string) => async ({get}): Promise<IIssue> => {
        const currentValue = get(issueLookupSelector(issueId));
        if (currentValue) {
            return currentValue;
        }
        return await readIssue(issueId);
    },
});
