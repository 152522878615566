import {Params, useParams} from 'react-router-dom';

import {useCategory} from './useCategory';

export const useCategoryFromPath = () => {
    const params = useParams() as Params<'categoryId'>;
    const categoryId = params.categoryId as string;

    const category = useCategory(categoryId);
    return {
        category,
        categoryId,
    };
};
