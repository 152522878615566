import {atom, selector, selectorFamily} from 'recoil';

import {IJob} from 'modules/job/models';
import {readJob} from 'modules/job/api';
import {throwWriteOnlySelectorError, guardRecoilDefaultValue} from 'shared/recoil/utils';
import {jobListAtom} from 'modules/job/state/job-list';
import {jobInsertSelector} from 'modules/job/state/job-insert';

export const jobLookupAtom = atom<IJob[]>({
    key: 'jobLookupAtom',
    default: [],
});

export const jobLookupSelector = selectorFamily<IJob | undefined, string>({
    key: 'jobLookupSelector',
    get: (jobId: string) => ({get}) => {
        const atomValue = get(jobLookupAtom);
        const valueInLookup = atomValue.find(job => job.id === jobId);
        if (valueInLookup) {
            return valueInLookup;
        }

        const listValue = get(jobListAtom);
        const valueInList = listValue?.jobs?.find(job => job.id === jobId);
        if (valueInList) {
            return valueInList;
        }

        // there is no need to search for the job in the "search" selector, because the state setter automatically
        // adds the jobs to the lookup state

        return undefined;
    },
    set: () => ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue) || !newValue) {
            return;
        }
        set(jobLookupInsertSelector, newValue);
    },
});

export const jobLookupReadSelector = selectorFamily<IJob, string>({
    key: 'jobLookupReadSelector',
    get: (jobId: string) => async ({get}): Promise<IJob> => {
        const currentValue = get(jobLookupSelector(jobId));
        if (currentValue) {
            return currentValue;
        }
        return await readJob(jobId);
    },
});

export const jobLookupMaybeSelector = selectorFamily<IJob | undefined, string | undefined>({
    key: 'jobLookupMaybeSelector',
    get: (jobId) => ({get}) => {
        if (jobId) {
            return get(jobLookupSelector(jobId));
        } else {
            return undefined;
        }
    },
    set: () => ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue) || !newValue) {
            return;
        }
        set(jobInsertSelector, newValue);
    },
});

export const jobLookupMaybeReadSelector = selectorFamily<IJob | undefined, string | undefined>({
    key: 'jobLookupMaybeReadSelector',
    get: (jobId) => async ({get}): Promise<IJob | undefined> => {
        if (jobId) {
            return get(jobLookupReadSelector(jobId));
        } else {
            return undefined;
        }
    },
});

export const jobLookupInsertSelector = selector<IJob>({
    key: 'jobLookupInsertSelector',
    get: throwWriteOnlySelectorError,
    set: ({set, get}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        const atomValue = get(jobLookupAtom);
        set(jobLookupAtom, [
            ...atomValue.filter(job => job.id !== newValue.id),
            newValue,
        ]);
    },
});

export const jobLookupRemoveSelector = selector<string>({
    key: 'jobLookupRemoveSelector',
    get: throwWriteOnlySelectorError,
    set: ({set, get}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        const atomValue = get(jobLookupAtom);
        set(jobLookupAtom, atomValue.filter(job => job.id !== newValue));
    },
});
