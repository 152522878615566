import React from 'react';

import {Button, Col, Form, Row} from 'react-bootstrap';
import {FieldValues, Path, UseControllerProps} from 'react-hook-form';

import {Delete} from 'shared/icons/dashboard';
import {InputController} from 'shared/components/form/InputController/InputController';
import {SocialProviderSelect} from 'components/profile/shared/SocialProviderSelect/SocialProviderSelect';

interface ISocialLinksControllerItemProps<FormType> extends UseControllerProps<FormType> {
    index: number;
    disabled?: boolean;
    onDelete?: () => void;
}

export const SocialLinksControllerItem = <FormType extends FieldValues>({
    disabled,
    name,
    control,
    onDelete,
}: ISocialLinksControllerItemProps<FormType>) => {
    return (
        <Row>
            <Col xs={4}>
                <Form.Group as={Col} controlId={`${name}.type`}>
                    <label className="Inputlable"><span className="d-none d-xl-inline">Social Media </span>Provider</label>
                    <SocialProviderSelect
                        control={control}
                        name={`${name}.type` as Path<FormType>}
                        disabled={disabled}
                    />
                </Form.Group>
            </Col>
            <Col xs={6} xl={7}>
                <Form.Group as={Col} controlId={`${name}.url`}>
                    <label className="Inputlable">URL</label>
                    <InputController
                        control={control}
                        name={`${name}.url` as Path<FormType>}
                        placeholder="URL"
                        className="Input__box-shodow--none Inputfield"
                        disabled={disabled}
                    />
                </Form.Group>
            </Col>
            <Col xs={2} xl={1}>
                <Form.Label className="d-block">&nbsp;</Form.Label>
                <Button variant="link" onClick={() => onDelete?.()}>
                    <Delete className="ClodeBtn" />
                </Button>
            </Col>
        </Row>
    );
};
