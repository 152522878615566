import axios from 'axios';

import {getAccessTokenSilently, getAccessTokenMaybe} from 'shared/utils/token';
import {IJob, IJobCreate, IJobListQuery, IJobListResult, IJobUpdate, IJobChat} from 'modules/job/models';
import {Config} from 'config';
import {getAuthHeaders} from 'shared/utils/auth';

export const getURLForJobAPI = (path: string): string => {
    return `${Config.jobServiceURL}/jobs${path}`;
};

export const readJobList = async (params: IJobListQuery): Promise<IJobListResult> => {
    const accessTokenMaybe = await getAccessTokenMaybe();
    const response = await axios.get(getURLForJobAPI('/'), {
        params,
        headers: accessTokenMaybe ? getAuthHeaders(accessTokenMaybe) : undefined,
    });
    return response.data as IJobListResult;
};

export const readJob = async (jobId: string): Promise<IJob> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getURLForJobAPI(`/${jobId}`), {
        headers: getAuthHeaders(accessToken),
    });
    return response.data as IJob;
};

export const createJob = async (jobCreate: IJobCreate): Promise<IJob> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(getURLForJobAPI('/'), jobCreate, {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const createJobChat = async (jobId: string): Promise<IJobChat> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(getURLForJobAPI(`/${jobId}/chat`), null, {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const closeJob = async (jobId: string): Promise<IJob> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(getURLForJobAPI(`/${jobId}/close`), null, {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const updateJob = async (jobId: string, jobUpdate: IJobUpdate): Promise<IJob> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.patch(getURLForJobAPI(`/${jobId}`), jobUpdate, {
        headers: getAuthHeaders(accessToken),
    });
    return response.data as IJob;
};
