import React from 'react';

import {Badge} from 'react-bootstrap';

interface IProfileStatusBadgeProps {
    isPublished: boolean;
    isFeatured: boolean;
}

export const ProfileStatusBadge = ({isPublished, isFeatured}: IProfileStatusBadgeProps) => {
    let title: string;
    let variant: string;
    if (isPublished && isFeatured) {
        title = 'Featured';
        variant = 'success-muted';
    } else if (isPublished && !isFeatured) {
        title = 'Published';
        variant = 'danger-muted';
    } else {
        title = 'Private';
        variant = 'light-warning-muted';
    }

    return (
        <Badge pill className={`badge--${variant} badge--lg`}>{title}</Badge>
    );
};
