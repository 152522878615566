import React from 'react';

import {Link} from 'react-router-dom';
import {Dropdown} from 'react-bootstrap';

import {useGuaranteedAuth} from 'shared/auth/hooks/useAuth';

import {Caret} from 'shared/components/Caret/Caret';
import {LogOut, ProfileUser} from 'shared/icons/navbar';
import {ProfilePicture} from 'shared/components/ProfilePicture/ProfilePicture';
import {CustomToggle} from 'shared/components/CustomToggle/CustomToggle';

export const AppNavbarDropdown = () => {
    const {tokenData} = useGuaranteedAuth();

    return (
        <Dropdown>
            <Dropdown.Toggle
                as={CustomToggle}
                id="AppNavbarDropdown.toggle"
                className="AppNavbar__dropdown-toggle AppNavbar__nav-item AppNavbar__nav-item--right AppNavbar__link pr-0"
            >
                <ProfilePicture
                    url={tokenData.picture}
                    imgClassName="article"
                    round
                    size="xs"
                    table
                    darkPlaceholder={true}
                    alt={tokenData.nickname}
                />
                <span className="AppNavbar__dropdown-name ml-3 d-none d-sm-inline-block">
                    {tokenData.nickname}
                </span>
                <Caret className="ml-3"/>
            </Dropdown.Toggle>

            <Dropdown.Menu className="NavDropdown">
                <Dropdown.Item as={Link} to="/" className="NavDropdown__link">
                    <ProfileUser className="NavDropdown__icon"/>
                    Dashboard
                </Dropdown.Item>
                <Dropdown.Item as={Link} to="/logout" className="NavDropdown__link">
                    <LogOut className="NavDropdown__icon"/> Log Out
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
};
