import React from 'react';

import {Button, Table} from 'react-bootstrap';

import {ICategory} from 'modules/help/models';
import {useDashboardTitle} from 'shared/hooks/useDashboardTitle';
import {useTitle} from 'shared/hooks/useTitle';
import {useCategoryListLoadable} from 'modules/help/hooks/useCategoryListLoadable';

import {EmptyTable} from 'shared/components/EmptyTable/EmptyTable';
import {CategoryListRow} from 'components/help-center/CategoryList/CategoryListRow';
import {CreateCategoryModal} from 'components/help-center/components/CreateCategoryModal/CreateCategoryModal';
import {DeleteCategoryModal} from 'components/help-center/components/DeleteCategoryModal/DeleteCategoryModal';
import {UpdateCategoryModal} from 'components/help-center/components/UpdateCategoryModal/UpdateCategoryModal';
import {DashboardHeader} from 'shared/components/app/DashboardHeader/DashboardHeader';
import {LoadingSpinner} from 'shared/components/loading/LoadingSpinner/LoadingSpinner';
import {PageError} from 'shared/components/PageError/PageError';

export const CategoryList = () => {
    useDashboardTitle('Categories - Help Center');
    useTitle('Categories - Help Center');

    const [showModal, setShowModal] = React.useState<boolean>(false);
    const [categoryToDelete, setCategoryToDelete] = React.useState<ICategory | undefined>(undefined);
    const [categoryToUpdate, setCategoryToUpdate] = React.useState<ICategory | undefined>(undefined);

    const {categories, loading, error} = useCategoryListLoadable();

    return (
        <>
            <DashboardHeader>
                <Button type="button" onClick={() => setShowModal(true)} className="btn-featured-blue">
                    Add Category
                </Button>
            </DashboardHeader>
            {loading && (
                <LoadingSpinner/>
            )}
            {error && (
                <PageError description="Error loading help categories"/>
            )}
            {categories && !!categories.length && (
                <Table className="table-fixed">
                    <thead>
                    <tr>
                        <th className="table__picture-col"/>
                        <th>Category</th>
                        <th className="table__title-col">Description</th>
                        <th className="table__date--col">Created At</th>
                        <th className="table__options-col">Options</th>
                    </tr>
                    </thead>
                    <tbody>
                    {categories.map(category => (
                        <CategoryListRow
                            key={category.id}
                            category={category}
                            onDelete={() => setCategoryToDelete(category)}
                            onUpdate={() => setCategoryToUpdate(category)}
                        />
                    ))}
                    </tbody>
                </Table>
            )}
            {categories && !categories.length && (
                <EmptyTable>There are no help categories</EmptyTable>
            )}
            <CreateCategoryModal
                show={showModal}
                onHide={() => setShowModal(false)}
            />
            <DeleteCategoryModal
                category={categoryToDelete}
                show={!!categoryToDelete}
                onHide={() => setCategoryToDelete(undefined)}
            />
            <UpdateCategoryModal
                category={categoryToUpdate}
                show={!!categoryToUpdate}
                onHide={() => setCategoryToUpdate(undefined)}
            />
        </>
    );
};
