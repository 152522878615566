import React from 'react';

import {Scope} from 'shared/auth/models';
import {useAuth} from 'shared/auth/hooks/useAuth';

import {NotAuthenticated} from 'components/NotAuthenticated/NotAuthenticated';
import {Spinner} from 'shared/components/loading/Spinner/Spinner';

interface IRequireAuthProps {
    scopes?: Scope[];
}

export const RequireAuth = ({
    scopes,
    children,
}: React.PropsWithChildren<IRequireAuthProps>) => {
    const {tokenData, isLoading, isAuthenticated} = useAuth();

    const isCorrectScopes = React.useMemo(() => {
        return isAuthenticated && tokenData && (!scopes || (scopes.every(scope => tokenData.scopes.includes(scope))));
    }, [tokenData, scopes, isAuthenticated]);

    const loadingPageFallback = (
        <div className="p-5 text-center">
            <Spinner/>
        </div>
    );

    if (isLoading) {
        return loadingPageFallback;
    } else if (isCorrectScopes && isAuthenticated) {
        return (
            <React.Suspense fallback={loadingPageFallback}>
                {children}
            </React.Suspense>
        );
    } else {
        return (
            <NotAuthenticated/>
        );
    }
};
