import React from 'react';

import Slick, {Settings} from 'react-slick';

import {TagId} from 'shared/components/tag/TagId/TagId';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface ITagListSliderProps {
    tagIds: string[];
}

const slickSettings: Settings = {
    draggable: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 3,
    infinite: false,
    variableWidth: true,
};

export const TagListSlider = ({tagIds}: ITagListSliderProps) => {

    return (
        <Slick {...slickSettings}>
            {tagIds.map(tagId => (
                <TagId tagId={tagId} key={tagId} size="lg" badge/>
            ))}
        </Slick>
    );
};
