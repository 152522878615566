import React from 'react';

import {usePostFromPathLoadable} from 'modules/post/hooks/usePostFromPathLoadable';

import UpdatePostForm from './UpdatePostForm';
import {LoadingSpinner} from 'shared/components/loading/LoadingSpinner/LoadingSpinner';
import {PageError} from 'shared/components/PageError/PageError';

export const UpdatePost = () => {
    const {post, loading, error} = usePostFromPathLoadable();

    return (
        <div>
            {loading && (
                <LoadingSpinner />
            )}
            {error && (
                <PageError description="Error loading resource">There was an error loading the resource.</PageError>
            )}
            {post && (
                <UpdatePostForm post={post} />
            )}
        </div>
    );
};
