import React from 'react';

import {Control} from 'react-hook-form';
import {Form} from 'react-bootstrap';

import {IProfile} from 'modules/profile/models/IProfile';
import {IProfileUpdate} from 'modules/profile/models/IProfileUpdate';

import {FormCard} from 'components/profile/shared/FormCard/FormCard';
import {TagSelectorController} from 'components/profile/shared/TagSelector/TagSelectorController';

interface IProfileFormTagsProps {
    profile: IProfile;
    control: Control<IProfileUpdate>;
    isSubmitting: boolean;
}

export const ProfileFormTags = ({control, isSubmitting}: IProfileFormTagsProps) => {

    return (
        <FormCard title="Tags">
            <Form.Group>
                <Form.Label>Tags</Form.Label>
                <div className="ProfilePageAbout__Tag-selector">
                    <TagSelectorController
                        name="tag_ids"
                        control={control}
                        xs={12}
                        md={12}
                        lg={6}
                        disabled={isSubmitting}
                    />
                </div>
            </Form.Group>
        </FormCard>
    );
};
