import React from 'react';

import {Link} from 'react-router-dom';

import {ICategory} from 'modules/help/models';

import {IconBin, IconEdit} from 'shared/icons/common';
import {FormatDate} from 'shared/components/date/FormatDate/FormatDate';
import {TableDropdown} from 'shared/components/TableDropdown/TableDropdown';
import {TableDropdownItem} from 'shared/components/TableDropdown/TableDropdownItem';
import {HelpCenterPicture} from 'components/help-center/components/HelpCenterPicture/HelpCenterPicture';

interface ICategoryListRowProps {
    category: ICategory;
    onUpdate: () => void;
    onDelete: () => void;
}

export const CategoryListRow = ({category, onUpdate, onDelete}: ICategoryListRowProps) => {

    return (
        <tr>
            <td className="table__picture-col">
                <HelpCenterPicture category={category} size="sm"/>
            </td>
            <td>
                <Link to={`/help-center/${category.id}`} className="text-dark">
                    {category.name}
                </Link>
            </td>
            <td className="table__truncate-col">
                {category.description}
            </td>
            <td className="table__date-col">
                <FormatDate>{category.created_at}</FormatDate>
            </td>
            <td className="table__options-col">
                <TableDropdown>
                    <TableDropdownItem icon={IconEdit} variant="warning" onClick={onUpdate}>
                        Update Category
                    </TableDropdownItem>
                    <TableDropdownItem icon={IconBin} variant="danger" onClick={onDelete}>
                        Delete Category
                    </TableDropdownItem>
                </TableDropdown>
            </td>
        </tr>
    );
};
