import React from 'react';
import classNames from 'classnames';

import {PostKind} from 'modules/post/models/postKind';

import {ResourceKindControlItem} from 'components/resources/ResourceList/ResourceKindControl/ResourceKindControlItem';

import './style.scss';

interface IResourceKindControlItemProps {
    className?: string;
    value: PostKind | undefined;
    onChange: (value: PostKind | undefined) => void;
    disabled?: boolean;
}

export const ResourceKindControl = ({value, onChange, className, disabled}: IResourceKindControlItemProps) => {
    return (
        <div className={classNames('d-flex align-items-center', className)}>
            <ResourceKindControlItem
                value={undefined}
                currentValue={value}
                onClick={onChange}
                disabled={disabled}
            />
            <ResourceKindControlItem
                value={PostKind.Webinar}
                currentValue={value}
                onClick={onChange}
                disabled={disabled}
            />
            <ResourceKindControlItem
                value={PostKind.Article}
                currentValue={value}
                onClick={onChange}
                disabled={disabled}
            />
            <ResourceKindControlItem
                value={PostKind.Podcast}
                currentValue={value}
                onClick={onChange}
                disabled={disabled}
            />
        </div>
    );
};
