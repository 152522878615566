import React from 'react';

import {Link} from 'react-router-dom';

import {useFileLoadable} from 'modules/file/hooks/useFileLoadable';

import {DeleteIcon, PDFIcon} from 'shared/icons/EditProfile';
import {Spinner} from 'shared/components/loading/Spinner/Spinner';

import './style.scss';

interface IResumeControllerFileProps {
    fileId: string;
    onDelete: () => void;
}

export const ResumeControllerFile = ({fileId, onDelete}: IResumeControllerFileProps) => {
    const {file, loading} = useFileLoadable(fileId);

    return (
        <div className="ResumeController__resume--upload--flex-center">
            <div className="ResumeController__resume--upload--left">
                <PDFIcon/>
            </div>
            <div className="ResumeController__resume--upload--left--center text-left pl-4">
                {loading && (
                    <Spinner size="sm"/>
                )}
                {file && (
                    <div className="m-0 text-left">
                        {file.filename}
                    </div>
                )}
            </div>
            <Link to="#" className="ResumeController__resume--upload--right" onClick={onDelete}>
                <DeleteIcon/>
            </Link>
        </div>
    );
};
