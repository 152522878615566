import React from 'react';

import {FontAwesomeIcon, FontAwesomeIconProps} from '@fortawesome/react-fontawesome';
import {faCaretDown} from '@fortawesome/free-solid-svg-icons';

import './style.scss';

export const DropdownCaret = ({className, ...props}: Pick<FontAwesomeIconProps, 'size' | 'className'>) => {
    return <FontAwesomeIcon className={`DropdownCaret ${className || ''}`} icon={faCaretDown} {...props} />;
};

DropdownCaret.defaultProps = {
    size: 'sm',
};
