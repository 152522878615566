import {jobLookupReadSelector, jobLookupSelector} from 'modules/job/state/job-lookup';
import {useRecoilLoadable} from 'shared/recoil/hooks/useRecoilLoadable';

export const useJobLoadable = (jobId: string) => {
    const {data, loading, error} = useRecoilLoadable({
        state: jobLookupSelector(jobId),
        loadable: jobLookupReadSelector(jobId),
    });
    return {
        job: data,
        loading,
        error,
    };
};
