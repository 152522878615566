import {selectorFamily, atomFamily} from 'recoil';

import {profileLookupSelector} from 'modules/profile/state/profile-lookup';
import {readProfileSummary} from 'modules/profile/api/profile';
import {IProfileSummary} from 'modules/profile/models/IProfileSummary';
import {profileToProfileSummary} from 'modules/profile/utils';
import {guardRecoilDefaultValue} from 'shared/recoil/utils';

export const profileSummaryAtom = atomFamily<IProfileSummary | undefined, string>({
    key: 'profileSummaryAtom',
    default: undefined,
});

export const profileSummarySelector = selectorFamily<IProfileSummary | undefined, string>({
    key: 'profileSummarySelector',
    get: (profileId) => ({get}) => {
        const atomValue = get(profileSummaryAtom(profileId));
        if (atomValue) {
            return atomValue;
        }

        const profileInLookup = get(profileLookupSelector(profileId));
        if (profileInLookup) {
            return profileToProfileSummary(profileInLookup);
        }

        return undefined;
    },
    set: () => ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue) || !newValue) {
            return;
        }
        set(profileSummaryAtom(newValue.id), newValue);
    },
});

export const profileSummaryReadSelector = selectorFamily<IProfileSummary, string>({
    key: 'profileSummaryReadSelector',
    get: (profileId: string) => async ({get}): Promise<IProfileSummary> => {
        const currentValue = get(profileSummarySelector(profileId));
        if (currentValue) {
            return currentValue;
        }
        return await readProfileSummary(profileId);
    },
});

/**
 * The same as profileSummarySelector except it accepts undefined as the profile ID.
 */
export const profileSummaryMaybeSelector = selectorFamily<IProfileSummary | undefined, string | undefined>({
    key: 'profileSummaryMaybeSelector',
    get: (profileId) => async ({get}): Promise<IProfileSummary | undefined> => {
        if (profileId) {
            return get(profileSummaryReadSelector(profileId));
        } else {
            return undefined;
        }
    },
});

export const profileSummaryBatchSelector = selectorFamily<IProfileSummary[], string[]>({
    key: 'profileSummaryBatchSelector',
    get: (profileIds) => ({get}) => {
        const profileSummaries = profileIds
            .map(profileId => get(profileSummaryMaybeSelector(profileId)))
            .filter(profile => !!profile);
        // typescript doesn't understand that we filtered the list above so we must declare its type in the return
        // statement.
        return profileSummaries as IProfileSummary[];
    },
});
