import React from 'react';

import {Button, Table} from 'react-bootstrap';

import {ITag} from 'modules/tag/models';
import {useTitle} from 'shared/hooks/useTitle';
import {useDashboardTitle} from 'shared/hooks/useDashboardTitle';
import {useTagList} from 'modules/tag/hooks/useTagList';

import {EmptyTable} from 'shared/components/EmptyTable/EmptyTable';
import {TagListRow} from 'components/TagList/TagListRow';
import {DeleteTagModal} from 'components/TagList/DeleteTagModal/DeleteTagModal';
import {UpdateTagModal} from 'components/TagList/UpdateTagModal/UpdateTagModal';
import {CreateTagModal} from 'components/TagList/CreateTagModal/CreateTagModal';
import {DashboardHeader} from 'shared/components/app/DashboardHeader/DashboardHeader';

export const TagList = () => {
    useDashboardTitle('Tags');
    useTitle('Tags');

    const [tagToDelete, setTagToDelete] = React.useState<ITag | undefined>(undefined);
    const [tagToUpdate, setTagToUpdate] = React.useState<ITag | undefined>(undefined);
    const [showCreateTagModal, setShowCreateTagModal] = React.useState<boolean>(false);
    const tags = useTagList();

    return (
        <>
            <DashboardHeader>
                <Button
                    type="button"
                    variant="primary"
                    onClick={() => setShowCreateTagModal(true)}
                    className="btnAdminPage"
                >
                    New Tag
                </Button>
            </DashboardHeader>
            <div className="AdminTags">
                {tags.length ? (
                    <Table className="table-fixed">
                        <thead>
                        <tr>
                            <th>Title</th>
                            <th className="table__badge-col">Badge</th>
                            <th className="table__date-col">Created At</th>
                            <th className="table__options-col">Options</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tags.map((tag) => (
                            <TagListRow
                                key={tag.id}
                                tag={tag}
                                handleDelete={() => setTagToDelete(tag)}
                                handleUpdate={() => setTagToUpdate(tag)}
                            />
                        ))}
                        </tbody>
                    </Table>
                ) : (
                    <EmptyTable>No Tags</EmptyTable>
                )}
            </div>
            <DeleteTagModal
                tag={tagToDelete}
                show={!!tagToDelete}
                onHide={() => setTagToDelete(undefined)}
            />
            <UpdateTagModal
                tag={tagToUpdate}
                show={!!tagToUpdate}
                onHide={() => setTagToUpdate(undefined)}
            />
            <CreateTagModal show={showCreateTagModal} onHide={() => setShowCreateTagModal(false)}/>
        </>
    );
};
