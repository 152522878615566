import {selector} from 'recoil';

import {IFile} from 'modules/file/models/IFile';
import {guardRecoilDefaultValue, throwWriteOnlySelectorError} from 'shared/recoil/utils';
import {fileListInsertSelector} from 'modules/file/state/file-list';
import {fileLookupAtom} from 'modules/file/state/file-lookup';

export const fileInsertSelector = selector<IFile>({
    key: 'fileInsertSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue) || !newValue) {
            return;
        }
        set(fileLookupAtom(newValue.id), newValue);
        set(fileListInsertSelector, newValue);
    },
});
