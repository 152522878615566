import React, {FormEvent} from 'react';

import {Button, Modal} from 'react-bootstrap';

import {IIssue} from 'modules/issue/models';
import {toast} from 'shared/utils/toast';
import {toastAxiosError} from 'shared/utils/error';
import {deleteIssue} from 'modules/issue/api';

interface IDeleteIssueModalProps {
    show: boolean;
    onClose: () => void;
    issue?: IIssue;
}

export const DeleteIssueModal = ({show, onClose, issue}: IDeleteIssueModalProps) => {
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        if (!issue || isSubmitting) {
            return;
        }
        setIsSubmitting(true);

        (async () => {
            try {
                await deleteIssue(issue.id);
                // TODO: Update issue list state
                toast.success('Issue Deleted', 'The issue was successfully deleted.');
                onClose();
            } catch (e) {
                toastAxiosError(e, 'Error Deleting Issue');
            } finally {
                setIsSubmitting(false);
            }
        })();
    };

    return (
        <Modal
            show={show}
            onHide={onClose}
            isSubmitting={isSubmitting}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Delete Issue
                </Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit}>
                <Modal.Body>
                    <p>Are you sure?</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" variant="danger" disabled={isSubmitting}>
                        Okay
                    </Button>
                </Modal.Footer>
            </form>
        </Modal>
    );
};
