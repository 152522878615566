import React from 'react';

import {useRecoilValue} from 'recoil';

import {profileSummaryReadSelector} from 'modules/profile/state/profile-summary';

import {ProfilePictureSharedProps} from 'shared/components/user/UserIdProfilePicture/UserIdProfilePicture';
import {ProfilePicture} from 'shared/components/ProfilePicture/ProfilePicture';
import {getFullName} from 'modules/profile/utils';

interface IUserIdProfilePictureImageProps extends ProfilePictureSharedProps {
    id: string;
}

export const UserIdProfilePictureImage = ({id, imgClassName, ...rest}: IUserIdProfilePictureImageProps) => {
    const profileSummary = useRecoilValue(profileSummaryReadSelector(id));
    const fullName = getFullName(profileSummary);

    return (
        <ProfilePicture
            name={fullName}
            id={profileSummary.image_id}
            alt={fullName}
            url={profileSummary.image_url}
            imgClassName={imgClassName}
            {...rest}
        />
    );
};
