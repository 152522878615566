import React from 'react';

import {Control} from 'react-hook-form';
import {Row, Col} from 'react-bootstrap';

import {IProfile} from 'modules/profile/models/IProfile';
import {IProfileUpdate} from 'modules/profile/models/IProfileUpdate';
import {parseInputValueAsInt} from 'shared/utils/form';

import {FormCard} from 'components/profile/shared/FormCard/FormCard';
import {FormController} from 'shared/components/form/FormController/FormController';
import {ServicesController} from 'components/profile/shared/ServicesController/ServicesController';

interface IProfileFormFeesProps {
    profile: IProfile;
    control: Control<IProfileUpdate>;
    isSubmitting: boolean;
}

export const ProfileFormFees = ({control, isSubmitting}: IProfileFormFeesProps) => {

    return (
        <FormCard title="Fees">
            <Row>
                <Col md={6}>
                    <FormController
                        name="service_details.hour_rate"
                        label="Hourly Rate"
                        control={control}
                        disabled={isSubmitting}
                        formatValue={parseInputValueAsInt}
                    />
                </Col>
                <Col md={6}>
                    <FormController
                        name="service_details.day_rate"
                        label="Day Rate"
                        control={control}
                        disabled={isSubmitting}
                        formatValue={parseInputValueAsInt}
                    />
                </Col>
            </Row>
            <ServicesController control={control} name="service_types" disabled={isSubmitting}/>
        </FormCard>
    );
};
