import {atom, selector, selectorFamily} from 'recoil';

import {IPost} from 'modules/post/models/IPost';
import {IPostListQuery, IPostQuery} from 'modules/post/models/IPostListQuery';
import {CursorList} from 'shared/types/cursor-list';
import {readPostList} from 'modules/post/api';
import {comparePostFilters} from '../utils';
import {throwWriteOnlySelectorError, guardRecoilDefaultValue} from 'shared/recoil/utils';

export interface IPostListStateFilters {
    filters: IPostQuery;
    page: number;
    limit: number;

    [key: string]: number | IPostListQuery;
}

interface IPostListState {
    filters: IPostListQuery;
    posts: IPost[];
    cursors: CursorList;
    page: number;
    limit: number;
    more: boolean;
    resetVersion: number;

    [key: string]: number | boolean | IPost[] | CursorList | IPostListQuery;
}

export const postListAtom = atom<IPostListState | undefined>({
    key: 'postListAtom',
    default: undefined,
});

export const resetPostListAtom = atom<number>({
    key: 'resetPostListAtom',
    default: 0,
});

export const postListSelector = selectorFamily<IPostListState | undefined, IPostListStateFilters>({
    key: 'postListSelector',
    get: (filters) => ({get}) => {
        const atomValue = get(postListAtom);
        const resetVersion = get(resetPostListAtom);
        if (
            atomValue &&
            filters.page === atomValue.page &&
            filters.limit === atomValue.limit &&
            comparePostFilters(filters.filters, atomValue.filters) &&
            atomValue.resetVersion === resetVersion
        ) {
            return {...atomValue};
        }
        return undefined;
    },
    set: (_) => ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue) || !newValue) {
            return null;
        }
        set(postListAtom, newValue);
    },
});

export const postListReadSelector = selectorFamily<IPostListState, IPostListStateFilters>({
    key: 'postListReadSelector',
    get: (filters) => async ({get}) => {
        const currentValue = get(postListSelector(filters));
        if (currentValue) {
            return currentValue;
        }

        const atomValue = get(postListAtom);
        const resetVersion = get(resetPostListAtom);

        // load a fresh page from the server
        const result = await readPostList({
            ...filters.filters,
            limit: filters.limit,
            cursor: (filters.page === 0 || !atomValue) ? undefined : atomValue.cursors[filters.page - 1],
        });

        // add the cursor to the list of cursors so that we can paginate backwards
        const cursors = Array.from(atomValue?.cursors ?? []);
        cursors[filters.page] = result.nextCursor;

        return {
            filters: filters.filters,
            posts: result.posts,
            cursors,
            page: filters.page,
            limit: filters.limit,
            more: !!result.nextCursor,
            resetVersion,
        } as IPostListState;
    },
});

export const postListRemoveSelector = selector<IPost>({
    key: 'postListRemoveSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }

        const atomValue = get(postListAtom);
        if (!atomValue) {
            return;
        }

        set(postListAtom, {
            ...atomValue,
            posts: atomValue.posts.filter(post => post.id !== newValue.id),
        });
    },
});

export const postListInsertSelector = selector<IPost>({
    key: 'postListInsertSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }

        const atomValue = get(postListAtom);
        if (!atomValue) {
            return;
        }

        // if the post is present in the store then update it. Otherwise, force a reload.
        const postIndex = atomValue.posts.findIndex((post) => post.id === newValue.id);
        if (postIndex !== -1) {
            const newPosts = Array.from(atomValue.posts);
            newPosts.splice(postIndex, 1, newValue);
            set(postListAtom, {
                ...atomValue,
                posts: newPosts,
            });
        } else {
            set(resetPostListAtom, get(resetPostListAtom) + 1);
        }
    },
});
