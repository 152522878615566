import React from 'react';

import {Table} from 'react-bootstrap';

import {IServiceTask, BackendService} from 'shared/models/systemTask';
import {useDashboardTitle} from 'shared/hooks/useDashboardTitle';
import {useTitle} from 'shared/hooks/useTitle';
import {
    flushMemcache,
    initializeMainMessagingSubscriptions,
    initializeChatMessagingSubscriptions,
    initializeDocumentMessagingSubscriptions,
    refreshHomepageCache,
    touchPosts,
    touchProfiles,
    rebuildPostSearch,
    rebuildProfileSearch,
    touchArticles,
    touchCategories,
    touchFiles,
    touchChats,
    downloadPasswordResetLinks,
    ensureUUIDs,
    resetUserPasswords,
    checkProfileFiles,
    touchEvents,
    initializeHelpMessagingSubscriptions,
} from 'modules/system/api';
import {readSystemBankAccount, initializePaymentMessagingSubscriptions} from 'modules/payment/api';

import {DashboardHeader} from 'shared/components/app/DashboardHeader/DashboardHeader';
import {SystemTaskRow} from 'components/System/SystemTaskRow';
import {SystemTaskModal} from 'components/System/SystemTaskResultModal';

import './style.scss';

const systemTasks: IServiceTask[] = [
    {
        name: 'Flush Memcache',
        action: flushMemcache,
        isSafe: true,
        service: BackendService.Main,
    },
    {
        name: 'Refresh Homepage Cache',
        action: refreshHomepageCache,
        isSafe: true,
        service: BackendService.Main,
    },
    {
        name: 'Initialize Messaging Subscriptions',
        action: initializeMainMessagingSubscriptions,
        isSafe: true,
        service: BackendService.Main,
    },
    {
        name: 'Initialize Messaging Subscriptions',
        action: initializeChatMessagingSubscriptions,
        isSafe: true,
        service: BackendService.Chat,
    },
    {
        name: 'Initialize Messaging Subscriptions',
        action: initializeDocumentMessagingSubscriptions,
        isSafe: true,
        service: BackendService.Document,
    },
    {
        name: 'Initialize Messaging Subscriptions',
        action: initializeHelpMessagingSubscriptions,
        isSafe: true,
        service: BackendService.Help,
    },
    {
        name: 'Initialize Messaging Subscriptions',
        action: initializePaymentMessagingSubscriptions,
        isSafe: true,
        service: BackendService.Payment,
    },
    {
        name: 'Read System Bank Account',
        action: readSystemBankAccount,
        isSafe: true,
        service: BackendService.Payment,
    },
    {
        name: 'Rebuild Post Search',
        action: rebuildPostSearch,
        isSafe: true,
        service: BackendService.Main,
    },
    {
        name: 'Rebuild Profile Search',
        action: rebuildProfileSearch,
        isSafe: true,
        service: BackendService.Main,
    },
    {
        name: 'Check Profile File Permissions',
        action: checkProfileFiles,
        isSafe: true,
        service: BackendService.Main,
    },
    {
        name: 'Touch Events',
        action: touchEvents,
        isSafe: true,
        service: BackendService.Main,
    },
    {
        name: 'Touch Posts',
        action: touchPosts,
        isSafe: false,
        service: BackendService.Main,
    },
    {
        name: 'Touch Profiles',
        action: touchProfiles,
        isSafe: false,
        service: BackendService.Main,
    },
    {
        name: 'Touch Categories',
        action: touchCategories,
        isSafe: false,
        service: BackendService.Help,
    },
    {
        name: 'Touch Articles',
        action: touchArticles,
        isSafe: false,
        service: BackendService.Help,
    },
    {
        name: 'Touch Files',
        action: touchFiles,
        isSafe: false,
        service: BackendService.File,
    },
    {
        name: 'Touch Chats',
        action: touchChats,
        isSafe: false,
        service: BackendService.Chat,
    },
    {
        name: 'Download User Password Reset Links',
        action: downloadPasswordResetLinks,
        isSafe: false,
        service: BackendService.Main,
    },
    {
        name: 'Ensure UUIDs',
        action: ensureUUIDs,
        isSafe: false,
        service: BackendService.User,
    },
    {
        name: 'Reset Passwords',
        action: resetUserPasswords,
        isSafe: false,
        service: BackendService.User,
    },
];

export const System = () => {
    useDashboardTitle('System Tasks');
    useTitle('System Tasks');

    const [selectedTask, setSelectedTask] = React.useState<IServiceTask | undefined>(undefined);

    return (
        <>
            <DashboardHeader/>
            <Table>
                <tbody>
                {systemTasks.map(systemTask => (
                    <SystemTaskRow
                        key={systemTask.name}
                        systemTask={systemTask}
                        onStart={() => setSelectedTask(systemTask)}
                    />
                ))}
                </tbody>
            </Table>
            <SystemTaskModal
                systemTask={selectedTask}
                show={!!selectedTask}
                onHide={() => setSelectedTask(undefined)}
            />
        </>
    );
};
