import React from 'react';

import classNames from 'classnames';

import {getFullName} from 'modules/profile/utils';
import {useProfileSummaryLoadable} from 'modules/profile/hooks/useProfileSummaryLoadable';

import {ProfilePicture} from 'shared/components/ProfilePicture/ProfilePicture';

interface IJobUserSummaryProps {
    userId: string;
    className?: string;
}

export const JobUserSummary = ({userId, className}: IJobUserSummaryProps) => {
    const {profileSummary, loading} = useProfileSummaryLoadable(userId);

    const fullName = React.useMemo(() => {
        if (profileSummary) {
            return getFullName(profileSummary);
        } else if (loading) {
            return '';
        } else {
            return 'Anonymous User';
        }
    }, [profileSummary, loading]);

    return (
        <div className={classNames('d-flex align-items-center', className)}>
            <ProfilePicture
                id={profileSummary?.image_id}
                round
                size="xs"
                table
                alt={fullName}
            />
            <div className="ml-2">
                {fullName}
            </div>
        </div>
    );
};
