import React from 'react';

import {ErrorBoundary} from 'react-error-boundary';

import {UserIdProfilePictureImage} from 'shared/components/user/UserIdProfilePicture/UserIdProfilePictureImage';
import {IProfilePictureProps, ProfilePicture} from 'shared/components/ProfilePicture/ProfilePicture';

export type ProfilePictureSharedProps = Omit<IProfilePictureProps, 'id' | 'url' | 'alt' | 'name'>;

interface IUserIdProfilePictureProps extends ProfilePictureSharedProps {
    id: string;
}

export const UserIdProfilePicture = ({id, imgClassName, ...rest}: IUserIdProfilePictureProps) => (
    <ErrorBoundary
        fallback={(
            <span className="text-danger">User Not Found!</span>
        )}
    >
        <React.Suspense
            fallback={(
                <ProfilePicture alt="loading..." {...rest} />
            )}
        >
            <UserIdProfilePictureImage id={id} imgClassName={imgClassName} {...rest} />
        </React.Suspense>
    </ErrorBoundary>
);
