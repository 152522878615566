import {z} from 'zod';

import {IssueStatus} from 'modules/issue/models';

export interface IIssueForm {
    status: IssueStatus;
    title: string;
    description: string;
    image_id: string;
}

export const createIssueForm = z.object({
    status: z.nativeEnum(IssueStatus),
    title: z.string().min(1).max(300),
    description: z.string().min(1),
    image_id: z.string().optional().nullable(),
});
