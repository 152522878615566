import React from 'react';

import {Auth0Provider} from '@auth0/auth0-react';

import {Config} from 'config';

import {AuthProviderContent} from 'shared/auth/components/AuthProvider/AuthProviderContent';
import {FileTokenRefresher} from 'modules/file/components/FileTokenRefresher';

interface IAuthProviderProps {
    children?: React.ReactNode;
}

export function AuthProvider({children}: IAuthProviderProps) {

    return (
        <Auth0Provider
            domain={Config.auth0Domain}
            clientId={Config.auth0ClientId}
            redirectUri={window.location.origin}
            audience={Config.auth0Audience}
        >
            <AuthProviderContent>
                <FileTokenRefresher/>
                {children}
            </AuthProviderContent>
        </Auth0Provider>
    );
}
