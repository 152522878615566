import React from 'react';

import {Link} from 'react-router-dom';

import {IJob} from 'modules/job/models';
import {jobLocationToFriendlyName} from 'modules/job/utils';
import {toast} from 'shared/utils/toast';

import {TableDropdown} from 'shared/components/TableDropdown/TableDropdown';
import {TableDropdownItem} from 'shared/components/TableDropdown/TableDropdownItem';
import {JobWorkTypeBadge} from 'components/job/shared/JobWorkTypeBadge/JobWorkTypeBadge';
import {JobVisibilityBadge} from 'components/job/shared/JobVisibilityBadge/JobVisibilityBadge';
import {JobProfileVisibilityBadge} from 'components/job/shared/JobProfileVisibilityBadge/JobProfileVisibilityBadge';
import {JobStatusBadge} from 'components/job/shared/JobStatusBadge/JobStatusBadge';
import {UserIdName} from 'shared/components/user/UserIdName/UserIdName';
import {UserIdProfilePicture} from 'shared/components/user/UserIdProfilePicture/UserIdProfilePicture';
import {IconBin, IconEdit} from 'shared/icons/common';

interface IJobListRowProps {
    job: IJob;
    onCancel: () => void;
}

export enum JobStatus {
    Open = 'OPEN',
    Closed = 'CLOSED',
}

export const JobListRow = ({job, onCancel}: IJobListRowProps) => {
    const viewJobURL = `/jobs/${job.id}`;

    return (
        <tr>
            <td className="">
                <Link to={viewJobURL} className="table__link">
                    <UserIdProfilePicture id={job.user_id} imgClassName="article" round size="xs" table/>
                </Link>
            </td>
            <td>
                <Link to={viewJobURL} className="table__link">
                    {job.name}
                </Link>
            </td>
            <td className="table__date-col-lg text-center">
                <UserIdName id={job.user_id}/>
            </td>
            <td className="table__date-col-lg text-center">
                <JobWorkTypeBadge workType={job.work_type}/>
            </td>
            <td className="table__badge-col text-center">
                <JobVisibilityBadge isVisible={job.is_published}/>
            </td>
            <td className="table__badge-col text-center">
                <JobProfileVisibilityBadge isVisible={job.is_profile_visible}/>
            </td>
            <td className="table__badge-col text-center">
                <JobStatusBadge status={job.status}/>
            </td>
            <td className="table__details-col-lg">
                {jobLocationToFriendlyName(job.location)}
            </td>
            <td className="table__options-col">
                <TableDropdown>
                    <TableDropdownItem icon={IconEdit} variant="success" to={viewJobURL}>
                        View Job
                    </TableDropdownItem>
                    <TableDropdownItem
                        icon={IconBin}
                        variant="danger"
                        onClick={() => toast.info('Not Supported', 'Not supported yet')}
                    >
                        Delete Job
                    </TableDropdownItem>
                    <TableDropdownItem
                        icon={IconBin}
                        variant="danger"
                        onClick={onCancel}
                        disabled={job.status !== JobStatus.Open}
                    >
                        Close Job
                    </TableDropdownItem>
                </TableDropdown>
            </td>
        </tr>
    );
};
