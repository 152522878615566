import React from 'react';

import {Card} from 'react-bootstrap';

import './style.scss';

interface IFormCardProps {
    title: string;
    description?: React.ReactNode;
    children: React.ReactNode;
}

export const FormCard = ({title, children, description}: IFormCardProps) => {
    return (
        <>
            <div className="FormCard__title">{title}</div>
            {description && (
                <div className="FormCard__description">{description}</div>
            )}
            <Card>
                <Card.Body>
                    {children}
                </Card.Body>
            </Card>
        </>
    );
};
