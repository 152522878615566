import React from 'react';

import {Link} from 'react-router-dom';

import {IArticle} from 'modules/help/models';

import {FormatDate} from 'shared/components/date/FormatDate/FormatDate';
import {TableDropdown} from 'shared/components/TableDropdown/TableDropdown';
import {TableDropdownItem} from 'shared/components/TableDropdown/TableDropdownItem';
import {IconBin, IconEdit} from 'shared/icons/common';

interface IArticleRowProps {
    article: IArticle;
    onDelete: () => void;
}

export const ArticleRow = ({article, onDelete}: IArticleRowProps) => {
    const updateArticleURL = `/help-center/${article.category_id}/articles/${article.id}`;

    return (
        <tr>
            <td>
                <Link to={updateArticleURL} className="text-dark">
                    {article.name}
                </Link>
            </td>
            <td className="table__truncate-col">
                {article.description}
            </td>
            <td className="table__date-col">
                <FormatDate>{article.created_at}</FormatDate>
            </td>
            <td className="table__options-col">
                <TableDropdown>
                    <TableDropdownItem icon={IconEdit} variant="warning" to={updateArticleURL}>
                        Update Article
                    </TableDropdownItem>
                    <TableDropdownItem icon={IconBin} variant="danger" onClick={onDelete}>
                        Delete Article
                    </TableDropdownItem>
                </TableDropdown>
            </td>
        </tr>
    );
};
