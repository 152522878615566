import React, {FormEvent} from 'react';

import {Button, Form, Modal} from 'react-bootstrap';
import {useSetRecoilState} from 'recoil';

import {toast} from 'shared/utils/toast';
import {toastAxiosError} from 'shared/utils/error';
import {ITag} from 'modules/tag/models';
import {tagRemoveSelector} from 'modules/tag/state/tag-remove';
import {deleteTag} from 'modules/tag/api';

import {Tag} from 'shared/components/tag/Tag/Tag';

interface IDeleteTagModalProps {
    show: boolean;
    onHide: () => void;
    tag?: ITag;
}

export const DeleteTagModal = ({show, onHide, tag}: IDeleteTagModalProps) => {
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

    const removeTag = useSetRecoilState(tagRemoveSelector);

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault();

        if (!tag) {
            return;
        }

        setIsSubmitting(true);
        (async () => {
            try {
                await deleteTag(tag.id);
                removeTag(tag.id);
                onHide();
                toast.success('Delete Tag', 'Tag deleted successfully.');
            } catch (e) {
                toastAxiosError(e, 'Error Deleting Tag');
            } finally {
                setIsSubmitting(false);
            }
        })();
    };

    return (
        <Modal show={show} onHide={onHide} isSubmitting={isSubmitting} centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    Are you sure?
                </Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit}>
                <Modal.Body>
                    {tag && (
                        <Tag color={tag.color} title={tag.title} className="d-inline-block" size="sm"/>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="danger"
                        type="submit"
                        disabled={isSubmitting}
                    >
                        {isSubmitting ? 'Deleting Tag...' : 'Okay'}
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};
