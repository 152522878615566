import {useRecoilLoadable} from 'shared/recoil/hooks/useRecoilLoadable';
import {
    jobListReadSelector,
    jobListSelector,
    IJobListStateFilters,
} from 'modules/job/state/job-list';

export const useJobListLoadable = (filters: IJobListStateFilters) => {
    const {data, loading, error} = useRecoilLoadable({
        state: jobListSelector(filters),
        loadable: jobListReadSelector(filters),
    });

    return {
        jobList: data,
        loading,
        error,
    };
};
