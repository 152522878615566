import React from 'react';

import {Button} from 'react-bootstrap';
import {ArrayPath, Control, FieldArray, FieldValues, Path, useFieldArray} from 'react-hook-form';

import {SocialLinksControllerItem} from 'components/profile/shared/SocialLinksController/SocialLinksControllerItem';

interface ISocialLinksControllerProps<FormType> {
    name: ArrayPath<FormType>;
    disabled?: boolean;
    control: Control<FormType>;
}

export const SocialLinksController = <FormType extends FieldValues>({
    disabled,
    name,
    control,
}: ISocialLinksControllerProps<FormType>) => {
    const {fields, append, remove} = useFieldArray({
        control,
        name,
    });

    const onAdd = () => {
        append({
            type: undefined,
            url: '',
        } as FieldArray<FormType>);
    };

    return (
        <>
            <Button type="button" variant="" className="btnAddOne" onClick={onAdd}>
                +Add
            </Button>

            {fields.map((field, index) => (
                <SocialLinksControllerItem
                    control={control}
                    key={field.id}
                    name={`${name}.${index}` as Path<FormType>}
                    index={index}
                    onDelete={() => remove(index)}
                    disabled={disabled}
                />
            ))}
        </>
    );
};
