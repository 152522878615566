import {atom, selector, selectorFamily} from 'recoil';

import {guardRecoilDefaultValue, throwWriteOnlySelectorError} from 'shared/recoil/utils';
import {IArticle} from '../models';
import {readArticleList} from '../api';

export interface IArticleListStateFilters {
    categoryId?: string;
    isPublished?: boolean;
    isFeatured?: boolean;

    [key: string]: string | undefined | boolean;
}

interface IArticleListState {
    filters: IArticleListStateFilters;
    articles: IArticle[];
    resetVersion: number;
}

export const articleListResetAtom = atom<number>({
    key: 'articleListResetAtom',
    default: 1,
});

export const articleListAtom = atom<IArticleListState | undefined>({
    key: 'articleListAtom',
    default: undefined,
});

export const articleListSelector = selectorFamily<IArticleListState | undefined, IArticleListStateFilters>({
    key: 'articleListSelector',
    get: (filters) => ({get}) => {
        const atomValue = get(articleListAtom);
        const resetVersion = get(articleListResetAtom);
        if (
            atomValue &&
            atomValue.resetVersion === resetVersion &&
            JSON.stringify(filters) === JSON.stringify(atomValue.filters)
        ) {
            return atomValue;
        }
        return undefined;
    },
    set: () => ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue) || !newValue) {
            return;
        }
        set(articleListAtom, newValue);
    },
});

export const articleListReadSelector = selectorFamily<IArticleListState, IArticleListStateFilters>({
    key: 'articleListReadSelector',
    get: (filters) => async ({get}): Promise<IArticleListState> => {
        const currentValue = get(articleListSelector(filters));
        if (currentValue) {
            return currentValue;
        }
        const resetVersion = get(articleListResetAtom);
        const {articles} = await readArticleList({
            category_id: filters.categoryId,
            is_featured: filters.isFeatured,
            is_published: filters.isPublished,
            limit: 1000,
        });
        return {
            filters,
            articles,
            resetVersion,
        };
    },
});

export const articleListInsertSelector = selector<IArticle>({
    key: 'articleListInsertSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        const resetVersion = get(articleListResetAtom);
        set(articleListResetAtom, resetVersion + 1);
    },
});

export const articleListRemoveSelector = selector<string>({
    key: 'articleListRemoveSelector',
    get: throwWriteOnlySelectorError,
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        const resetVersion = get(articleListResetAtom);
        set(articleListResetAtom, resetVersion + 1);
    },
});
