import React from 'react';

import {Route, Routes, Outlet} from 'react-router-dom';

import {Dashboard} from 'shared/components/app/Dashboard';
import {NotFound} from 'components/NotFound/NotFound';
import {ProfileList} from 'components/profile/ProfileList/ProfileList';
import {TagList} from 'components/TagList/TagList';
import {UpdateArticle} from 'components/help-center/UpdateArticle/UpdateArticle';
import {CreateArticle} from 'components/help-center/CreateArticle/CreateArticle';
import {CategoryPage} from 'components/help-center/CategoryPage/CategoryPage';
import {CategoryList} from 'components/help-center/CategoryList/CategoryList';
import {IssueList} from 'components/issues/IssueList/IssueList';
import {ViewIssue} from 'components/issues/ViewIssue/ViewIssue';
import {AppWrapper} from 'AppWrapper';
import {ResourceList} from 'components/resources/ResourceList/ResourceList';
import {System} from 'components/System/System';
import {ViewProfile} from 'components/profile/ViewProfile/ViewProfile';
import {JobList} from 'components/job/JobList/JobList';
import {AdminDashboard} from 'components/AdminDashboard/AdminDashboard';
import {UpdatePost} from 'components/resources/UpdatePost/UpdatePost';

export const App = () => {
    return (
        <AppWrapper>
            <Routes>
                <Route
                    element={
                        <Dashboard>
                            <Outlet />
                        </Dashboard>
                    }
                >
                    <Route path="/" element={<AdminDashboard />} />
                    <Route path="/profiles/:profileId" element={<ViewProfile />} />
                    <Route path="/profiles" element={<ProfileList />} />
                    <Route path="/tags" element={<TagList />} />
                    <Route path="/jobs" element={<JobList />} />
                    <Route path="/jobs/:jobId" element={<JobList />} />
                    <Route path="help-center/:categoryId/articles/:articleId" element={<UpdateArticle />} />
                    <Route path="help-center/:categoryId/articles/add" element={<CreateArticle />} />
                    <Route path="help-center/:categoryId" element={<CategoryPage />} />
                    <Route path="help-center" element={<CategoryList />} />
                    <Route path="issues/:issueId" element={<ViewIssue />} />
                    <Route path="issues" element={<IssueList />} />
                    <Route path="resources/:postId/update" element={<UpdatePost />} />
                    <Route path="resources" element={<ResourceList />} />
                    <Route path="system" element={<System />} />
                    <Route path="*" element={<NotFound />} />
                </Route>
            </Routes>
        </AppWrapper>
    );
};
