import React from 'react';

import {ToastContainer as ToastifyToastContainer} from 'react-toastify';

import './style.scss';

export const ToastContainer = () => (
    <ToastifyToastContainer
        position="bottom-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
    />
);
