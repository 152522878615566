import React from 'react';

import {Button, Card, Form} from 'react-bootstrap';
import {useFieldArray, useForm} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';
import {useSetRecoilState} from 'recoil';
import {useNavigate, Link} from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronLeft} from '@fortawesome/free-solid-svg-icons';

import {useDashboardTitle} from 'shared/hooks/useDashboardTitle';
import {useTitle} from 'shared/hooks/useTitle';
import {updateArticle} from 'modules/help/api';
import {toastAxiosError} from 'shared/utils/error';
import {articleListInsertSelector} from 'modules/help/state/article-list';
import {toast} from 'shared/utils/toast';
import {useArticleFromPath} from 'modules/help/hooks/useArticleFromPath';
import {articleForm, IArticleForm} from 'modules/help/forms';

import {InputController} from 'shared/components/form/InputController/InputController';
import {UpdateArticleSection} from 'components/help-center/UpdateArticle/UpdateArticleSection';
import {DashboardHeader} from 'shared/components/app/DashboardHeader/DashboardHeader';

import './style.scss';

export const UpdateArticle = () => {
    useTitle('Add Article');
    useDashboardTitle('Add Article');

    const {category, article} = useArticleFromPath();

    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
    const insertArticle = useSetRecoilState(articleListInsertSelector);

    const {handleSubmit, register, control} = useForm<IArticleForm>({
        resolver: zodResolver(articleForm),
        defaultValues: {
            ...article,
        },
    });

    const {fields, append, remove} = useFieldArray({
        control,
        name: 'sections',
    });

    const addArticle = () => {
        append({
            name: '',
            description: '',
            file_id: undefined,
        });
    };

    const [activeAccordion, setActiveAccordion] = React.useState<number>(0);

    React.useEffect(() => {
        setActiveAccordion(fields ? fields.length - 1 : 0);
    }, [fields]);

    const onSubmit = handleSubmit((formData) => {
        setIsSubmitting(true);
        (async () => {
            try {
                const updatedArticle = await updateArticle(article.id, {
                    ...formData,
                    // is_published: isPublished === 'true',
                    // is_featured: isFeatured === 'true',
                });
                insertArticle(updatedArticle);
                toast.success('Create Article', 'Article updated successfully.');
                navigate(`/help-center/${category.id}`);
            } catch (e) {
                toastAxiosError(e, 'Error updating article');
            } finally {
                setIsSubmitting(false);
            }
        })();
    });

    return (
        <>
            <DashboardHeader>
                <Button as={Link} to={`/help-center/${category.id}`} variant="outline-secondary mr-3">
                    <FontAwesomeIcon icon={faChevronLeft} className="mr-2"/>
                    Back
                </Button>
            </DashboardHeader>
            <Form className="AdminAddArticle" onSubmit={onSubmit}>
                <Card className="AdminAddArticle__card">
                    <Card.Body className="AdminAddArticle__body">
                        <Form.Group className="mb-3 AdminAddArticle__form-group" controlId="Article-Title">
                            <Form.Label className="AdminAddArticle__form-title">
                                Article Title
                            </Form.Label>
                            <InputController
                                className="AdminAddArticle__form-input"
                                placeholder="Title of Article"
                                control={control}
                                name="name"
                                disabled={isSubmitting}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3 AdminAddArticle__form-group" controlId="Article-Description">
                            <Form.Label className="AdminAddArticle__form-title">
                                Article Description
                            </Form.Label>
                            <InputController
                                className="AdminAddArticle__form-input"
                                placeholder="Description of Article"
                                control={control}
                                name="description"
                                asType="textarea"
                                disabled={isSubmitting}
                            />
                        </Form.Group>
                        <Form.Check
                            id="CreateArticle.isPublished"
                            type="checkbox"
                            {...register('is_published')}
                            label="Is Published"
                            // value="true"
                            disabled={isSubmitting}
                        />
                        <Form.Check
                            id="CreateArticle.isFeatured"
                            type="checkbox"
                            {...register('is_featured')}
                            label="Is Featured"
                            // value="true"
                            disabled={isSubmitting}
                        />
                    </Card.Body>
                </Card>
                {fields && fields.map((field, index: number) => (
                    <UpdateArticleSection
                        key={field.id}
                        disabled={isSubmitting}
                        index={index}
                        control={control}
                        setActiveAccordion={setActiveAccordion}
                        activeAccordion={activeAccordion}
                        name={`sections.${index}`}
                        onDelete={remove}
                    />
                ))}
                <Form.Group className="text-right mt-5">
                    <Button className="btn-featured-red mr-2" onClick={addArticle} disabled={isSubmitting}>
                        Add Section
                    </Button>
                    <Button type="submit" className="btn-featured-blue" disabled={isSubmitting}>
                        Save
                    </Button>
                </Form.Group>
            </Form>
        </>
    );
};
