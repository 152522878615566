import React from 'react';

import {FieldValues, UseControllerProps} from 'react-hook-form';

import {SocialProvider} from 'modules/profile/models/socialProvider';

import {InputController} from 'shared/components/form/InputController/InputController';

interface ISocialProviderSelectProps<FormType> extends UseControllerProps<FormType> {
    disabled?: boolean;
}

export const SocialProviderSelect = <FormType extends FieldValues>({
    disabled,
    name,
    control,
    defaultValue,
}: ISocialProviderSelectProps<FormType>) => {
    return (
        <InputController
            custom
            control={control}
            asType="select"
            name={name}
            disabled={disabled}
            defaultValue={defaultValue}
            className="Input__box-shodow--none Inputfield"
        >
            <option value=""> - Social Media Provider -</option>
            {Object.keys(SocialProvider).map(key => (
                <option value={SocialProvider[key as keyof typeof SocialProvider]} key={key}>{key}</option>
            ))}
        </InputController>
    );
};
