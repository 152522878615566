import React from 'react';

import {Button} from 'react-bootstrap';
import {Link} from 'react-router-dom';

import {IPostListQuery, IPostQuery} from 'modules/post/models/IPostListQuery';
import {PostOrder} from 'modules/post/models/postOrder';
import {useTitle} from 'shared/hooks/useTitle';
import {useDashboardTitle} from 'shared/hooks/useDashboardTitle';
import {useGuaranteedAuth} from 'shared/auth/hooks/useAuth';
import {usePostListLoadable} from 'modules/post/hooks/usePolListLoadable';

import {DashboardHeader} from 'shared/components/app/DashboardHeader/DashboardHeader';
import {ResourceListHeader} from 'components/resources/ResourceList/ResourceListHeader/ResourceListHeader';
import {Spinner} from 'shared/components/loading/Spinner/Spinner';
import {ResourcesListError} from 'components/resources/ResourceList/ResourceListError/ResourcesPageError';
import {ResourceListTable} from 'components/resources/ResourceList/ResourceListTable';
import {EmptyTable} from 'shared/components/EmptyTable/EmptyTable';

import './style.scss';

export const ResourceList = () => {
    useDashboardTitle('Resources');
    useTitle('Resources');

    const {tokenData} = useGuaranteedAuth();

    const [page, setPage] = React.useState<number>(0);
    const [filters, setFilters] = React.useState<IPostListQuery>({
        isPublished: true,
        orderBy: PostOrder.CreatedAt,
        kind: undefined,
    });
    const {postList, loading, error} = usePostListLoadable({
        page,
        limit: 20,
        filters,
    });

    const onFiltersChange = (filters: IPostQuery) => {
        setPage(0);
        setFilters(filters);
    };

    return (
        <>
            <DashboardHeader
                description="To create a post on behalf of another user, select a user on the Profile Page and click the 'Create Post' button."
            >
                <Button
                    as={Link}
                    to={`${tokenData?.id}/posts/add`}
                    type="button"
                    variant="primary"
                    className="btnAdminPage"
                >
                    New Post
                </Button>
            </DashboardHeader>
            <ResourceListHeader
                onChange={onFiltersChange}
                value={filters}
                disabled={loading}
            />
            {loading && (
                <div className="p-3 text-center">
                    <Spinner/>
                </div>
            )}
            {error && (
                <ResourcesListError/>
            )}
            {postList && !!postList.posts.length && (
                <ResourceListTable
                    posts={postList.posts}
                    page={page}
                    onPageChange={setPage}
                    more={postList.more}
                />
            )}
            {postList && !postList.posts.length && (
                <EmptyTable>
                    {(!!filters.searchTerm?.length || !!filters.kind) ? (
                        'No resources match the filters.'
                    ) : (
                        'There are no resources.'
                    )}
                </EmptyTable>
            )}
        </>
    );
};
