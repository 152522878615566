import React from 'react';

import {Button, Badge} from 'react-bootstrap';

import {IServiceTask} from 'shared/models/systemTask';
import {ServiceBadge} from 'components/System/ServiceBadge';

interface ISystemTaskRowProps {
    systemTask: IServiceTask;
    onStart: () => void;
}

export const SystemTaskRow = ({systemTask, onStart}: ISystemTaskRowProps) => {

    return (
        <tr>
            <td className="table__badge-col">
                <ServiceBadge service={systemTask.service}/>
            </td>
            <td>
                {systemTask.name}
                {systemTask.isSafe ? (
                    <Badge className="ml-2" variant="success">Safe to Run</Badge>
                ) : (
                    <Badge className="ml-2" variant="danger">Danger</Badge>
                )}
            </td>
            <td className="System__button-col">
                <Button
                    variant="primary"
                    onClick={onStart}
                    className="d-flex align-items-center w-100 justify-content-center"
                >
                    Run
                </Button>
            </td>
        </tr>
    );
};
