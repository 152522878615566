import React from 'react';

interface IDashboardHeaderProps {
    children?: React.ReactNode;
    title?: string;
    description?: string;
}

export const DashboardHeader = ({children, title, description}: IDashboardHeaderProps) => {
    return (
        <>
            <div className="d-flex align-items-center mb-4">
                {description && (
                    <p className="mr-auto mb-0">{description}</p>
                )}
                {children}
            </div>
            <h3 className="mb-4">{title}</h3>
        </>
    );
};
