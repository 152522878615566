import axios from 'axios';

import {IReadTagsRequest, IReadTagsResponse, ITag, ITagCreate, ITagUpdate} from 'modules/tag/models';
import {getAccessTokenSilently} from 'shared/utils/token';
import {Config} from 'config';
import {getAuthHeaders} from 'shared/utils/auth';

const getURLForTagAPI = (path: string) => {
    return `${Config.mainServiceURL}/tags${path}`;
};

export const readTagList = async ({cursor, limit}: IReadTagsRequest = {}): Promise<IReadTagsResponse> => {
    const response = await axios.get(getURLForTagAPI('/'), {
        params: {
            cursor,
            limit,
        },
    });
    return response.data;
};

export const updateTag = async (tagId: string, tagUpdate: ITagUpdate): Promise<ITag> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.patch(getURLForTagAPI(`/${tagId}`), tagUpdate, {
        headers: getAuthHeaders(accessToken),
    });
    return response.data as ITag;
};

export const createTag = async (tagCreate: ITagCreate): Promise<ITag> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(getURLForTagAPI('/'), tagCreate, {
        headers: getAuthHeaders(accessToken),
    });
    return response.data as ITag;
};

export const deleteTag = async (tagId: string) => {
    const accessToken = await getAccessTokenSilently();
    await axios.delete(getURLForTagAPI(`/${tagId}`), {
        headers: getAuthHeaders(accessToken),
    });
};
