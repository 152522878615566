import {useRecoilLoadable} from 'shared/recoil/hooks/useRecoilLoadable';
import {subscriptionAtom, subscriptionReadSelector} from '../state/subscription';

export const useSubscriptionLoadable = (userId: string) => {
    const {data, loading, error} = useRecoilLoadable({
        state: subscriptionAtom(userId),
        loadable: subscriptionReadSelector(userId),
    });
    return {
        subscription: data,
        loading,
        error,
    };
};
