import React from 'react';

import {Button, Table} from 'react-bootstrap';

import {IIssue} from 'modules/issue/models';
import {useTitle} from 'shared/hooks/useTitle';
import {useDashboardTitle} from 'shared/hooks/useDashboardTitle';
import {useIssueListLoadable} from 'modules/issue/hooks/useIssueListLoadable';

import {EmptyTable} from 'shared/components/EmptyTable/EmptyTable';
import {DashboardHeader} from 'shared/components/app/DashboardHeader/DashboardHeader';
import {IssueListRow} from 'components/issues/IssueList/IssueListRow';
import {DeleteIssueModal} from 'components/issues/shared/DeleteIssueModal/DeleteIssueModal';
import {CreateIssueModal} from 'components/issues/shared/CreateIssueModal/CreateIssueModal';
import {UpdateIssueModal} from 'components/issues/shared/UpdateIssueModal/UpdateIssueModal';
import {LoadingSpinner} from 'shared/components/loading/LoadingSpinner/LoadingSpinner';
import {PageError} from 'shared/components/PageError/PageError';

export const IssueList = () => {
    const [issueToDelete, setIssueToDelete] = React.useState<IIssue | undefined>(undefined);
    const [issueToUpdate, setIssueToUpdate] = React.useState<IIssue | undefined>(undefined);
    const [showCreateModal, setShowCreateModal] = React.useState<boolean>(false);

    const {issueList, loading, error} = useIssueListLoadable({
        page: 0,
        limit: 1000,
    });

    useDashboardTitle('Issues');
    useTitle('Issues');

    return (
        <>
            <DashboardHeader>
                <Button type="button" className="btn-featured-blue" onClick={() => setShowCreateModal(true)}>
                    New issue
                </Button>
            </DashboardHeader>
            {loading && (
                <LoadingSpinner/>
            )}
            {error && (
                <PageError description="Error loading issues"/>
            )}
            {issueList && !issueList.issues.length && (
                <div className="AdminIssues">
                    <EmptyTable>There are no issues</EmptyTable>
                </div>
            )}
            {issueList && !!issueList.issues.length && (
                <div className="AdminIssues">
                    <Table className="table-fixed">
                        <thead>
                        <tr>
                            <th className="table__title">Title</th>
                            <th className="table__details-col-lg">Description</th>
                            <th className="table__date-col">Created At</th>
                            <th className="table__badge-col text-center">Status</th>
                            <th className="table__options-col">Options</th>
                        </tr>
                        </thead>
                        <tbody>
                        {issueList.issues.map(issue => (
                            <IssueListRow
                                key={issue.id}
                                issue={issue}
                                onDelete={() => setIssueToDelete(issue)}
                                onUpdate={() => setIssueToUpdate(issue)}
                            />
                        ))}
                        </tbody>
                    </Table>
                </div>
            )}
            <CreateIssueModal onHide={() => setShowCreateModal(false)} show={showCreateModal}/>
            <UpdateIssueModal
                onHide={() => setIssueToUpdate(undefined)}
                show={!!issueToUpdate}
                issue={issueToUpdate}
            />
            <DeleteIssueModal
                issue={issueToDelete}
                show={!!issueToDelete}
                onClose={() => setIssueToDelete(undefined)}
            />
        </>
    );
};
