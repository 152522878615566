import axios from 'axios';

import {Config} from 'config';
import {
    IArticle,
    IArticleCreate,
    IArticleListQuery,
    IArticleListResult,
    IArticleUpdate,
    ICategory,
    ICategoryCreate,
    ICategoryListQuery,
    ICategoryListResult,
    ICategoryUpdate,
    IHelpSearchResult,
    IReadHelpSearchProps,
} from '../models';
import {getAccessTokenMaybe} from 'shared/utils/token';
import {getAuthHeaders} from 'shared/utils/auth';

export const getURLForHelpService = (path: string): string => {
    return `${Config.helpServiceURL}${path}`;
};

export const createCategory = async (categoryCreate: ICategoryCreate): Promise<ICategory> => {
    const accessToken = await getAccessTokenMaybe();
    const response = await axios.post(getURLForHelpService('/categories/'), categoryCreate, {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const updateCategory = async (categoryId: string, categoryUpdate: ICategoryUpdate): Promise<ICategory> => {
    const accessToken = await getAccessTokenMaybe();
    const response = await axios.patch(getURLForHelpService(`/categories/${categoryId}`), categoryUpdate, {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const deleteCategory = async (categoryId: string) => {
    const accessToken = await getAccessTokenMaybe();
    await axios.delete(getURLForHelpService(`/categories/${categoryId}`), {
        headers: getAuthHeaders(accessToken),
    });
};

export const deleteArticle = async (articleId: string) => {
    const accessToken = await getAccessTokenMaybe();
    await axios.delete(getURLForHelpService(`/articles/${articleId}`), {
        headers: getAuthHeaders(accessToken),
    });
};

export const createArticle = async (articleCreate: IArticleCreate): Promise<IArticle> => {
    const accessToken = await getAccessTokenMaybe();
    const response = await axios.post(getURLForHelpService('/articles/'), articleCreate, {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const updateArticle = async (articleId: string, articleUpdate: IArticleUpdate): Promise<IArticle> => {
    const accessToken = await getAccessTokenMaybe();
    const response = await axios.patch(getURLForHelpService(`/articles/${articleId}`), articleUpdate, {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const readCategoryList = async (categoryListQuery: ICategoryListQuery): Promise<ICategoryListResult> => {
    const accessToken = await getAccessTokenMaybe();
    const response = await axios.get(getURLForHelpService('/categories/'), {
        params: categoryListQuery,
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const readCategory = async (categoryId: string): Promise<ICategory> => {
    const accessToken = await getAccessTokenMaybe();
    const response = await axios.get(getURLForHelpService(`/categories/${categoryId}`), {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const readArticle = async (articleId: string): Promise<IArticle> => {
    const accessToken = await getAccessTokenMaybe();
    const response = await axios.get(getURLForHelpService(`/articles/${articleId}`), {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const readArticleList = async (articleListQuery: IArticleListQuery): Promise<IArticleListResult> => {
    const accessToken = await getAccessTokenMaybe();
    const response = await axios.get(getURLForHelpService('/articles/'), {
        params: articleListQuery,
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const readHelpSearch = async ({
    cancelToken,
    ...helpSearchQuery
}: IReadHelpSearchProps): Promise<IHelpSearchResult> => {
    const accessToken = await getAccessTokenMaybe();
    const response = await axios.get(getURLForHelpService('/search/'), {
        params: helpSearchQuery,
        headers: getAuthHeaders(accessToken),
        cancelToken,
    });
    return response.data;
};
