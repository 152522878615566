import React from 'react';

import {Controller, FieldValues, UseControllerProps} from 'react-hook-form';

import {FileDropzoneControllerContent} from './FileDropzoneControllerContent';

import './style.scss';

interface IFileDropzoneControllerProps<FormType> extends UseControllerProps<FormType> {
    disabled?: boolean;
    userId: string;
    folder: string;
}

export const FileDropzoneController = <FormType extends FieldValues>({
    control,
    disabled,
    name,
    userId,
    folder,
}: React.PropsWithChildren<IFileDropzoneControllerProps<FormType>>) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({field: {onChange, value}}) => (
                <FileDropzoneControllerContent
                    userId={userId}
                    onChange={onChange}
                    value={value}
                    disabled={disabled}
                    folder={folder}
                />
            )}
        />
    );
};

