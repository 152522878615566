import React from 'react';

import {Alert} from 'react-bootstrap';

import {FormCard} from 'components/profile/shared/FormCard/FormCard';

export const BillingSystemUserWarning = () => {
    return (
        <FormCard title="Billing">
            <Alert variant="warning" className="mb-0">
                <strong>Disabled:</strong> System users do not support billing.
            </Alert>
        </FormCard>
    );
};
