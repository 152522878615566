import React from 'react';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCalendar} from '@fortawesome/free-solid-svg-icons';
import {Button, Modal} from 'react-bootstrap';

import {useJobLoadable} from 'modules/job/hooks/useJobLoadable';
import {longDateFormat} from 'shared/utils/date';

import {FormatDate} from 'shared/components/date/FormatDate/FormatDate';
import {JobStatusBadge} from 'components/job/shared/JobStatusBadge/JobStatusBadge';
import {JobLocation} from 'components/job/shared/JobLocation/JobLocation';
import {JobUserSummary} from 'components/job/shared/JobUserSummary/JobUserSummary';
import {JobWorkTypeIcon} from 'components/job/shared/JobWorkTypeIcon/JobWorkTypeIcon';
import {LoadingSpinner} from 'shared/components/loading/LoadingSpinner/LoadingSpinner';
import {PageError} from 'shared/components/PageError/PageError';

import './style.scss';

interface IJobModalContentProps {
    onHide: () => void;
    jobId: string;
}

export const JobModalContent = ({onHide, jobId}: IJobModalContentProps) => {
    const {job, loading, error} = useJobLoadable(jobId);

    return (
        <>
            {!job && (
                <Modal.Header closeButton={true}>
                    <Modal.Title className="mb-1">View Job</Modal.Title>
                </Modal.Header>
            )}
            {loading && (
                <Modal.Body>
                    <LoadingSpinner/>
                </Modal.Body>
            )}
            {error && (
                <Modal.Body>
                    <PageError description="Error loading job"/>
                </Modal.Body>
            )}
            {job && (
                <>
                    <Modal.Header closeButton={true}>
                        <div className="">
                            <Modal.Title className="mb-1">{job.name}</Modal.Title>
                            <div className="d-flex align-items-center">
                                <JobStatusBadge status={job.status} size="sm" className="mr-3"/>
                                <JobLocation job={job}/>
                                <small className="text-muted ml-3">
                                    <FontAwesomeIcon icon={faCalendar} className="mr-2"/>
                                    <FormatDate formatString={longDateFormat}>{job.created_at}</FormatDate>
                                </small>
                            </div>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="mb-3 d-flex align-items-center">
                            <JobUserSummary userId={job.user_id} className="mr-4"/>
                            <JobWorkTypeIcon workType={job.work_type} iconClassName="JobModal__work-type-icon mr-3"/>
                        </div>
                        <div className="text-preserve-whitespace text-muted">
                            {job.description}
                        </div>
                    </Modal.Body>
                </>
            )}
            <Modal.Footer>
                <Button variant="outline-secondary" onClick={onHide}>
                    Done
                </Button>
            </Modal.Footer>
        </>
    );
};
