import {atom, selectorFamily} from 'recoil';

import {IIssue} from 'modules/issue/models';
import {CursorList} from 'shared/types/cursor-list';
import {readIssueList} from 'modules/issue/api';
import {guardRecoilDefaultValue} from 'shared/recoil/utils';

export interface IIssueListStateFilters {
    page: number;
    limit: number;

    [key: string]: number;
}

export interface IIssueListStateValue {
    filters: IIssueListStateFilters;
    issues: IIssue[];
    cursors: CursorList;
    more: boolean;
    resetVersion: number;
}

export const issueListAtom = atom<IIssueListStateValue | undefined>({
    key: 'issueListAtom',
    default: undefined,
});

export const issueListResetAtom = atom<number>({
    key: 'issueListResetAtom',
    default: 1,
});

export const issueListSelector = selectorFamily<IIssueListStateValue | undefined, IIssueListStateFilters>({
    key: 'issueListSelector',
    get: (filters) => ({get}) => {
        const atomValue = get(issueListAtom);
        const resetVersion = get(issueListResetAtom);
        if (atomValue && atomValue.filters.page === filters.page && atomValue.filters.limit === filters.limit && atomValue.resetVersion === resetVersion) {
            return atomValue;
        }
        return undefined;
    },
    set: () => ({set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        set(issueListAtom, newValue);
    },
});

export const issueListReadSelector = selectorFamily<IIssueListStateValue, IIssueListStateFilters>({
    key: 'issueListReadSelector',
    get: (filters) => async ({get}) => {
        const currentValue = get(issueListSelector(filters));
        if (currentValue) {
            return currentValue;
        }
        const atomValue = get(issueListAtom);
        const resetVersion = get(issueListResetAtom);
        const cursors = atomValue && filters.page > 0 ? Array.from(atomValue.cursors) : [];
        const issueListResult = await readIssueList({
            limit: filters.limit,
            cursor: cursors[filters.page],
        });
        cursors[filters.page] = issueListResult.next_cursor;
        return {
            issues: issueListResult.issues,
            cursors,
            filters,
            resetVersion,
            more: !!issueListResult.next_cursor,
        };
    },
});

