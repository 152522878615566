import React from 'react';

import {format, parseISO} from 'date-fns';

interface IFormatDateProps {
    children: Date | string;
    formatString: string;
}

export const FormatDate = ({children, formatString}: IFormatDateProps) => {
    const date = (typeof children === 'string') ? parseISO(children) : children;
    const dateString = format(date, formatString);
    return (
        <>{dateString}</>
    );
};

FormatDate.defaultProps = {
    formatString: 'dd/MM/yyyy',
};
