import {IPostListStateFilters, postListReadSelector, postListSelector} from 'modules/post/state/post-list';
import {useRecoilLoadable} from 'shared/recoil/hooks/useRecoilLoadable';

export const usePostListLoadable = (filters: IPostListStateFilters) => {
    const {data, loading, error} = useRecoilLoadable({
        state: postListSelector(filters),
        loadable: postListReadSelector(filters),
    });
    return {
        postList: data,
        loading,
        error,
    };
};
