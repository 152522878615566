import React, {useReducer, useState} from 'react';

import {Col, Image, Row} from 'react-bootstrap';
import classNames from 'classnames';

import {IFile} from 'modules/file/models/IFile';
import {getFileDownloadUrl} from 'modules/file/utils';
import {useFileListLoadable} from 'modules/file/hooks/useFileListLoadable';

import {LoadingSpinner} from 'shared/components/loading/LoadingSpinner/LoadingSpinner';
import {LoadingError} from 'shared/components/loading/LoadingError/LoadingError';

import './style.scss';

interface IProfileImageListProps {
    userId: string;
    onChange: (file: IFile) => void;
    className?: string;
}

export const ProfileImageList = ({userId, onChange, className}: IProfileImageListProps) => {
    const [selected, setSelected] = useState<string>('');
    const [, forceUpdate] = useReducer(x => x + 1, 0);

    const {fileList, loading, error} = useFileListLoadable({
        folder: `users/${userId}/picture`,
        limit: 100,
        page: 0,
    });

    function getFileClassName(file: IFile) {
        return classNames('ProfileImageList__image dropdown-item', {
            'ProfileImageList__image--selected': file.id === selected,
        });
    }

    const onFileClick = (file: IFile) => {
        setSelected(file.id);
        onChange(file);
        forceUpdate();
    };

    return (
        <div className={className}>
            {fileList && (
                <Row xs={2} sm={4}>
                    {fileList.files.map(file => (
                        <Col key={file.id}>
                            <Image
                                src={getFileDownloadUrl({
                                    id: file.id,
                                    filename: file.filename,
                                    public_url: file.public_url,
                                })}
                                className={getFileClassName(file)}
                                onClick={() => onFileClick(file)}
                                thumbnail
                            />
                        </Col>
                    ))}
                </Row>
            )}
            {loading && (
                <LoadingSpinner/>
            )}
            {error && (
                <LoadingError>
                    Error loading profile images.
                </LoadingError>
            )}
        </div>
    );
};
