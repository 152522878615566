import {Spinner as ReactBootstrapSpinner} from 'react-bootstrap';

interface ISpinnerProps {
    variant?: string;
    size?: 'sm';
    className?: string;
}

export const Spinner = ({variant, size, className}: ISpinnerProps) => (
    <ReactBootstrapSpinner animation="border" variant={variant} size={size} className={className}/>
);
