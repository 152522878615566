import {atom, selector} from 'recoil';

import {getCurrentUserScopes} from '../utils/get-current-user-scopes';
import {guardRecoilDefaultValue} from 'shared/recoil/utils';
import {Scope} from 'shared/auth/models';
import {currentUserIdAtom} from 'shared/auth/state/current-user-id';

export const currentUserScopesAtom = atom<Scope[] | undefined>({
    key: 'currentUserScopesAtom',
    default: undefined,
});

export const guaranteedCurrentUserScopesSelector = selector<Scope[] | undefined>({
    key: 'guaranteedCurrentUserScopesSelector',
    get: async ({get}) => {
        const currentUserId = get(currentUserIdAtom);
        if (!currentUserId) {
            return undefined;
        }
        const currentUserScopes = get(currentUserScopesAtom);
        if (currentUserScopes) {
            return currentUserScopes;
        } else {
            return await getCurrentUserScopes();
        }
    },
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        set(currentUserScopesAtom, newValue);
    },
});
