import React from 'react';

import {Form, FormCheckProps} from 'react-bootstrap';
import {Controller, FieldValues, Path, Control} from 'react-hook-form';

interface ICheckboxControllerProps<FormType> extends FormCheckProps {
    control: Control<FormType>;
    name: Path<FormType>;
    id?: string;
    label?: React.ReactNode;
    children?: React.ReactNode;
}

export const CheckboxController = <FormType extends FieldValues>({
    name,
    control,
    id,
    ...rest
}: ICheckboxControllerProps<FormType>) => {

    return (
        <Controller
            name={name}
            control={control}
            render={({field: {value, ...field}}) => (
                <Form.Check
                    id={id ?? name}
                    checked={!!value}
                    {...field}
                    {...rest}
                />
            )}
        />
    );
};
