import React from 'react';

import {Control} from 'react-hook-form';

import {IProfile} from 'modules/profile/models/IProfile';
import {IProfileUpdate} from 'modules/profile/models/IProfileUpdate';

import {FormCard} from 'components/profile/shared/FormCard/FormCard';
import {FormController} from 'shared/components/form/FormController/FormController';
import {Row, Col} from 'react-bootstrap';

interface IProfileFormReferredByProps {
    profile: IProfile;
    control: Control<IProfileUpdate>;
    isSubmitting: boolean;
}

export const ProfileFormReferredBy = ({control, isSubmitting}: IProfileFormReferredByProps) => {

    return (
        <FormCard title="Referred By">
            <Row>
                <Col xs={12} xl={6}>
                    <FormController
                        name="referred_by"
                        label="Referred By"
                        control={control}
                        disabled={isSubmitting}
                    />
                </Col>
            </Row>
        </FormCard>
    );
};
