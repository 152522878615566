import React from 'react';

import {useRecoilValue} from 'recoil';
import {Table} from 'react-bootstrap';

import {IProfileListStateFilters, profileListReadSelector} from 'modules/profile/state/profile-list';

import {PaginationControls} from 'shared/components/PaginationControls/PaginationControls';
import {ProfilePicture} from 'shared/components/ProfilePicture/ProfilePicture';
import {ProfileListRow} from 'components/profile/ProfileList/ProfileListRow';
import {IProfile} from 'modules/profile/models/IProfile';
import {DeleteProfileModal} from 'components/profile/ProfileList/DeleteProfileModal';

interface IProfileListTableProps {
    filters: IProfileListStateFilters;
    setFilters: (filters: IProfileListStateFilters) => void;
}

export const ProfileListTable = ({filters, setFilters}: IProfileListTableProps) => {
    const [profileToDelete, setProfileToDelete] = React.useState<IProfile | undefined>(undefined);
    const {profiles, more} = useRecoilValue(profileListReadSelector(filters));

    const onPaginationChange = (page: number) => {
        setFilters({
            ...filters,
            page,
        });
    };

    return (
        <>
            <Table className="table-fixed">
                <thead>
                <tr>
                    <th className="table__picture-col table_th_common">
                        <ProfilePicture alt="Profile Picture" imgClassName="article" round size="xs" table/>
                    </th>
                    <th>Name</th>
                    <th className="d-none d-md-table-cell">ID</th>
                    <th>Created At</th>
                    <th>Profession</th>
                    <th>Status</th>
                    <th className="table__options-col">Options</th>
                </tr>
                </thead>
                <tbody>
                {profiles.map((profile) => (
                    <ProfileListRow key={profile.id} profile={profile} onDelete={() => setProfileToDelete(profile)}/>
                ))}
                </tbody>
            </Table>
            <PaginationControls page={filters.page} onChange={onPaginationChange} isMore={more}/>
            <DeleteProfileModal
                profile={profileToDelete}
                show={!!profileToDelete}
                onHide={() => setProfileToDelete(undefined)}
            />
        </>
    );
};
