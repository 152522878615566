import {AxiosResponse} from 'axios';

export type SystemTaskCallable = () => Promise<AxiosResponse>;
export type UserSystemTaskCallable = (userId: string) => Promise<AxiosResponse>;

export enum BackendService {
    Main = 'main',
    Document = 'document',
    File = 'file',
    Chat = 'chat',
    User = 'user',
    Payment = 'payment',
    Notification = 'notification',
    Help = 'help',
}

export interface ISystemTask<CallableType = void> {
    name: string;
    action: (args: CallableType) => Promise<AxiosResponse>;
    isSafe: boolean;
}

export interface IServiceTask<CallableType = void> {
    name: string;
    service: BackendService;
    action: (args: CallableType) => Promise<AxiosResponse>;
    isSafe: boolean;
}
