import React from 'react';

import {Row, Col} from 'react-bootstrap';

import {useTitle} from 'shared/hooks/useTitle';
import {useDashboardTitle} from 'shared/hooks/useDashboardTitle';
import {useProfileCountLoadable} from 'modules/profile/hooks/useProfileCountLoadable';
import {usePostCountLoadable} from 'modules/post/hooks/usePostCountLoadable';

import {DashboardCard} from 'components/AdminDashboard/DashboardCard';

export const AdminDashboard = () => {
    useTitle('Dashboard');
    useDashboardTitle('Dashboard');

    const {profileCount} = useProfileCountLoadable({});
    const {postCount} = usePostCountLoadable({});

    return (
        <Row>
            <Col sm={2}>
                <DashboardCard title="Profiles" amount={profileCount} to="/profiles"/>
            </Col>
            <Col sm={2}>
                <DashboardCard title="Resources" amount={postCount} to="/resources"/>
            </Col>
        </Row>
    );
};
