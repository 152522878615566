import React from 'react';

import {Badge} from 'react-bootstrap';

import {SubscriptionStatus} from 'modules/billing/models';
import {IBadgeProps} from 'shared/models/badge';

interface IBillingStatusBadgeProps {
    status: SubscriptionStatus;
}

export const BillingStatusBadge = ({status}: IBillingStatusBadgeProps) => {
    const badge = React.useMemo<IBadgeProps>(() => {
        switch (status) {
            case SubscriptionStatus.Active:
                return {
                    variant: 'success',
                    children: 'Active Subscription',
                };
            case SubscriptionStatus.Failed:
                return {
                    variant: 'danger',
                    children: 'Failed Payment',
                };
            default:
                return {
                    variant: 'dark',
                    children: 'No Active Subscription',
                };
        }
    }, [status]);

    return (
        <Badge className="badge--lg" {...badge}/>
    );
};
