import React from 'react';

import {Button, Form, Modal} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';
import {z} from 'zod';
import {useSetRecoilState} from 'recoil';

import {CategoryIcon} from 'modules/help/models';
import {createCategory} from 'modules/help/api';
import {categoryListInsertSelector} from 'modules/help/state/category-list';
import {toastAxiosError} from 'shared/utils/error';
import {toast} from 'shared/utils/toast';

import {InputController} from 'shared/components/form/InputController/InputController';

interface ICreateCategoryModalProps {
    show: boolean;
    onHide: () => void;
}

interface ICategoryCreateForm {
    name: string;
    description: string;
    icon: CategoryIcon;
}

const categoryCreateForm = z.object({
    name: z.string().nonempty(),
    description: z.string().nonempty(),
    icon: z.nativeEnum(CategoryIcon),
});

export const CreateCategoryModal = ({show, onHide}: ICreateCategoryModalProps) => {
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
    const insertCategory = useSetRecoilState(categoryListInsertSelector);

    const {
        control,
        handleSubmit,
        reset,
    } = useForm<ICategoryCreateForm>({
        resolver: zodResolver(categoryCreateForm),
    });

    React.useEffect(() => {
        if (!show) {
            reset();
        }
    }, [show, reset]);

    const onSubmit = handleSubmit(({icon, ...formData}) => {
        setIsSubmitting(true);
        (async () => {
            try {
                const newCategory = await createCategory({
                    picture: {
                        icon,
                    },
                    ...formData,
                });
                insertCategory(newCategory);
                onHide();
                toast.success('Create Category', 'Help category created successfully.');
            } catch (e) {
                toastAxiosError(e, 'Error creating category');
            } finally {
                setIsSubmitting(false);
            }
        })();
    });

    return (
        <Modal
            centered={true}
            show={show}
            size="lg"
            title="Add Category"
            isSubmitting={isSubmitting}
            onHide={onHide}
        >
            <Modal.Header closeButton>
                <Modal.Title>
                    Create Category
                </Modal.Title>
            </Modal.Header>
            <Form onSubmit={onSubmit}>
                <Modal.Body>
                    <Form.Group>
                        <InputController
                            control={control}
                            name="name"
                            placeholder="Name"
                            disabled={isSubmitting}
                        />
                    </Form.Group>
                    <Form.Group>
                        <InputController
                            control={control}
                            name="description"
                            placeholder="Description"
                            asType="textarea"
                            disabled={isSubmitting}
                        />
                    </Form.Group>
                    <Form.Group>
                        <InputController
                            control={control}
                            name="icon"
                            asType="select"
                            disabled={isSubmitting}
                        >
                            <option disabled value="" selected>
                                -- Select an Icon --
                            </option>
                            {Object.values(CategoryIcon).map(icon => (
                                <option key={icon} value={icon}>{icon}</option>
                            ))}
                        </InputController>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button type="submit" variant="featured-blue" disabled={isSubmitting}>
                        Add
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};
