import React from 'react';

import {Form} from 'react-bootstrap';

import './style.scss';

interface ITableSearchProps {
    value?: string;
    onChange?: (value: string) => void;
    placeholder?: string;
    disabled?: boolean;
}

export const TableSearch = ({value, placeholder, onChange, disabled}: ITableSearchProps) => {
    return (
        <Form.Control
            type="text"
            className="TableSearch focusNone"
            placeholder={placeholder}
            style={{
                backgroundImage: 'url("/assets/loupe.svg")',
                backgroundRepeat: 'no-repeat',
            }}
            onChange={e => onChange?.(e.target.value)}
            value={value}
            disabled={disabled}
        />
    );
};
