import React from 'react';

import {Dropdown} from 'react-bootstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCog} from '@fortawesome/free-solid-svg-icons';

import {DropdownCaret} from 'shared/components/DropdownCaret/DropdownCaret';

import './style.scss';

interface ITableDropdownProps {
    variant: string;
    size: 'sm' | 'lg' | undefined;
}

export const TableDropdown = ({size, variant, children}: React.PropsWithChildren<ITableDropdownProps>) => {
    return (
        <Dropdown>
            <Dropdown.Toggle variant={variant} id="dropdown-basic" size={size} block className="TableDropdown__toggle">
                <FontAwesomeIcon className="TableDropdown__icon" icon={faCog}/>
                <DropdownCaret/>
            </Dropdown.Toggle>

            <Dropdown.Menu className="TableDropdown__dropdown-menu" alignRight popperConfig={{strategy: 'fixed'}}>
                {children}
            </Dropdown.Menu>
        </Dropdown>
    );
};

TableDropdown.defaultProps = {
    variant: 'dark',
    size: 'sm',
};
