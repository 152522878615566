import {atom, selector} from 'recoil';

import {ICategory} from '../models';
import {guardRecoilDefaultValue} from 'shared/recoil/utils';
import {readCategoryList} from '../api';

interface ICategoryListState {
    categories: ICategory[];
    resetVersion: number;
}

export const categoryListAtom = atom<ICategoryListState | undefined>({
    key: 'categoryListAtom',
    default: undefined,
});

export const categoryListResetAtom = atom<number>({
    key: 'categoryListResetAtom',
    default: 1,
});

export const categoryListSelector = selector<ICategoryListState | undefined>({
    key: 'categoryListSelector',
    get: ({get}) => {
        const atomValue = get(categoryListAtom);
        const resetVersion = get(categoryListResetAtom);
        if (atomValue && atomValue.resetVersion === resetVersion) {
            return atomValue;
        }
        return undefined;
    },
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue) || !newValue) {
            return;
        }
        set(categoryListAtom, newValue);
    },
});

export const categoryListReadSelector = selector<ICategoryListState>({
    key: 'categoryListReadSelector',
    get: async ({get}) => {
        const currentValue = get(categoryListSelector);
        if (currentValue) {
            return currentValue;
        }
        const resetVersion = get(categoryListResetAtom);
        const categoryList = await readCategoryList({
            limit: 1000,
        });
        return {
            categories: categoryList.categories,
            resetVersion,
        };
    },
});

export const categoryListInsertSelector = selector<ICategory>({
    key: 'categoryListInsertSelector',
    get: async () => {
        throw new Error('This is a write-only selector');
    },
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        const resetVersion = get(categoryListResetAtom);
        set(categoryListResetAtom, resetVersion + 1);
    },
});

export const categoryListRemoveSelector = selector<string>({
    key: 'categoryListRemoveSelector',
    get: async () => {
        throw new Error('This is a write-only selector');
    },
    set: ({get, set}, newValue) => {
        if (guardRecoilDefaultValue(newValue)) {
            return;
        }
        const resetVersion = get(categoryListResetAtom);
        set(categoryListResetAtom, resetVersion + 1);
    },
});
