import React from 'react';

import {ITag} from 'modules/tag/models';

import {Tag} from 'shared/components/tag/Tag/Tag';

interface ITagSelectorBadgeProps {
    tag: ITag;
    isOption?: boolean;
    onRemove?: (tagId: string) => void;
    disabled?: boolean;
    TagClassName?: string;
}

export const TagSelectorBadge = ({tag, isOption, TagClassName, onRemove, disabled}: ITagSelectorBadgeProps) => {
    const tagClassName = TagClassName
        ? `${TagClassName} TagSelector__badge TagSelector--hide`
        : 'TagSelector__badge TagSelector--hide';

    return (
        <Tag
            size="xl"
            color={tag.color}
            title={tag.title}
            isOptionClose={isOption}
            className={tagClassName}
            onRemove={disabled ? undefined : onRemove}
            data={tag}
        />
    );
};
