import React from 'react';

import {Button, Form, Modal} from 'react-bootstrap';
import {useSetRecoilState} from 'recoil';
import {useForm} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';

import {ITag, ITagUpdate} from 'modules/tag/models';
import {toast} from 'shared/utils/toast';
import {toastAxiosError} from 'shared/utils/error';
import {tagInsertSelector} from 'modules/tag/state/tag-insert';
import {updateTag} from 'modules/tag/api';
import {tagForm} from 'modules/tag/forms';

import {ColorSelector} from 'shared/components/ColorSelector/ColorSelector';

interface IUpdateTagModalProps {
    show: boolean;
    onHide: () => void;
    tag?: ITag;
}

export const UpdateTagModal = ({show, onHide, tag}: IUpdateTagModalProps) => {
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
    const insertTag = useSetRecoilState(tagInsertSelector);

    const {
        register,
        handleSubmit,
        control,
        reset,
        formState: {errors},
    } = useForm<ITagUpdate>({
        resolver: zodResolver(tagForm),
    });

    React.useEffect(() => {
        if (tag) {
            reset({
                title: tag.title,
                color: tag.color,
            });
        }
    }, [tag, reset]);

    React.useEffect(() => {
        if (!show) {
            setIsSubmitting(false);
        }
    }, [show]);

    const onSubmit = (formData: ITagUpdate) => {
        if (!tag) {
            return;
        }

        setIsSubmitting(true);
        (async () => {
            try {
                const updatedTag = await updateTag(tag.id, formData);
                insertTag(updatedTag);
                toast.success('Tag Updated', 'The tag was successfully deleted.');
                onHide();
            } catch (e) {
                toastAxiosError(e, 'Error Updating Tag');
            } finally {
                setIsSubmitting(false);
            }
        })();
    };

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>
                    Update Tag
                </Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleSubmit(onSubmit)} noValidate>
                <Modal.Body>
                    <Form.Group controlId="title">
                        <Form.Label className="form-label--sm">Title</Form.Label>
                        <Form.Control
                            {...register('title')}
                            disabled={isSubmitting}
                            autoFocus
                            placeholder="Title of the tag"
                            isInvalid={!!errors?.title}
                        />
                        {errors && errors.title && (
                            <Form.Control.Feedback type="invalid">{errors.title.message}</Form.Control.Feedback>
                        )}
                    </Form.Group>
                    <Form.Group controlId="color">
                        <Form.Label className="form-label--sm">Color</Form.Label>
                        <Form.Text className="AdminCreateTag__color_form-text mb-3">
                            Select a color for the tag badge.
                        </Form.Text>
                        <ColorSelector
                            control={control}
                            name="color"
                            disabled={isSubmitting}
                        />
                        {errors && errors.color && (
                            <Form.Control.Feedback type="invalid">{errors.color.message}</Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" type="submit" disabled={isSubmitting}>
                        Save
                    </Button>
                </Modal.Footer>
            </Form>
        </Modal>
    );
};
