import {z} from 'zod';

import {Profession} from 'modules/profile/models/profession';
import {ServiceType} from 'modules/profile/models/serviceType';
import {SocialProvider} from 'modules/profile/models/socialProvider';

export const contactDetailsForm = z.object({
    email: z.union([z.string().optional(), z.string().email().min(8)]).nullable(),
    phone_number: z.string().nullable(),
    address: z.string().nullable(),
    city: z.string().nullable(),
    state: z.string().nullable(),
    country: z.string().nullable(),
});

export const skillForm = z.object({
    name: z.string().min(1),
    level: z.number().min(1).max(5),
});

export const workExperienceForm = z.object({
    name: z.string().min(1),
    description: z.string().min(1),
});

export const socialLinkForm = z.object({
    type: z.nativeEnum(SocialProvider),
    url: z.string().url(),
});

export const serviceDetailsForm = z.object({
    day_rate: z.number().nullable().optional(),
    hour_rate: z.number().nullable().optional(),
});

export const updateProfileForm = z.object({
    firstname: z.string().min(1),
    lastname: z.string().min(1),
    title: z.string(),
    profession: z.nativeEnum(Profession).nullable(),
    is_published: z.union([z.boolean(), z.literal('true')]),
    is_featured: z.union([z.boolean(), z.literal('true')]),
    is_owner: z.union([z.boolean(), z.literal('true')]),
    contact_details: contactDetailsForm.partial(),
    contact_details_display: contactDetailsForm.partial(),
    summary: z.string().max(4000),
    claim_to_fame: z.string().max(2000),
    resume_id: z.string().optional().nullable(),
    service_details: serviceDetailsForm,
    service_types: z.array(z.nativeEnum(ServiceType)).optional().nullable(),
    skills: z.array(skillForm).optional(),
    work_experience: z.array(workExperienceForm).optional(),
    social_links: z.array(socialLinkForm).optional(),
    tag_ids: z.array(z.string()).optional(),
    referred_by: z.string(),
});
