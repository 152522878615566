import React from 'react';

import {Button, ListGroup} from 'react-bootstrap';

import {IPendingFile} from 'shared/models/pending-file/IPendingFile';
import {createFileFromFile} from 'modules/file/utils';

import {FileControlAttachment} from 'shared/components/form/FileControl/FileControlAttachment';

import './style.scss';
import {IFile} from 'modules/file/models/IFile';

interface IFileControlProps {
    userId: string;
    maxFiles?: number;
    onChange: (files: IFile[]) => void;
    disabled?: boolean;
    folder: string;
}

export const FileControl = ({
    userId,
    onChange,
    disabled,
    children,
    folder,
}: React.PropsWithChildren<IFileControlProps>) => {
    const fileInputRef = React.useRef<HTMLInputElement>(null);
    const pendingFileIdRef = React.useRef<number>(1);
    const [refresh, setRefresh] = React.useState<string>();
    const [files, setFiles] = React.useState<IFile[]>([]);
    const [pendingFiles, setPendingFiles] = React.useState<IPendingFile[]>([]);

    const onUploadClicked = () => {
        if (fileInputRef.current && fileInputRef.current.click) {
            fileInputRef.current.click();
        }
    };

    // update the list of pending files whenever "refresh" is changed.
    // we use an effect for this because the state variables in an async function could be out of date
    React.useEffect(() => {
        const newlyUploadedFiles: IFile[] = pendingFiles
            .filter((pendingFile) => pendingFile.isComplete && pendingFile.file)
            .map((pendingFile) => pendingFile.file as IFile);
        if (!!newlyUploadedFiles.length) {
            setPendingFiles(pendingFiles.filter((pendingFile) => !(pendingFile.isComplete && pendingFile.file)));
            const newFiles = [...files, ...newlyUploadedFiles];
            setFiles(newFiles);
            onChange(newFiles);
        }
    }, [pendingFiles, setPendingFiles, refresh, files, setFiles, onChange]);

    const uploadFile = (file: File, pendingFile: IPendingFile) => {
        (async () => {
            const newFile = await createFileFromFile({
                userId,
                file,
                folder,
            });
            // mark it as complete and increment the "refresh" counter so that the effect can update the state and move
            // the files to completed
            pendingFile.isComplete = true;
            pendingFile.file = newFile;
            setRefresh(newFile.id);
        })();
    };

    const onFileAdded = () => {
        const files: File[] = fileInputRef.current?.files ? Array.from(fileInputRef.current.files) : [];
        if (!!files.length && userId) {
            const newPendingFiles: IPendingFile[] = [];
            files.forEach((file) => {
                const pendingFile: IPendingFile = {
                    id: pendingFileIdRef.current,
                    filename: file.name,
                    contentType: file.type,
                    size: file.size,
                };
                pendingFileIdRef.current += 1;
                newPendingFiles.push(pendingFile);
                uploadFile(file, pendingFile);
            });
            setPendingFiles([...pendingFiles, ...newPendingFiles]);
            // clear the file input so that onFileAdd is triggered even if same file is chosen again
            if (fileInputRef.current) {
                fileInputRef.current.value = '';
            }
        }
    };

    const onDeleteFile = (file: IFile) => {
        const newFiles = files.filter((fileInState) => fileInState.id !== file.id);
        setFiles(newFiles);
        onChange(newFiles);
    };

    return (
        <>
            <div>
                <div className="file_text_input_group">
                    <div className="file_input_group">
                        <input
                            ref={fileInputRef}
                            name="media"
                            type="file"
                            id="upload"
                            accept="*"
                            className="UploadFile__input"
                            onChange={onFileAdded}
                            disabled={disabled}
                            multiple={true}
                        />
                        <Button
                            onClick={onUploadClicked}
                            disabled={disabled}
                            className="FileControl__button"
                            variant="primary"
                        >
                            <img
                                className="FileControl__button-icon"
                                alt="upload file icon"
                                src="/assets/SVG/upload-big-arrow.svg"
                            />
                            <div className="FileControl__button-p-text">Upload new file</div>
                        </Button>
                    </div>
                    {children}
                </div>
                {pendingFiles.length > 0 && (
                    <ListGroup className="FileControl__attachment-list">
                        {pendingFiles.map((pendingFile) => (
                            <FileControlAttachment
                                key={pendingFile.id}
                                name={pendingFile.filename}
                                size={pendingFile.size}
                                spinner={true}
                            />
                        ))}
                    </ListGroup>
                )}
                {(!!files.length || !!pendingFiles.length) && (
                    <ListGroup className="FileControl__attachment-list">
                        {files.map(file => (
                            <FileControlAttachment
                                name={file.filename}
                                size={file.size}
                                onDelete={() => onDeleteFile(file)}
                                key={file.id}
                                disabled={disabled}
                            />
                        ))}
                    </ListGroup>
                )}
            </div>
        </>
    );
};
