import React from 'react';

import {ModalBody, Form} from 'react-bootstrap';
import {useSetRecoilState} from 'recoil';

import {createFileFromBlob} from 'modules/file/utils';
import {toast} from 'shared/utils/toast';
import {profileInsertSelector} from 'modules/profile/state/profile-insert';
import {updateProfile} from 'modules/profile/api/profile';
import {fileInsertSelector} from 'modules/file/state/file-insert';
import {toastAxiosError} from 'shared/utils/error';

import {ModalFormFooter} from 'shared/components/modal/ModalFormFooter/ModalFormFooter';
import {ICropperSharedState, Cropper} from 'shared/components/Cropper/Cropper';
import {Modal} from 'shared/components/modal/Modal/Modal';

interface IUploadProfilePictureModalProps {
    fileName: string;
    fileData: string;
    show?: boolean;
    userId: string;
    onClose?: () => void;
}

export const UploadProfilePictureModal = ({
    show,
    onClose,
    fileName,
    fileData,
    userId,
}: IUploadProfilePictureModalProps) => {
    const [cropperState, setCropperState] = React.useState<ICropperSharedState | undefined>(undefined);
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
    const insertProfile = useSetRecoilState(profileInsertSelector);
    const insertFile = useSetRecoilState(fileInsertSelector);

    const handleClose = () => {
        onClose?.();
    };

    const onSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        if (!cropperState) {
            return;
        }

        setIsSubmitting(true);

        (async () => {
            try {
                const blob = await cropperState.getImage();
                if (blob) {
                    const file = await createFileFromBlob({
                        blob,
                        filename: fileName,
                        contentType: blob.type,
                        size: blob.size,
                        userId,
                        folder: `users/${userId}/picture`,
                    });
                    insertFile(file);
                    const profile = await updateProfile(userId, {
                        image_id: file.id,
                    });
                    insertProfile(profile);
                    toast.success('Upload Profile Image', 'Profile image updated successfully');
                    onClose?.();
                }
            } catch (e) {
                toastAxiosError(e, 'Upload Profile Image Error');
            } finally {
                setIsSubmitting(false);
            }
        })();
    };

    const onReady = React.useMemo<(newCropperState?: ICropperSharedState) => void>(() => {
        return (newCropperState?: ICropperSharedState) => {
            setCropperState(newCropperState);
        };
    }, [setCropperState]);

    return (
        <Modal
            show={!!show}
            onHide={handleClose}
            size={'lg'}
            isSubmitting={isSubmitting}
            title="Upload Profile Picture"
        >
            <Form onSubmit={onSubmit}>
                <ModalBody>
                    {fileName && fileData && (
                        <Cropper fileData={fileData} onReady={onReady} width={810} height={540}/>
                    )}
                </ModalBody>
                <ModalFormFooter onHide={handleClose} isSubmitting={isSubmitting}/>
            </Form>
        </Modal>
    );
};
