import React, {useState} from 'react';

import {ModalBody, ModalFooter, Button} from 'react-bootstrap';
import {useSetRecoilState} from 'recoil';

import {IFile} from 'modules/file/models/IFile';
import {toast} from 'shared/utils/toast';
import {profileInsertSelector} from 'modules/profile/state/profile-insert';
import {updateProfile} from 'modules/profile/api/profile';

import {Modal} from 'shared/components/modal/Modal/Modal';
import {ProfileImageList} from 'shared/components/ProfileImageList/ProfileImageList';

interface IChooseProfilePictureModalProps {
    userId: string;
    show?: boolean;
    onClose?: () => void;
}

export const ChooseProfilePictureModal = ({show, onClose, userId}: IChooseProfilePictureModalProps) => {
    const [selected, setSelected] = useState<IFile | null>(null);
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const insertProfile = useSetRecoilState(profileInsertSelector);

    const handleClose = () => {
        if (!isSubmitting) {
            onClose?.();
        }
    };

    const onSaveClick = () => {
        if (isSubmitting || !selected) {
            return;
        }
        setIsSubmitting(true);
        (async () => {
            try {
                const profile = await updateProfile(userId, {
                    image_id: selected.id,
                });
                insertProfile(profile);
                toast.success('Choose Profile Image', 'Profile image updated successfully');
                onClose?.();
            } catch (e) {
                toast.error('Error updating profile image', e.toString());
            } finally {
                setIsSubmitting(false);
            }
        })();
    };

    return (
        <Modal
            show={!!show}
            onHide={handleClose}
            title="Choose Profile Picture"
            isSubmitting={isSubmitting}
        >
            <ModalBody>
                <p>Update Profile Picture From Your Existing Images</p>
                <ProfileImageList userId={userId} onChange={file => setSelected(file)}/>
                {selected && (
                    <p className="mb-0">Use {selected.filename}</p>
                )}
            </ModalBody>
            <ModalFooter>
                <Button variant="secondary" onClick={handleClose} disabled={isSubmitting}>
                    Close
                </Button>
                <Button variant="primary" type="submit" onClick={() => onSaveClick()} disabled={isSubmitting}>
                    Save changes
                </Button>
            </ModalFooter>
        </Modal>
    );
};
