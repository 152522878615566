import axios from 'axios';

import {IUser, IUserUpdate} from 'modules/user/models';
import {getAccessTokenSilently} from 'shared/utils/token';
import {IUserAndProfile, IUserProfileCreate} from 'shared/models/user-and-profile';
import {Config} from 'config';
import {getAuthHeaders} from 'shared/utils/auth';

const getURLForUserAPI = (path: string): string => {
    return `${Config.userServiceURL}/users${path}`;
};

export const readUser = async (userId: string): Promise<IUser> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.get(getURLForUserAPI(`/${userId}`), {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const updateUser = async (userId: string, updateData: IUserUpdate): Promise<IUser> => {
    const response = await axios.patch(getURLForUserAPI(`/${userId}`), updateData, {
        headers: {
            Authorization: `Bearer ${await getAccessTokenSilently()}`,
        },
    });
    return response.data;
};

export const createUserAndProfile = async (userProfileCreate: IUserProfileCreate): Promise<IUserAndProfile> => {
    const accessToken = await getAccessTokenSilently();
    const response = await axios.post(getURLForUserAPI('/'), userProfileCreate, {
        headers: getAuthHeaders(accessToken),
    });
    return response.data;
};

export const deleteUserVerification = async (userId: string) => {
    const accessToken = await getAccessTokenSilently();
    return await axios.post(getURLForUserAPI(`/${userId}/un_verify`), null, {
        headers: getAuthHeaders(accessToken),
    });
};

export const sendVerificationEmail = async (userId: string) => {
    const accessToken = await getAccessTokenSilently();
    return await axios.post(getURLForUserAPI(`/${userId}/send_verification_email`), null, {
        headers: getAuthHeaders(accessToken),
    });
};
