import React from 'react';

import DropdownItem, {DropdownItemProps} from 'react-bootstrap/DropdownItem';
import {Link} from 'react-router-dom';
import classNames from 'classnames';

interface ITableDropdownItem extends DropdownItemProps {
    to?: string;
    href?: string;
    target?: string;
    rel?: string;
    icon?: React.ElementType;
    variant?: 'danger' | 'warning' | 'success' | 'info';
}

export const TableDropdownItem = ({
    icon,
    variant,
    to,
    href,
    children,
    ...rest
}: React.PropsWithChildren<ITableDropdownItem>) => {
    const IconComponent = icon ?? undefined;

    const iconClassName = classNames('TableDropdownItem__icon', {
        [`text-${variant}`]: variant,
    });

    return (
        <DropdownItem
            as={to ? Link : undefined}
            to={to || ''}
            href={href}
            className="TableDropdownItem"
            {...rest}
        >
            {IconComponent && (
                <IconComponent className={iconClassName}/>
            )}
            <div className="TableDropdownItem__title">{children}</div>
        </DropdownItem>
    );
};
