import React from 'react';

import {useNavigate} from 'react-router-dom';
import {Button, Card, Form} from 'react-bootstrap';
import {useForm} from 'react-hook-form';
import {zodResolver} from '@hookform/resolvers/zod';
import {useSetRecoilState} from 'recoil';
import {z} from 'zod';

import {IPost} from 'modules/post/models/IPost';
import {Scope} from 'shared/auth/models';
import {IPostMedia} from 'modules/post/models/IPostMedia';
import {PostMediaKind} from 'modules/post/models/postMediaKind';
import {PostKind} from 'modules/post/models/postKind';
import {IPendingFile} from 'shared/models/pending-file/IPendingFile';
import {ICreatePostLawyer} from 'modules/post/models/ICreatePost';
import {toastAxiosError} from 'shared/utils/error';
import {updatePost} from 'modules/post/api';
import {toast} from 'shared/utils/toast';
import {useAuth} from 'shared/auth/hooks/useAuth';
import {insertPostSelector} from 'modules/post/state/post-insert';
import {createFileFromFile} from 'modules/file/utils';

import {Spinner} from 'shared/components/loading/Spinner/Spinner';
import {InputController} from 'shared/components/form/InputController/InputController';
import {ArticleEditor} from 'shared/components/form/ArticleEditor/ArticleEditor';
import {FileControlAttachment} from 'shared/components/form/FileControl/FileControlAttachment';
import {TagSelectorController} from 'components/profile/shared/TagSelector/TagSelectorController';

import './style.scss';

interface IViewPostContentProps {
    post: IPost;
}

type UpdateResourceFormData = {
    title: string;
    content: string;
    summary: string;
    tagIds: string[];
    isPublished: boolean | true;
    isFeatured: boolean | true;
    link: string;
};

const postSchema = z.object({
    title: z.string().min(1),
    summary: z.string().min(1),
    content: z.string(),
    tagIds: z.array(z.string()),
    link: z.string().optional(),
    isPublished: z.union([z.boolean(), z.literal(true)]),
    isFeatured: z.union([z.boolean(), z.literal(true)]),
});

const UpdatePostForm = ({post}: IViewPostContentProps) => {
    const navigate = useNavigate();

    const {tokenData} = useAuth();
    const isSystemUser = tokenData && tokenData.scopes.includes(Scope.System);

    const {
        control,
        register,
        handleSubmit,
        setValue,
        formState: {errors},
    } = useForm<UpdateResourceFormData>({
        resolver: zodResolver(postSchema),
        defaultValues: {
            title: post.title,
            content: post.content,
            summary: post.summary,
            tagIds: post.tag_ids,
            isPublished: post.is_published,
            isFeatured: post.is_featured,
        },
    });

    React.useEffect(() => {
        const LinkIndex = post.media.findIndex((value) => value.kind === 'LINK');
        const urlLink = LinkIndex > -1 ? post.media[LinkIndex].name : '';
        if (urlLink) {
            setValue('link', urlLink);
        }
    }, [post, setValue]);

    const insertPost = useSetRecoilState(insertPostSelector);
    const fileInputRef = React.useRef<HTMLInputElement>(null);
    const [media, setMedia] = React.useState<IPostMedia[]>([]);
    const [fileUploaded, setFileUploaded] = React.useState<boolean>(true);
    const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

    const uploadClicked = () => {
        if (fileInputRef.current && fileInputRef.current.click) {
            fileInputRef.current.click();
        }
    };
    const [files, setFiles] = React.useState<IPendingFile[]>([]);

    const fileAdded = () => {
        const file = fileInputRef.current && fileInputRef.current.files && fileInputRef.current.files[0];
        if (file && post.user_id) {
            const pendingFile = {
                id: files.length + 1,
                filename: file.name,
                contentType: file.type,
                size: file.size,
            };
            setFiles([pendingFile]);
            setFileUploaded(false);

            (async () => {
                const fileResult = await createFileFromFile({
                    userId: post.user_id,
                    file,
                    folder: `users/${post.user_id}/post`,
                });
                setMedia([
                    {
                        kind: PostMediaKind.Image,
                        name: fileResult.filename,
                        file_id: fileResult.id,
                    },
                ]);
                setFileUploaded(true);
            })();
        }
    };

    const onContentChange = (content: string) => {
        setValue('content', content, {
            shouldValidate: true,
            shouldDirty: true,
        });
    };

    const onDeleteFile = () => {
        setFiles([]);
    };

    const onSubmit = handleSubmit((formData) => {
        setIsSubmitting(true);

        const mediaToSubmit = post ? Array.from(post.media) : [];
        const IndexForMediaLink = mediaToSubmit ? mediaToSubmit.findIndex((value) => value.kind === 'LINK') : [];
        if (typeof IndexForMediaLink === 'number' && IndexForMediaLink > -1) {
            if (formData.link) {
                mediaToSubmit[IndexForMediaLink] = {
                    kind: PostMediaKind.Link,
                    name: formData.link,
                    content: formData.link,
                };
            } else {
                mediaToSubmit.splice(IndexForMediaLink, 1);
            }
        } else {
            if (formData.link) {
                mediaToSubmit.push({
                    kind: PostMediaKind.Link,
                    name: formData.link,
                    content: formData.link,
                });
            }
        }

        const IndexForMedia = mediaToSubmit ? mediaToSubmit.findIndex((value) => value.kind === 'IMAGE') : [];

        if (typeof IndexForMedia === 'number' && IndexForMedia > -1) {
            if (media.length) {
                mediaToSubmit[IndexForMedia] = media[0];
            }
        } else {
            if (media.length) {
                mediaToSubmit.push(media[0]);
            }
        }

        const postUpdate: ICreatePostLawyer = {
            kind: PostKind.Article,
            media: mediaToSubmit ? mediaToSubmit : [],
            user_id: post.user_id,
            title: formData.title,
            content: formData.content,
            summary: formData.summary,
            tag_ids: formData.tagIds,
            is_published: formData.isPublished,
            is_featured: formData.isFeatured,
        };

        (async () => {
            try {
                const updatedPost = await updatePost(post.id, postUpdate);
                insertPost(updatedPost);
                setIsSubmitting(false);
                const toastTitle = 'Resource updated';
                if (postUpdate.is_published) {
                    toast.success(toastTitle, 'The resource was updated successfully');
                } else {
                    toast.warning(
                        toastTitle,
                        'The resource was updated successfully, but it is hidden until you publish it.',
                    );
                }
            } catch (e) {
                toastAxiosError(e, 'Update Resource Error');
            } finally {
                setIsSubmitting(false);
            }
        })();
    });

    return (
        <>
            <Card>
                <Card.Body>
                    <Form onSubmit={onSubmit} noValidate>
                        <input type="hidden" {...register('content')} />
                        <Form.Group controlId="CreateResource__title">
                            <Form.Label className="CreateResource__label">Title</Form.Label>
                            <Form.Control
                                {...register('title')}
                                className="CreateResource__input"
                                placeholder="Give your resource a title"
                                autoFocus
                                disabled={isSubmitting}
                                isInvalid={!!errors?.title}
                            />
                            {errors && errors.title && (
                                <Form.Control.Feedback type="invalid">{errors.title.message}</Form.Control.Feedback>
                            )}
                        </Form.Group>
                        <Form.Group controlId="CreateResource__title">
                            <Form.Label className="CreateResource__label">Link</Form.Label>
                            <InputController
                                className=""
                                control={control}
                                name="link"
                                placeholder="Enter resource link"
                                disabled={isSubmitting}
                            />
                        </Form.Group>
                        <Form.Group controlId="CreateResource__content">
                            <Form.Label className="CreateResource__label">Content</Form.Label>
                            <Form.Text className="CreateResource__form-text">
                                Use the "+" button to build the page content for this post.
                            </Form.Text>
                            <ArticleEditor
                                name="content"
                                onChange={onContentChange}
                                isInvalid={!!errors?.content}
                                value={post.content}
                                disabled={isSubmitting}
                            />
                            {errors && errors.content && (
                                <Form.Control.Feedback type="invalid">{errors.content.message}</Form.Control.Feedback>
                            )}
                        </Form.Group>
                        <Form.Group controlId="CreateResource__summary">
                            <Form.Label className="CreateResource__label">Summary</Form.Label>
                            <Form.Text className="CreateResource__form-text">
                                Enter a summary for this page, which will show up in previews and thumbnails.
                            </Form.Text>
                            <Form.Control
                                as="textarea"
                                {...register('summary')}
                                className="CreateResource__input"
                                placeholder="Summarise your resource"
                                isInvalid={!!errors?.summary}
                                disabled={isSubmitting}
                            />
                            {errors && errors.summary && (
                                <Form.Control.Feedback type="invalid">{errors.summary.message}</Form.Control.Feedback>
                            )}
                        </Form.Group>
                        <Form.Group>
                            <Form.Label className="CreateResource__label">Resources</Form.Label>
                            <Form.Text className="CreateResource__form-text">
                                Attach a file, image or other resource to your post.
                            </Form.Text>
                            <input
                                ref={fileInputRef}
                                name="media"
                                type="file"
                                id="upload"
                                accept="*"
                                className="UploadFile__input"
                                onChange={fileAdded}
                                disabled={isSubmitting}
                            />
                            <Button
                                onClick={uploadClicked}
                                disabled={isSubmitting}
                                className="FileControl__button"
                                variant="primary"
                            >
                                <img
                                    className="FileControl__button-icon"
                                    alt="upload file icon"
                                    src="/assets/SVG/upload-big-arrow.svg"
                                />
                                <div className="FileControl__button-p-text">Upload new file</div>
                            </Button>
                            <span>
                                {files.map((pendingFile) => (
                                    <div className="mt-3" key={pendingFile.id}>
                                        <FileControlAttachment
                                            key={pendingFile.id}
                                            name={pendingFile.filename}
                                            size={pendingFile.size}
                                            onDelete={() => onDeleteFile()}
                                            disabled={!fileUploaded || isSubmitting}
                                            spinner={!fileUploaded}
                                        />
                                    </div>
                                ))}
                            </span>
                        </Form.Group>
                        <Form.Group controlId="CreateResource__tagIds">
                            <Form.Label className="CreateResource__label" id="CreateResource__tagIds">
                                Tags
                            </Form.Label>
                            <Form.Text className="CreateResource__form-text">
                                Tag your post with relevant categories to make it easier for others to find.
                            </Form.Text>
                            <TagSelectorController control={control} name="tagIds" disabled={isSubmitting} />
                        </Form.Group>
                        <Form.Label className="CreateResource__label">Visibility</Form.Label>
                        <Form.Group controlId="CreateResource__isPublished">
                            <Form.Check
                                label="Publish"
                                className="CreateResource__checkbox"
                                type="checkbox"
                                disabled={isSubmitting}
                                {...register('isPublished')}
                            />
                            <Form.Text className="CreateResource__form-text">
                                Publish your post to allow others to view it. Leave unchecked to keep the post as a
                                draft.
                            </Form.Text>
                        </Form.Group>
                        {isSystemUser && (
                            <Form.Group controlId="CreateResource__isFeatured">
                                <Form.Check
                                    label="Feature"
                                    type="checkbox"
                                    {...register('isFeatured')}
                                    disabled={isSubmitting}
                                />
                                <Form.Text className="CreateResource__form-text">
                                    Admin only: Feature this post on the homepage
                                </Form.Text>
                            </Form.Group>
                        )}
                        <Form.Group className="text-right">
                            <Button
                                onClick={() => {
                                    navigate('/resources');
                                }}
                                type="button"
                                variant="link"
                                disabled={isSubmitting}
                            >
                                Cancel
                            </Button>
                            <Button type="submit" variant="featured" className="ml-1" disabled={!fileUploaded}>
                                {isSubmitting ? 'Updating' : 'Update'}
                                {isSubmitting && <Spinner size="sm" className="ml-1" />}
                            </Button>
                        </Form.Group>
                    </Form>
                </Card.Body>
            </Card>
        </>
    );
};

export default UpdatePostForm;
