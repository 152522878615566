import React from 'react';

import {Card} from 'react-bootstrap';
import {Link} from 'react-router-dom';

import {Spinner} from 'shared/components/loading/Spinner/Spinner';

interface IDashboardCardProps {
    title: string;
    amount: number | undefined;
    to: string;
}

export const DashboardCard = ({title, amount, to}: IDashboardCardProps) => {
    return (
        <Card>
            <Card.Header>
                <Card.Title className="mb-0">
                    {title}
                </Card.Title>
            </Card.Header>
            <Card.Body>
                {amount ?? (
                    <Spinner size="sm"/>
                )}
            </Card.Body>
            <Link to={to} className="stretched-link"/>
        </Card>
    );
};
